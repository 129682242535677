import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

const AddOrUpdateOrganisationFootprint = () => {

    const match = useMatch('/addOrUpdateOrganisationFootprint/:organisationId/:footprintId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [usersLoading, setUsersLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [contactsLoading, setContactsLoading] = useState(false)
    const [contacts, setContacts] = useState([])
    const [organisation, setOrganisation] = useState({})
    const [organisationId, setOrganisationId] = useState('')
    const [organisationFootprintRef, setOrganisationFootprintRef] = useState('')
    const [organisationFootprintYear, setOrganisationFootprintYear] = useState((new Date().getFullYear()-1).toString())
    const [organisationFootprintDate, setOrganisationFootprintDate] = useState('')
    const [organisationFootprintItems, setOrganisationFootprintItems] = useState([])
    const [organisationFootprintRecordConsolidation, setOrganisationFootprintRecordConsolidation] = useState('')
    const [organisationFootprintReportingStructure, setOrganisationFootprintReportingStructure] = useState('')
    const [organisationFootprintType, setOrganisationFootprintType] = useState('')
    const [organisationFootprintNotes, setOrganisationFootprintNotes] = useState('Welcome to the Data Collection Portal for <ORGANISATION NAME>. Please add all necessary documents for each emissions category that is applicable to the organisation\n\nTo find out more information on what different datasets are required for the Carbon Footprint exercise, please note the following:\n\n(1) If you hover over each sub-category, information will show which provides the user with additional guidance on which datasets should be prioritised for the Carbon Footprint calculation.\n\n(2) You can also find a video tutorial explaining the key data requirements for completing a Carbon Footprint with Eight Versa here:\nhttps://youtu.be/zDpIP6JkkxY\n\nPlease do reach out to us if you have any other queries, we are happy to help.')
    const [organisationFootprintSummaryComments, setOrganisationFootprintSummaryComments] = useState('')
    const [organisationFootprintScope1Comments, setOrganisationFootprintScope1Comments] = useState('')
    const [organisationFootprintScope2Comments, setOrganisationFootprintScope2Comments] = useState('')
    const [organisationFootprintScope3Comments, setOrganisationFootprintScope3Comments] = useState('')
    const [organisationFootprintCertification, setOrganisationFootprintCertification] = useState('')
    const [organisationFootprintConsultant, setOrganisationFootprintConsultant] = useState('')
    const [organisationFootprintClientContact, setOrganisationFootprintClientContact] = useState('')
    const [organisationFootprintVerifier, setOrganisationFootprintVerifier] = useState('')
    const [organisationFootprintTargetYear, setOrganisationFootprintTargetYear] = useState('')
    const [organisationFootprintOverallReductionTargets, setOrganisationFootprintOverallReductionTargets] = useState({ "High": 90, "Medium": 90, "Low": 90, "-": 0 })
    const [organisationNumberOfEmployees, setOrganisationNumberOfEmployees] = useState(0)
    const [organisationTurnoverGBP, setOrganisationTurnoverGBP] = useState(0)
    const [organisationFootprintObjectives, setOrganisationFootprintObjectives] = useState('Verification of the Carbon Footprint')
    const [organisationFootprintScopeBoundaries, setOrganisationFootprintScopeBoundaries] = useState('Scope 1, 2 and 3 GHG Emissions')
    const [organisationFootprintLevelOfAssurance, setOrganisationFootprintLevelOfAssurance] = useState('Reasonable level of assurance')
    const [organisationFootprintMateriality, setOrganisationFootprintMateriality] = useState({"scope1And2": "YES", "scope3AtLeast95Percent": "YES", "scope3CategoryExclusionAllowed": "YES"})
    const [organisationFootprintVerificationActivitiesAndTechniques, setOrganisationFootprintVerificationActivitiesAndTechniques] = useState({"observation": "YES", "inquiry": "YES", "analyticalTesting": "NO", "confirmation": "YES", "recalculation": "YES", "examination": "NO", "retracing": "YES", "tracing": "YES", "controlTesting": "NO", "sampling": "NO", "estimateTesting": "NO", "crossChecking": "YES", "reconciliation": "NO"})
    const [organisationFootprintProjectNumber, setOrganisationFootprintProjectNumber] = useState('')
    const [organisationFootprintInternalNotes, setOrganisationFootprintInternalNotes] = useState('')
    const [organisationFootprintCommentThread, setOrganisationFootprintCommentThread] = useState([])


    const [clientId, setClientId] = useState('')

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    
    const aggregateEntitiesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.entity === val.entity);
            if (index === -1) {
                acc.push({
                    entity: val.entity,
                });
            };
            return acc.sort((item1, item2) => item1.entity < item2.entity ? -1 : 0)
        }, []);
    };

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const removeCommas = (num) => num.toString().replace(/,/g, "");

    const handleChangeForOrganisationTurnoverGBP = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setOrganisationTurnoverGBP(formatted);
            } else {
                setOrganisationTurnoverGBP(value);
            }
        } else {
            setOrganisationTurnoverGBP(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedOrganisationTurnoverGBP = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setOrganisationTurnoverGBP(formatted);

        } else {
            setOrganisationTurnoverGBP("");
        }

    };

    const handleChangeForOrganisationNumberOfEmployees = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setOrganisationNumberOfEmployees(formatted);
            } else {
                setOrganisationNumberOfEmployees(value);
            }
        } else {
            setOrganisationNumberOfEmployees(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedOrganisationNumberOfEmployees = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setOrganisationNumberOfEmployees(formatted);

        } else {
            setOrganisationNumberOfEmployees("");
        }

    };

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            var clientIdLocal = ""

            // if (match.params.footprintId !== "_add") {

                axios.get(process.env.REACT_APP_API_URL + '/organisations/' + match.params.organisationId)
                    .then((res) => {
                        setClientId(res.data.clientId)
                        clientIdLocal = res.data.clientId
                        if ((role === 3001 || role === 6001) && res.data.clientId !== company) {
                            navigate(`/errorPage`)
                        }
                        setOrganisation(res.data)
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            // }

            setOrganisationFootprintItems([])
            setOrganisationId(match.params.organisationId)

            if (match.params.footprintId !== "_add") {

                setLoading(true)

                axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
                    .then((res) => {
                        if (res.data.organisationId !== match.params.organisationId) {
                            navigate(`/errorPage`)
                        }
                        setOrganisationId(res.data.organisationId)
                        setOrganisationFootprintRef(res.data.organisationFootprintRef)
                        setOrganisationFootprintYear(res.data.organisationFootprintYear)
                        setOrganisationFootprintDate(res.data.organisationFootprintDate)
                        setOrganisationFootprintItems(res.data.organisationFootprintItems)
                        setOrganisationFootprintRecordConsolidation(res.data.organisationFootprintRecordConsolidation)
                        setOrganisationFootprintType(res.data.organisationFootprintType)
                        setOrganisationFootprintNotes(res.data.organisationFootprintNotes)
                        setOrganisationFootprintSummaryComments(res.data.organisationFootprintSummaryComments)
                        setOrganisationFootprintScope1Comments(res.data.organisationFootprintScope1Comments)
                        setOrganisationFootprintScope2Comments(res.data.organisationFootprintScope2Comments)
                        setOrganisationFootprintScope3Comments(res.data.organisationFootprintScope3Comments)
                        setOrganisationFootprintCertification(res.data.organisationFootprintCertification)
                        setOrganisationFootprintConsultant(res.data.organisationFootprintConsultant)
                        setOrganisationFootprintClientContact(res.data.organisationFootprintClientContact)
                        setOrganisationFootprintVerifier(res.data.organisationFootprintVerifier)
                        setOrganisationFootprintTargetYear(res.data.organisationFootprintTargetYear)
                        setOrganisationFootprintOverallReductionTargets(res.data.organisationFootprintOverallReductionTargets)
                        // setOrganisationNumberOfEmployees(res.data.organisationNumberOfEmployees)
                        // setOrganisationTurnoverGBP(res.data.organisationTurnoverGBP)
                        setFormattedOrganisationTurnoverGBP(res.data.organisationTurnoverGBP)
                        setFormattedOrganisationNumberOfEmployees(res.data.organisationNumberOfEmployees)
                        setOrganisationFootprintObjectives(res.data.organisationFootprintObjectives)
                        setOrganisationFootprintScopeBoundaries(res.data.organisationFootprintScopeBoundaries)
                        setOrganisationFootprintLevelOfAssurance(res.data.organisationFootprintLevelOfAssurance)
                        setOrganisationFootprintMateriality(res.data.organisationFootprintMateriality)
                        setOrganisationFootprintVerificationActivitiesAndTechniques(res.data.organisationFootprintVerificationActivitiesAndTechniques)
                        setOrganisationFootprintProjectNumber(res.data.organisationFootprintProjectNumber)
                        setOrganisationFootprintInternalNotes(res.data.organisationFootprintInternalNotes)
                        setOrganisationFootprintCommentThread(res.data.organisationFootprintCommentThread)
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

            }

            setUsersLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/users_internal_only')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                if (match.params.footprintId === "_add") {
                    setOrganisationFootprintConsultant(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0)[0].id)
                    setOrganisationFootprintVerifier(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0)[0].id)
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

            setContactsLoading(true)

            if (role === 3001 || role === 6001) {

                axios.get(process.env.REACT_APP_API_URL + '/contacts_by_clientId/' + company)
                .then((res) => {
                    setContacts(res.data)
                    if (match.params.footprintId === "_add") {
                        setOrganisationFootprintClientContact(res.data.filter(item => item.clientId === clientIdLocal).sort((item1, item2) => item1.contactName < item2.contactName ? -1 : 0)[0].id)
                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setContactsLoading(false);
                });

            } else if (role === 6002) {

                axios.get(process.env.REACT_APP_API_URL + '/contacts_by_special_user')
                .then((res) => {
                    setContacts(res.data.contacts)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setContactsLoading(false);
                });

            } else {

                axios.get(process.env.REACT_APP_API_URL + '/contacts')
                    .then((res) => {
                        setContacts(res.data)
                        if (match.params.footprintId === "_add") {
                            setOrganisationFootprintClientContact(res.data.filter(item => item.clientId === clientIdLocal).sort((item1, item2) => item1.contactName < item2.contactName ? -1 : 0)[0].id)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setContactsLoading(false);
                    });

                }

        }

    }, [match.params.footprintId, match.params.organisationId, company, role, userPropertiesLoading])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, form)
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        if (match.params.footprintId === "_add" && organisationFootprintReportingStructure === "")
        {
            window.alert("Please ensure that a Reporting Structure is selected")
            return
        }

        if (organisationFootprintType === "" || organisationFootprintRef === "" || organisationFootprintYear === "" || organisationFootprintYear.length !== 4 || isNaN(Number(organisationFootprintYear))) {

            window.alert("Please ensure that a footprint reference, footprint type and valid footprint year (YYYY) are entered")

        } else {

            if (!isNaN(Number(removeCommas(organisationNumberOfEmployees))) && !isNaN(Number(removeCommas(organisationTurnoverGBP)))) {

                if (match.params.footprintId !== "_add") {

                    //Get current data from database and update footprint items and other variables not set from this update screen
                    var currentOrganisationFootprintItems, currentOrganisationFootprintSummaryComments, currentOrganisationFootprintScope1Comments, currentOrganisationFootprintScope2Comments, currentOrganisationFootprintScope3Comments

                    await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
                    .then((res) => {
                        currentOrganisationFootprintItems = res.data.organisationFootprintItems
                        currentOrganisationFootprintSummaryComments = res.data.organisationFootprintSummaryComments
                        currentOrganisationFootprintScope1Comments = res.data.organisationFootprintScope1Comments
                        currentOrganisationFootprintScope2Comments = res.data.organisationFootprintScope2Comments
                        currentOrganisationFootprintScope3Comments = res.data.organisationFootprintScope3Comments
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

                    updateForm({ organisationId, organisationFootprintRef, organisationFootprintYear, organisationFootprintDate, organisationFootprintItems: currentOrganisationFootprintItems, organisationFootprintRecordConsolidation, organisationFootprintType, organisationFootprintNotes, organisationFootprintCertification, organisationFootprintConsultant, organisationFootprintClientContact, organisationFootprintVerifier, organisationFootprintTargetYear, organisationFootprintOverallReductionTargets, organisationNumberOfEmployees: Number(removeCommas(organisationNumberOfEmployees)), organisationTurnoverGBP: Number(removeCommas(organisationTurnoverGBP)), organisationFootprintObjectives, organisationFootprintScopeBoundaries, organisationFootprintLevelOfAssurance, organisationFootprintMateriality, organisationFootprintVerificationActivitiesAndTechniques, organisationFootprintSummaryComments: currentOrganisationFootprintSummaryComments, organisationFootprintScope1Comments: currentOrganisationFootprintScope1Comments, organisationFootprintScope2Comments: currentOrganisationFootprintScope2Comments, organisationFootprintScope3Comments: currentOrganisationFootprintScope3Comments, organisationFootprintProjectNumber, organisationFootprintInternalNotes, organisationFootprintCommentThread }).then(res => { navigate(-1) })
                
                } else {

                    var defaultItems = [
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "1dfc2506-d42a-4755-b67a-366a3bcd7094",
                            "itemCategory": "1",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "1.1 Stationary Combustion",
                            "itemSubCategory": "1.1 Stationary Combustion",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "b4e31285-8cb6-4a71-92db-e1eb25ad1910",
                            "itemCategory": "1",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "1.2 Mobile Combustion",
                            "itemSubCategory": "1.2 Mobile Combustion",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "095db4e0-afe3-43fe-bc19-c4a37a77eaff",
                            "itemCategory": "1",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "1.3 Refrigerants",
                            "itemSubCategory": "1.3 Refrigerants",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "52e491c6-738a-4758-997c-e1445112b416",
                            "itemCategory": "2",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "2.1 Purchased Electricity",
                            "itemSubCategory": "2.1 Purchased Electricity",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "253c2c9e-b2e0-43df-8d72-a980c61d220d",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.1 Purchased Goods & Services",
                            "itemSubCategory": "3.1 Purchased Goods & Services",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "4cccac09-6657-4ef3-80ba-7a305b48e3df",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.2 Capital Goods",
                            "itemSubCategory": "3.2 Capital Goods",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "596a6534-5fbd-424e-a045-0de7d1a9078f",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.3 Fuel & Energy",
                            "itemSubCategory": "3.3 Fuel & Energy",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "28e6224d-9f73-457b-9bb4-26d0ebd69668",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.4 Upstream Transportation & Distribution",
                            "itemSubCategory": "3.4 Upstream Transportation & Distribution",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "d43aec06-0fe4-4839-8043-67697f526b5e",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.5 Waste",
                            "itemSubCategory": "3.5 Waste",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "542d0df2-daf1-48a2-9e40-d176f257692a",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.6 Business Travel",
                            "itemSubCategory": "3.6 Business Travel",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "671252ce-ba7d-46bf-8c72-7753fbb74262",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.7 Employee Commuting",
                            "itemSubCategory": "3.7 Employee Commuting",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "5b262b66-99dc-4466-b4b5-cf03563c2d66",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.8 Upstream Leased Assets",
                            "itemSubCategory": "3.8 Upstream Leased Assets",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "23ef2939-601f-4e45-bc2c-3b61967e5ee0",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.9 Downstream Transportation & Distribution",
                            "itemSubCategory": "3.9 Downstream Transportation & Distribution",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "7399490f-ae7d-49e7-bf8d-8ed0722967ff",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.10 Processing of Sold Products",
                            "itemSubCategory": "3.10 Processing of Sold Products",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "870fbbd7-0542-4b07-9acc-e91543196496",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.11 Use of Sold Products",
                            "itemSubCategory": "3.11 Use of Sold Products",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "7a9508da-3852-4dcf-a0b8-52400b5c9d96",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.12 End-of-Life Treatment of Sold Products",
                            "itemSubCategory": "3.12 End-of-Life Treatment of Sold Products",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "27f1c074-b202-43df-a080-59ab9acbdac1",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.13 Downstream Leased Assets",
                            "itemSubCategory": "3.13 Downstream Leased Assets",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "2b9f9b0d-dbf8-454b-b8fd-f20a233505c7",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.14 Franchises",
                            "itemSubCategory": "3.14 Franchises",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        },
                        {
                            "additionalEstimatedEmissions": 0,
                            "basicUncertainty": "N/A",
                            "basicUncertaintyFactor": "1",
                            "carbonEmissions": 0,
                            "commentThread": [],
                            "consumption": 0,
                            "dataQualityCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "dataQualityCompletenessClassification": "Very Good",
                            "dataQualityCompletenessFactor": "1",
                            "dataQualityGeography": "Data from the same area",
                            "dataQualityGeographyClassification": "Very Good",
                            "dataQualityGeographyFactor": "1",
                            "dataQualityReliability": "Verified data based on measurements",
                            "dataQualityReliabilityClassification": "Very Good",
                            "dataQualityReliabilityFactor": "1",
                            "dataQualityTechnology": "Data generated using the same technology",
                            "dataQualityTechnologyClassification": "Very Good",
                            "dataQualityTechnologyFactor": "1",
                            "dataQualityTime": "Data with less than 3 years of difference",
                            "dataQualityTimeClassification": "Very Good",
                            "dataQualityTimeFactor": "1",
                            "dataType": "Primary Data",
                            "emissionFactor": 0,
                            "emissionsFactorCompleteness": "Data from all relevant sites over an adequate time period to even out normal fluctuations",
                            "emissionsFactorCompletenessClassification": "Very Good",
                            "emissionsFactorCompletenessFactor": "1",
                            "emissionsFactorGeography": "Data from the same area",
                            "emissionsFactorGeographyClassification": "Very Good",
                            "emissionsFactorGeographyFactor": "1",
                            "emissionsFactorReliability": "Verified data partly based on assumptions or non-verified data based on measurements",
                            "emissionsFactorReliabilityClassification": "Good",
                            "emissionsFactorReliabilityFactor": "1.1",
                            "emissionsFactorSource": "",
                            "emissionsFactorTechnology": "Data generated using the same technology",
                            "emissionsFactorTechnologyClassification": "Very Good",
                            "emissionsFactorTechnologyFactor": "1",
                            "emissionsFactorTime": "Data with less than 3 years of difference",
                            "emissionsFactorTimeClassification": "Very Good",
                            "emissionsFactorTimeFactor": "1",
                            "filesUploaded": 0,
                            "footprintSubItems": [],
                            "id": "5437f3e9-aed0-4e88-82b7-8a3cc3dca09d",
                            "itemCategory": "3",
                            "itemConsultantComment": "",
                            "itemConsultantCommentForDataCollection": "",
                            "itemCommentForReport": "",
                            "itemDescription": "3.15 Investments",
                            "itemSubCategory": "3.15 Investments",
                            "itemTag": "",
                            "dataReviewProgress": "",
                            "dataReviewConsultant": "",
                            "calculationProgress": "",
                            "calculationConsultant": "",
                            "QAProgress": "",
                            "QAConsultant": "",
                            "numberOfMonths": 12,
                            "reductionCommentsOnControl": "",
                            "reductionControlCategory": "Full Control",
                            "reductionMeasures": "",
                            "reductionProjection": "0",
                            "reductionProjectionYear": "",
                            "reductionTarget": "",
                            "reductionTargetPercentageOverride": 0,
                            "reductionTargetYear": "",
                            "submissionComment": "",
                            "submitted": "",
                            "unit": "",
                            "verificationComment": "",
                            "verified": "",
    "showOnTracker": "YES", "itemEntity": ""
                        }
                        ]

                    var i


                    if (organisationFootprintReportingStructure === "Entity Level Reporting" && organisation.organisationFacilities.length > 0) {
                        var entityInstance
                        var originalDefaultItems = [...defaultItems]
                        defaultItems = []
                        var tempItemDescription
                        var entities = aggregateEntitiesArray(organisation.organisationFacilities)

                        for (entityInstance = 0; entityInstance < entities.length; entityInstance++) {

                            for (i = 0; i < originalDefaultItems.length; i++) {
                                if (originalDefaultItems[i].itemSubCategory.slice(4,4) === " ") {
                                    tempItemDescription = originalDefaultItems[i].itemSubCategory.slice(0,4) + "- " + entities[entityInstance].entity
                                } else {
                                    tempItemDescription = originalDefaultItems[i].itemSubCategory.slice(0,4) + " - " + entities[entityInstance].entity
                                }

                                defaultItems.push({...originalDefaultItems[i], itemEntity: entities[entityInstance].entity, itemDescription: tempItemDescription} )

                            }
                    
                        }

                    }

                    for (i = 0; i < defaultItems.length; i++) {

                        defaultItems[i].id = uuidv4()

                    }
                    
                    addForm({ organisationId, organisationFootprintRef, organisationFootprintYear, organisationFootprintDate, organisationFootprintItems: defaultItems, organisationFootprintRecordConsolidation, organisationFootprintType, organisationFootprintNotes, organisationFootprintCertification, organisationFootprintConsultant, organisationFootprintClientContact, organisationFootprintVerifier, organisationFootprintTargetYear, organisationFootprintOverallReductionTargets, organisationNumberOfEmployees: Number(removeCommas(organisationNumberOfEmployees)), organisationTurnoverGBP: Number(removeCommas(organisationTurnoverGBP)), organisationFootprintObjectives, organisationFootprintScopeBoundaries, organisationFootprintLevelOfAssurance, organisationFootprintMateriality, organisationFootprintVerificationActivitiesAndTechniques, organisationFootprintSummaryComments, organisationFootprintScope1Comments, organisationFootprintScope2Comments, organisationFootprintScope3Comments, organisationFootprintProjectNumber, organisationFootprintInternalNotes, organisationFootprintCommentThread }).then(res => { navigate(-1) })
                
                }


            } else {

                window.alert("Please check that numeric values are valid")

            }
        }
        
    }

    if (!loading && !userPropertiesLoading && role && company && !usersLoading && users.length > 0 && !contactsLoading) {

        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>

                <div className="container-form-8A">

                    <Header title="Organisations - Add / Edit Footprint" section="Organisation Main Details" url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <p className="font-bold text-xl">General Info:</p>

                        {match.params.footprintId === "_add" &&
                        <div className="form-control-8A">
                            <label>Organisation Reporting Structure</label>
                            <select className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} value={organisationFootprintReportingStructure} onChange={(e) => setOrganisationFootprintReportingStructure(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Scope Category Level Reporting">Scope Category Level Reporting (1 Item per Category)</option>
                                <option value="Entity Level Reporting">Entity Level Reporting (1 Item per Category-Entity)</option>
                            </select>
                        </div>
                        }

                        <div className="form-control-8A">
                            <label>Organisation Footprint Ref</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Organisation Footprint Ref Here" value={organisationFootprintRef} onChange={(e) => setOrganisationFootprintRef(e.target.value)} />
                        </div>
                        {match.params.footprintId === "_add" &&
                        <div className="form-control-8A">
                            <label>Organisation Footprint Year</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Organisation Footprint Year Here (YYYY)" value={organisationFootprintYear} onChange={(e) => setOrganisationFootprintYear(e.target.value)} />
                        </div>
                        }
                        {match.params.footprintId !== "_add" &&
                        <div className="form-control-8A">
                            <label>Organisation Footprint Year</label>
                            <input className={"bg-slate-100 cursor-not-allowed placeholder-gray-300"} readOnly={true} type="text" placeholder="Enter Organisation Footprint Year Here (YYYY)" value={organisationFootprintYear} onChange={(e) => setOrganisationFootprintYear(e.target.value)} />
                        </div>
                        }
                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Date</label>
                            <input className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} type="date" placeholder="Enter Organisation Footprint Date Here" value={organisationFootprintDate} onChange={(e) => setOrganisationFootprintDate(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Number of Employees</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 && role !== 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 && role !== 3001 ? true : false} type="text" placeholder="Enter Number of Employees Here" value={organisationNumberOfEmployees} onChange={handleChangeForOrganisationNumberOfEmployees} />
                        </div>

                        <div className="form-control-8A">
                            <label>Turnover (Base Currency)</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 && role !== 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 && role !== 3001 ? true : false} type="text" placeholder="Enter Turnover Here" value={organisationTurnoverGBP} onChange={handleChangeForOrganisationTurnoverGBP} />
                        </div>

                        <div className="form-control-8A">
                            <label>Record Consolidation Approach</label>
                            <select className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} value={organisationFootprintRecordConsolidation} onChange={(e) => setOrganisationFootprintRecordConsolidation(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Financial Control">Financial Control</option>
                                <option value="Operational Control">Operational Control</option>
                                <option value="Equity Share">Equity Share</option>
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Organisation Footprint Type</label>
                            <select className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} value={organisationFootprintType} onChange={(e) => setOrganisationFootprintType(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Actual (Baseline)">Actual (Baseline)</option>
                                <option value="Actual">Actual</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Notes</label>
                            <textarea className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300 w-full border-slate-300 border-2 min-h-[150px] ml-2` : `w-full border-slate-300 border-2 min-h-[150px] ml-2`} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Organisation Footprint Notes Here" value={organisationFootprintNotes} onChange={(e) => setOrganisationFootprintNotes(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Certification</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintCertification} onChange={(e) => setOrganisationFootprintCertification(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Non Certified">Non Certified</option>
                                <option value="Carbon Measured">Carbon Measured</option>
                                <option value="Lower Carbon Committed">Lower Carbon Committed</option>
                                <option value="Lower Carbon">Lower Carbon</option>
                                <option value="Net Zero Carbon Committed">Net Zero Carbon Committed</option>
                                <option value="Net Zero Carbon">Net Zero Carbon</option>
                                <option value="Climate Positive">Climate Positive</option>
                            </select>
                        </div>

                        {/* <div className="form-control-8A">
                            <label>Organisation Footprint Consultant</label>
                            <input className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 3001 && role !== 4001 ? false : true} type="text" placeholder="Enter Organisation Footprint Consultant Here" value={organisationFootprintConsultant} onChange={(e) => setOrganisationFootprintConsultant(e.target.value)} />
                        </div> */}

                        <div className="form-control-8A">
                            <label>Organisation Footprint Consultant</label>
                            <select className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} value={organisationFootprintConsultant} onChange={(e) => setOrganisationFootprintConsultant(e.target.value)}>
                                {users.filter(user => user.role !== 9999 && user.role !== 9998 && user.role !== 3001).map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>

                        {/* <div className="form-control-8A">
                            <label>Organisation Client Contact</label>
                            <input className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 3001 && role !== 4001 ? false : true} type="text" placeholder="Enter Organisation Footprint Client Contact Here" value={organisationFootprintClientContact} onChange={(e) => setOrganisationFootprintClientContact(e.target.value)} />
                        </div> */}

                        <div className="form-control-8A">
                            <label>Organisation Client Contact</label>
                            <select className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 && role !== 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 && role !== 3001 ? true : false} onChange={(e) => setOrganisationFootprintClientContact(e.target.value)} onFocus={(e) => setOrganisationFootprintClientContact(e.target.value)}>
                                {contacts.filter(item => item.clientId === clientId).sort((item1, item2) => item1.contactName < item2.contactName ? -1 : 0).map((contact) => (
                                    <option key={contact.id} value={contact.id}>{contact.contactName}</option>
                                ))}
                            </select>
                        </div>

                        <p className="font-bold text-xl">Reduction:</p>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Final Target Year</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Organisation Footprint Final Target Year Here" value={organisationFootprintTargetYear} onChange={(e) => setOrganisationFootprintTargetYear(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>High Priority Reduction Target</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter High Priority Reduction Target Here" value={organisationFootprintOverallReductionTargets.High} onChange={(e) => setOrganisationFootprintOverallReductionTargets({ ...organisationFootprintOverallReductionTargets, "High": e.target.value })} />
                        </div>

                        <div className="form-control-8A">
                            <label>Medium Priority Reduction Target</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Medium Priority Reduction Target Here" value={organisationFootprintOverallReductionTargets.Medium} onChange={(e) => setOrganisationFootprintOverallReductionTargets({ ...organisationFootprintOverallReductionTargets, "Medium": e.target.value })} />
                        </div>

                        <div className="form-control-8A">
                            <label>Low Priority Reduction Target</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Low Priority Reduction Target Here" value={organisationFootprintOverallReductionTargets.Low} onChange={(e) => setOrganisationFootprintOverallReductionTargets({ ...organisationFootprintOverallReductionTargets, "Low": e.target.value })} />
                        </div>

                        {/* <div className="form-control-8A">
                            <label>Organisation Verifier</label>
                            <input className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 3001 && role !== 4001 ? false : true} type="text" placeholder="Enter Organisation Footprint Client Contact Here" value={organisationFootprintVerifier} onChange={(e) => setOrganisationFootprintVerifier(e.target.value)} />
                        </div> */}

                        <p className="font-bold text-xl">Verification:</p>

                        <div className="form-control-8A">
                            <label>Organisation Verifier</label>
                            <select className={role !== 5001 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerifier} onChange={(e) => setOrganisationFootprintVerifier(e.target.value)}>
                                {users.filter(user => user.role !== 9999 && user.role !== 9998 && user.role !== 3001).map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Objectives</label>
                            <input className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} type="text" placeholder="Enter Objectives Here" value={organisationFootprintObjectives} onChange={(e) => setOrganisationFootprintObjectives(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Scope - Boundaries</label>
                            <input className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} type="text" placeholder="Enter Objectives Here" value={organisationFootprintScopeBoundaries} onChange={(e) => setOrganisationFootprintScopeBoundaries(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Level of Assurance</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintLevelOfAssurance} onChange={(e) => setOrganisationFootprintLevelOfAssurance(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Limited level of assurance">Limited level of assurance</option>
                                <option value="Reasonable level of assurance">Reasonable level of assurance</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Materiality - 100% GHG emissions from Scope 1 and 2</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintMateriality.scope1And2} onChange={(e) => setOrganisationFootprintMateriality({ ...organisationFootprintMateriality, "scope1And2": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Materiality - At least 95% GHG emissions from Scope 3</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintMateriality.scope3AtLeast95Percent} onChange={(e) => setOrganisationFootprintMateriality({ ...organisationFootprintMateriality, "scope3AtLeast95Percent": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Materiality - Scope 3 categories can be excluded if their individual contribution is less than 1% of the total GHG emissions</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintMateriality.scope3CategoryExclusionAllowed} onChange={(e) => setOrganisationFootprintMateriality({ ...organisationFootprintMateriality, "scope3CategoryExclusionAllowed": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Observation</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.observation} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "observation": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Inquiry</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.inquiry} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "inquiry": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Analytical testing</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.analyticalTesting} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "analyticalTesting": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Confirmation</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.confirmation} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "confirmation": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Recalculation</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.recalculation} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "recalculation": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Examination</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.examination} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "examination": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Retracing</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.retracing} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "retracing": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Tracing</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.tracing} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "tracing": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Control testing</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.controlTesting} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "controlTesting": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Sampling</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.sampling} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "sampling": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Estimate testing</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.estimateTesting} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "estimateTesting": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Cross-checking</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.crossChecking} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "crossChecking": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Organisation Footprint Verification Activities and Techniques - Reconciliation</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={organisationFootprintVerificationActivitiesAndTechniques.reconciliation} onChange={(e) => setOrganisationFootprintVerificationActivitiesAndTechniques({ ...organisationFootprintVerificationActivitiesAndTechniques, "reconciliation": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                </div>
            </div>
        )

    } else {

        return (
            <div className='flex bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Organisations - Add / Edit Footprint" section="Organisation Main Details" url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>

                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateOrganisationFootprint