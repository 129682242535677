import Header from '../UI/Header'
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import axios from 'axios'
import Button from '../UI/Button'
import { v4 as uuidv4 } from 'uuid'
import Modal from '../UI/Modal'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import ModalLoading from '../UI/ModalLoading'
import InfoModal from '../UI/InfoModal'
import ReactSelect from 'react-select'
import Options from '../UI/Options'
import { ExportToCsv } from 'export-to-csv'
import { MultiSelect } from "react-multi-select-component";
import AuthService from '../../services/AuthService'

const ViewOrganisationFootprintItemHelperToolAllSubItems = (props) => {

    const descriptionInput = useRef(null);
    const supplierInput = useRef(null);
    const facilityInput = useRef(null);
    const commentsInput = useRef(null);
    const [sortedField, setSortedField] = useState(null);
    const [sortedOrder, setSortedOrder] = useState(null);
    const match = useMatch('/ViewOrganisationFootprintItemHelperToolAllSubItems/:organisationId/:footprintId')
    const [firstRecord, setFirstRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(100)
    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [loading, setLoading] = useState(true)
    const [subItemsLoading, setSubItemsLoading] = useState(true)
    const [organisationsLoading, setOrganisationsLoading] = useState(true)
    const [footprint, setFootprint] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [editToggle, setEditToggle] = useState(false)
    const [editSelectedToggle, setEditSelectedToggle] = useState(false)
    const [multiplierSelection, setMultiplierSelection] = useState(false)
    const [subItemsFilteredNumberOfItems, setSubItemsFilteredNumberOfItems] = useState(0)
    const [subItemsFilteredTotaltCO2e, setSubItemsFilteredTotaltCO2e] = useState(0)
    const [files, setFiles] = useState([]);
    const [keyWordsCheck, setKeyWordsCheck] = useState([{keyWord: "train ", subCategory: "3.6 Business Travel"},
    {keyWord: " trains ", subCategory: "3.6 Business Travel"},
    {keyWord: "accomodation", subCategory: "3.6 Business Travel"},
    {keyWord: "Uber", subCategory: "3.6 Business Travel"},
    {keyWord: "Inn ", subCategory: "3.6 Business Travel"},
    {keyWord: "Travel ", subCategory: "3.6 Business Travel"},
    {keyWord: "Tube ", subCategory: "3.6 Business Travel"},
    {keyWord: " prop ", subCategory: "#CHECK"},
    {keyWord: "Membership ", subCategory: "#CHECK"},
    {keyWord: "Recharge ", subCategory: "#CHECK"},
    {keyWord: " Car ", subCategory: "3.6 Business Travel"},
    {keyWord: " bus ", subCategory: "3.6 Business Travel"},
    {keyWord: "minibus", subCategory: "3.6 Business Travel"},
    {keyWord: " Cars", subCategory: "3.6 Business Travel"},
    {keyWord: "Car ", subCategory: "3.6 Business Travel"},
    {keyWord: " Cab ", subCategory: "3.6 Business Travel"},
    {keyWord: " Cabs ", subCategory: "3.6 Business Travel"},
    {keyWord: "hotel", subCategory: "3.6 Business Travel"},
    {keyWord: " ibis ", subCategory: "3.6 Business Travel"},
    {keyWord: " Hilton ", subCategory: "3.6 Business Travel"},
    {keyWord: " Night", subCategory: "3.6 Business Travel"},
    {keyWord: "flight", subCategory: "3.6 Business Travel"},
    {keyWord: "Addison Lee", subCategory: "3.6 Business Travel"},
    {keyWord: "Add Lee", subCategory: "3.6 Business Travel"},
    {keyWord: "bike ", subCategory: "3.6 Business Travel"},
    {keyWord: "Esso ", subCategory: "3.6 Business Travel"},
    {keyWord: "bikes  ", subCategory: "3.6 Business Travel"},
    {keyWord: "Mileage", subCategory: "3.6 Business Travel"},
    {keyWord: "Trainline", subCategory: "3.6 Business Travel"},
    {keyWord: "TfL ", subCategory: "3.6 Business Travel"},
    {keyWord: "Postage", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Shipping Charge", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Royal Mail", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Production supplies ", subCategory: "#CHECK"},
    {keyWord: "Utilities", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Utility", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "service charge", subCategory: "#CHECK"},
    {keyWord: "Machinery", subCategory: "#CHECK"},
    {keyWord: "Diesel", subCategory: "#CHECK"},
    {keyWord: "Deposit", subCategory: "#CHECK"},
    {keyWord: "Limousine", subCategory: "3.6 Business Travel"},
    {keyWord: "Black Lane", subCategory: "3.6 Business Travel"},
    {keyWord: "Blacklane", subCategory: "3.6 Business Travel"},
    {keyWord: "taxi", subCategory: "3.6 Business Travel"},
    {keyWord: "courier", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Delivery ", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Parcel", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: " Rail ", subCategory: "3.6 Business Travel"},
    {keyWord: "Fuel", subCategory: "#CHECK"},
    {keyWord: "Petrol", subCategory: "#CHECK"},
    {keyWord: " Accom", subCategory: "3.6 Business Travel"},
    {keyWord: "Travel", subCategory: "3.6 Business Travel"},
    {keyWord: "Transport", subCategory: "3.6 Business Travel"},
    {keyWord: "Editing cost", subCategory: "#CHECK"},
    {keyWord: "Water Rate", subCategory: "#CHECK"},
    {keyWord: "Waste Water", subCategory: "#CHECK"},
    {keyWord: "Water Supply", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Awards entry", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "License fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Parking", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "BBC Public Service Rights", subCategory: "#CHECK EXCLUDE"},
    {keyWord: " Tax ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Online charge", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Toll ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Exclude ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Bank charge", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Carbon offset", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Business Rate", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Admin Fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Wetransfer", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Deposit", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Refund", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Cancelled", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Cancellation", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Archive", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Business Charges", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Recycling", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Location Fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Flight Change", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Offset", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Overdraft", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Payroll", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Interest", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Debit", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Credit", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "ATM", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Handling Fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Donation", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Charity", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Entry", subCategory: "#CHECK EXCLUDE"},
    {keyWord: " Gas ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Electricity", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "waste disposal", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "levies payable", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "levy", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "visa ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "exchange", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "salaries", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "wages", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "sponsorship", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "pension", subCategory: "#CHECK EXCLUDE"}
    ]) 

    const [organisation, setOrganisation] = useState({})
    const [subItems, setSubItems] = useState([])
    const [subItemsFiltered, setSubItemsFiltered] = useState([])
    const [subItemsToDelete, setSubItemsToDelete] = useState([])

    const [databaseMapping, setDatabaseMapping] = useState([])
    const [databaseMappingLoading, setDatabaseMappingLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const [selectedSubCategories, setSelectedSubCategories] = useState([])
    const [selectedDatabases, setSelectedDatabases] = useState([])
    const [selectedComments, setSelectedComments] = useState([])
    const [commentsToSelect, setCommentsToSelect] = useState([])
    const [selectedDescriptions, setSelectedDescriptions] = useState([])
    const [descriptionsToSelect, setDescriptionsToSelect] = useState([])
    const [selectedSuppliers, setSelectedSuppliers] = useState([])
    const [suppliersToSelect, setSuppliersToSelect] = useState([])
    const [selectedFacilities, setSelectedFacilities] = useState([])
    const [facilitiesToSelect, setFacilitiesToSelect] = useState([])
    const [subCategoriesToSelect, setSubCategoriesToSelect] = useState([])
    const [selectedEmissionFactorSources, setSelectedEmissionFactorSources] = useState([])
    const [emissionFactorsToSelect, setEmissionFactorsToSelect] = useState([])
    const [selectedUnits, setSelectedUnits] = useState([])
    const [unitsToSelect, setUnitsToSelect] = useState([])
    const [carbonEmissionFactors, setCarbonEmissionFactors] = useState([])
    const [carbonFactorsLoading, setCarbonFactorsLoading] = useState(false)
    const [fromPostcodeCoordinates, setFromPostcodeCoordinates] = useState('')
    const [toPostcodeCoordinates, setToPostcodeCoordinates] = useState('')
    const [searchText, setSearchText] = useState('')
    const instance = axios.create({});
    const [selectedTransportModeToAdd, setSelectedTransportModeToAdd] = useState({value: "", label: ""})
    const [transportModeOptions, setTransportModeOptions] = useState([])
    const [previousYearsData, setPreviousYearsData] = useState({})
    const [managementSystem, setManagementSystem] = useState('Xero')
    const [descriptionToAdd, setDescriptionToAdd] = useState('')
    const [fuelTypeToAdd, setFuelTypeToAdd] = useState('Natural gas')
    const [fromDateToAdd, setFromDateToAdd] = useState('')
    const [toDateToAdd, setToDateToAdd] = useState('')
    const [inputMethodToAdd, setInputMethodToAdd] = useState('From Documents')
    const [calculationMethodToAdd, setCalculationMethodToAdd] = useState('Actual')
    const [supplierToAdd, setSupplierToAdd] = useState('')
    const [facilityToAdd, setFacilityToAdd] = useState('')
    const [individualToAdd, setIndividualToAdd] = useState('')
    const [fromPostCodeToAdd, setFromPostCodeToAdd] = useState('')
    const [toPostCodeToAdd, setToPostCodeToAdd] = useState('')
    const [consumptionToAdd, setConsumptionToAdd] = useState(0)
    const [unitToAdd, setUnitToAdd] = useState('')
    const [emissionFactorToAdd, setEmissionFactorToAdd] = useState(0)
    const [carbonEmissionsToAdd, setCarbonEmissionsToAdd] = useState(0)
    const [commentsToAdd, setCommentsToAdd] = useState('')
    const [multiplierToAdd, setMultiplierToAdd] = useState(1)
    const [emissionFactorSourceToAdd, setEmissionFactorSourceToAdd] = useState('')
    const [multiplierFactorSourceToAdd, setMultiplierFactorSourceToAdd] = useState('')
    const [subItemIdToAdd, setSubItemIdToAdd] = useState('')
    const [showModalUpdate, setShowModalUpdate] = useState(false)
    const [showModalLoading, setShowModalLoading] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')
    const internationalNumberFormat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})
    const [inflationFactor, setInflationFactor] = useState(0)
    const [totalNumberOfRecordsToImport, setTotalNumberOfRecordsToImport] = useState(0)
    const [currentRecordToImport, setCurrentRecordToImport] = useState(1)
    const [animationSwitch, setAnimationSwitch] = useState(false)
    const [reloadDataToggle, setReloadDataToggle] = useState(false)
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [processWhat, setProcessWhat] = useState('')

    const URL_REGEX =
	/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    
    const TextWithLinks = (content) =>  {

        const words = content.content.split('\n')

        // console.log(words)
    
        return <div className="text-sm font-medium text-pacific-blue"><p className="my-1 whitespace-pre-line" >{words.map((word) => {
            return word.match(URL_REGEX) ? (
                <>
                    <a href={word} target="_blank" className="underline">{word}</a>{'\n'}
                </>
            ) : (
                word + '\n'
            );
        })}</p></div>
    
    }

    const aggregateCarbonFactorDatabasesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.DataSource === val.DataSource + " " + val.Year);
            if (index === -1) {
                acc.push({
                    DataSource: val.DataSource + " " + val.Year,
                });
            };
            return acc.sort((item1, item2) => item1.DataSource < item2.DataSource ? -1 : 0)
        }, []);
    };

    const aggregateCommentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.comments === val.comments);
            if (index === -1) {
                acc.push({
                    comments: val.comments,
                });
            };
            return acc.sort((item1, item2) => item1.comments < item2.comments ? -1 : 0)
        }, []);
    };

    const aggregateSuppliersArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.supplier === val.supplier);
            if (index === -1) {
                acc.push({
                    supplier: val.supplier,
                });
            };
            return acc.sort((item1, item2) => item1.supplier < item2.supplier ? -1 : 0)
        }, []);
    };

    const aggregateFacilitiesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.facility === val.facility);
            if (index === -1) {
                acc.push({
                    facility: val.facility,
                });
            };
            return acc.sort((item1, item2) => item1.facility < item2.facility ? -1 : 0)
        }, []);
    };

    const aggregateSubCategoriesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.subCategory === val.subCategory);
            if (index === -1) {
                acc.push({
                    subCategory: val.subCategory,
                });
            };
            return acc.sort((item1, item2) => item1.subCategory < item2.subCategory ? -1 : 0)
        }, []);
    };

    const aggregateEmissionFactorSourcesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.emissionFactorSource === val.emissionFactorSource);
            if (index === -1) {
                acc.push({
                    emissionFactorSource: val.emissionFactorSource,
                });
            };
            return acc.sort((item1, item2) => item1.emissionFactorSource < item2.emissionFactorSource ? -1 : 0)
        }, []);
    };

    const aggregateUnitsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.unit === val.unit);
            if (index === -1) {
                acc.push({
                    unit: val.unit,
                });
            };
            return acc.sort((item1, item2) => item1.unit < item2.unit ? -1 : 0)
        }, []);
    };

    const aggregateFootprintItemIdsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.footprintItemId === val.footprintItemId);
            if (index === -1) {
                acc.push({
                    footprintItemId: val.footprintItemId,
                });
            };
            return acc
        }, []);
    };

    const aggregateDescriptionsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.description === val.description);
            if (index === -1) {
                acc.push({
                    description: val.description,
                });
            };
            return acc
        }, []);
    };

    const FV = (rate, nper, pmt, pv, type) => {
        var pow = Math.pow(1 + rate, nper),
           fv;
        if (rate) {
         fv = (pmt*(1+rate*type)*(1-pow)/rate)-pv*pow;
        } else {
         fv = -1 * (pv + pmt * nper);
        }
        return fv;
      }

      const scrollTop = () => {

        //This should scroll to the top of the page
        document.getElementById("subItemsTable").scrollIntoView()
        
    }

    const removeCommas = (num) => num.toString().replace(/,/g, "");

    const handleChangeForConsumption = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setConsumptionToAdd(formatted);
            } else {
                setConsumptionToAdd(value);
            }
        } else {
            setConsumptionToAdd(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedConsumption = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setConsumptionToAdd(formatted);

        } else {
            setConsumptionToAdd("");
        }

    };

    const handleChangeForEmissions = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setCarbonEmissionsToAdd(formatted);
            } else {
                setCarbonEmissionsToAdd(value);
            }
        } else {
            setCarbonEmissionsToAdd(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedEmissions = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setCarbonEmissionsToAdd(formatted);

        } else {
            setCarbonEmissionsToAdd("");
        }

    };

    const selectOrDeselectFile = (filename) => {

        var tempFiles = [...files]
       
        if (tempFiles.find(item => item.name === filename).selected === -1) {

            tempFiles.find(item => item.name === filename).selected = 0

        } else {

            tempFiles.find(item => item.name === filename).selected = -1

        }

        setFiles(tempFiles)

    }

    const prev100Records = () => {
        
        if (firstRecord > 99) {

            setFirstRecord(firstRecord - 100)
            setLastRecord(lastRecord - 100)

        }

        scrollTop()

    }

    const next100Records = () => {

        if (lastRecord <= subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSubCategories.find((itemB) => {
                return itemA.subCategory === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase())).length) {

            setFirstRecord(firstRecord + 100)
            setLastRecord(lastRecord + 100)

        }

        scrollTop()

    }

    const clearSelectedItems = () => {

        var subItemIndex
        var newSubItems
        newSubItems = [ ...subItems ]

        for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
            newSubItems[subItemIndex].individual = ""
        }

        setSubItems(newSubItems)
        
    };

    const sortByEmissions = () => {

        var newSubItems
        newSubItems = [ ...subItems ]
       
        if (sortedOrder === "asc") {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.carbonEmissions) > Number(item2.carbonEmissions) ? -1 : Number(item1.carbonEmissions) < Number(item2.carbonEmissions) ? 1 : 0)
            setSortedOrder("desc")
        } else {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.carbonEmissions) < Number(item2.carbonEmissions) ? -1 : Number(item1.carbonEmissions) > Number(item2.carbonEmissions) ? 1 : 0)
            setSortedOrder("asc")
        }

        setSubItems(newSubItems)

        setSortedField("emissions")

    };

    const sortByEmissionFactors = () => {

        var newSubItems
        newSubItems = [ ...subItems ]
       
        if (sortedOrder === "asc") {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.emissionFactor) > Number(item2.emissionFactor) ? -1 : Number(item1.emissionFactor) < Number(item2.emissionFactor) ? 1 : 0)
            setSortedOrder("desc")
        } else {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.emissionFactor) < Number(item2.emissionFactor) ? -1 : Number(item1.emissionFactor) > Number(item2.emissionFactor) ? 1 : 0)
            setSortedOrder("asc")
        }

        setSubItems(newSubItems)

        setSortedField("emissionsFactor")

    };

    const sortByConsumption = () => {

        var newSubItems
        newSubItems = [ ...subItems ]
       
        if (sortedOrder === "asc") {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.consumption) > Number(item2.consumption) ? -1 : Number(item1.consumption) < Number(item2.consumption) ? 1 : 0)
            setSortedOrder("desc")
        } else {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.consumption) < Number(item2.consumption) ? -1 : Number(item1.consumption) > Number(item2.consumption) ? 1 : 0)
            setSortedOrder("asc")
        }

        setSubItems(newSubItems)

        setSortedField("consumption")

    };

    const sortByDescription = () => {

        var newSubItems
        newSubItems = [ ...subItems ]
       
        if (sortedOrder === "asc") {
            newSubItems = newSubItems.sort((item1, item2) => item1.description > item2.description ? -1 : item1.description < item2.description ? 1 : 0)
            setSortedOrder("desc")
        } else {
            newSubItems = newSubItems.sort((item1, item2) => item1.description < item2.description ? -1 : item1.description > item2.description ? 1 : 0)
            setSortedOrder("asc")
        }

        setSubItems(newSubItems)

        setSortedField("description")

    };

    const selectAllFilteredItems = () => {

        var subItemIndex
        var filteredItemIndex
        var newSubItems
        newSubItems = [ ...subItems ]

        //FILTER newSubItems to the same filters and then mark them all as selected!!!!!!!!!!!!!!!!!!!!!

        if (subItemsFiltered.length === newSubItems.length) {

            for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
                newSubItems[subItemIndex].individual = "SELECTED"
            }

        } else {

            for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
                newSubItems[subItemIndex].individual = ""
            }

            for (filteredItemIndex = 0; filteredItemIndex < subItemsFiltered.length; filteredItemIndex++) {
                newSubItems.find(item => item.id === subItemsFiltered[filteredItemIndex].id).individual = "SELECTED"
            }

        }

        setSubItems(newSubItems)

    };

    const arrayToCsv = (data) => {

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Sub Items Export from Helper Tool',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        var dataWithHeadersOrdered = []
        var subItemIndex

        for (subItemIndex = 0; subItemIndex < data.length; subItemIndex++) {

            if (role === 3001 || role === 6001) {
                dataWithHeadersOrdered.push({id: data[subItemIndex].id, description: data[subItemIndex].description, supplier: data[subItemIndex].supplier, facility: data[subItemIndex].facility, individual: data[subItemIndex].individual, fromPostCode: data[subItemIndex].fromPostCode, toPostCode: data[subItemIndex].toPostCode, transportMode: data[subItemIndex].transportMode, consumption: data[subItemIndex].consumption, multiplier: data[subItemIndex].multiplier, unit: data[subItemIndex].unit, carbonEmissions: data[subItemIndex].carbonEmissions, comments: data[subItemIndex].comments})
            } else {
                dataWithHeadersOrdered.push({id: data[subItemIndex].id, description: data[subItemIndex].description, supplier: data[subItemIndex].supplier, facility: data[subItemIndex].facility, individual: data[subItemIndex].individual, fromPostCode: data[subItemIndex].fromPostCode, toPostCode: data[subItemIndex].toPostCode, transportMode: data[subItemIndex].transportMode, consumption: data[subItemIndex].consumption, multiplier: data[subItemIndex].multiplier, unit: data[subItemIndex].unit, emissionFactor: data[subItemIndex].emissionFactor, carbonEmissions: data[subItemIndex].carbonEmissions, comments: data[subItemIndex].comments, emissionFactorSource: data[subItemIndex].emissionFactorSource, multiplierFactorSource: data[subItemIndex].multiplierFactorSource})
            }
        }

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(dataWithHeadersOrdered)

    }

    //set styles for ReactSelect
    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            fontSize: 17,
            minWidth: "100%",
            borderRadius: "0%",
            height: "41px"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
            background: "#bfe3f1"
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: "500px"
        }),
    }

    const stylesWhenEditing = {
        option: provided => ({
            ...provided,
            color: 'black',
            background: "#bfe3f1"
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            fontSize: 17,
            minWidth: "100%",
            borderRadius: "0%",
            height: "41px",
            background: "#bfe3f1"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
            background: "#bfe3f1"
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: "500px",
            background: "#bfe3f1"
        }),
    }

    const navigate = useNavigate()

    const [file, setFile] = useState()

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        const fileReader = new FileReader()

        if (file) {
            fileReader.onload = function (organisation) {
                const text = organisation.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
            setFile()
            document.getElementById("csvFileInput").value = ""

        }else{
            window.alert("No file selected")
        }

    };
    
    const handlePostcodesPreCheck = async () => {
        var subItemIndex
        let iCount = 0
        
        for (subItemIndex = 0; subItemIndex < subItems.length; subItemIndex++) {
            
            let originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
            let destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()
            let kms = subItems[subItemIndex].consumption

            if (originPostcode!=='' && destPostcode!=='' && kms!==0){
                iCount++
            }

        }

        if (iCount>0){
            setShowInfoModal(true)
        }else{
            handlePostcodes()
        }
    }

    function deg2rad(deg) {
        return deg * (Math.PI/180)
    }

    const handleFlights = async () => {

        let origin, destination
        let record
        let postcodesArray = []
        let lat1, lon1, lat2, lon2
        var subItemIndex
        let startRecord = 0
        let endRecord = 0
        let originPostcode = ''
        let destPostcode = ''
        let transportMode = ''
        var newSubItems = [ ...subItems ]
        let postcodeCount = 0
        let distance = 0

        for (subItemIndex = 0; subItemIndex < subItems.length; subItemIndex++) {
                
            originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
            destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()
            transportMode= subItems[subItemIndex].transportMode

            if (originPostcode!=="" && destPostcode!=="" && transportMode.toLowerCase().includes("flights")) {
                
                //Get all the postcodes into an array
                postcodesArray.push({id: subItems[subItemIndex].id, origin: subItems[subItemIndex].fromPostCode, destination: subItems[subItemIndex].toPostCode});
                postcodeCount++

            }
        }

        setTotalNumberOfRecordsToImport(postcodesArray.length)

        let totalLoops = postcodesArray.length

        if (postcodesArray.length>0){

            setShowModalLoading(true);

            for (let index = 0; index < totalLoops; index++) {

                setCurrentRecordToImport(index)

                origin = postcodesArray[index].origin.replaceAll('/', '')
                destination = postcodesArray[index].destination.replaceAll('/', '')
                record = postcodesArray[index].id

                //Get origin coordinates
                await axios.get(process.env.REACT_APP_API_URL + '/airports/'+ origin)
                    .then(response => {
                        lat1 = response.data.lat
                        lon1 = response.data.lon
                    })
                    .catch(error => {
                        console.log(error.message)
                    })

                //Get dest coordinates
                await axios.get(process.env.REACT_APP_API_URL + '/airports/'+ destination)
                .then(response => {
                    lat2 = response.data.lat
                    lon2 = response.data.lon
                })
                .catch(error => {
                    console.log(error.message)
                })
                
                var R = 6371; // Radius of the earth in km
                var dLat = deg2rad(lat2-lat1);  // deg2rad below
                var dLon = deg2rad(lon2-lon1); 
                var a = 
                    Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
                    Math.sin(dLon/2) * Math.sin(dLon/2)
                    ; 
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
                var d = R * c; // Distance in km
                distance = d.toFixed(2)

                newSubItems.find(subItem => subItem.id === record).consumption = distance
                
            }

            setShowModalLoading(false);
        }else{
            window.alert('No records with "flight" in the transport mode')
        }

    }

    const handlePostcodes = async () => {

        let origins = ''
        let destinations = ''
        let distance = ''
        let distances = []
        let postcodesArray = []
        let originsArray = []
        let destinationsArray = []
        var subItemIndex
        let startRecord = 0
        let endRecord = 0
        let originPostcode = ''
        let destPostcode = ''
        var newSubItems = [ ...subItems ]
        let postcodeCount = 0
        let originCount = 0
        let destinationCount = 0
        let kms = 0

        if (processWhat==="All" || processWhat===""){
            // check that there are some records with from and to data
            for (subItemIndex = 0; subItemIndex < subItems.length; subItemIndex++) {
                
                // originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
                // destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()

                if (subItems[subItemIndex].fromPostCode!=="" && subItems[subItemIndex].toPostCode!=="") {
                    originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
                    destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()
                    //check for multiple postcodes
                    //first postcode
                    if (subItemIndex===0){
                        originsArray.push(originPostcode)
                        originCount=1
                        destinationsArray.push(destPostcode)
                        destinationCount=1
                    }else{
                        if (!originsArray.includes(originPostcode)){
                            originsArray.push(originPostcode)
                            originCount++
                        }
                        if (!destinationsArray.includes(destPostcode)){
                            destinationsArray.push(destPostcode)
                            destinationCount++
                        }
                    }
                    
                    //Get all the postcodes into an array
                    postcodesArray.push({id: subItems[subItemIndex].id, origin: subItems[subItemIndex].fromPostCode, destination: subItems[subItemIndex].toPostCode});
                    postcodeCount++

                }
            }
        }else if (processWhat==="Zero"){
            // check that there are some records with from and to data
            for (subItemIndex = 0; subItemIndex < subItems.length; subItemIndex++) {
                
                // originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
                // destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()
                kms = subItems[subItemIndex].consumption

                if (subItems[subItemIndex].fromPostCode!=='' && subItems[subItemIndex].toPostCode!=='' && kms===0){

                    originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
                    destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()

                    //check for multiple postcodes
                    //first postcode
                    if (subItemIndex===0){
                        originsArray.push(originPostcode)
                        originCount=1
                        destinationsArray.push(destPostcode)
                        destinationCount=1
                    }else{
                        if (!originsArray.includes(originPostcode)){
                            originsArray.push(originPostcode)
                            originCount++
                        }
                        if (!destinationsArray.includes(destPostcode)){
                            destinationsArray.push(destPostcode)
                            destinationCount++
                        }
                    }
                    
                    //Get all the postcodes into an array
                    postcodesArray.push({id: subItems[subItemIndex].id, origin: subItems[subItemIndex].fromPostCode, destination: subItems[subItemIndex].toPostCode});
                    postcodeCount++

                }
            }
        }

        //If they are, run the code that checks 25 origins per pass
        if (postcodeCount > 0){

            setTotalNumberOfRecordsToImport(postcodesArray.length)

            //Check number of origins and destinations to determine which code to run
            if ((originCount>1 && destinationCount>1) || (originCount===1 && destinationCount===1)) {
                if (window.confirm("Postcodes will be calculated on a line by line basis.  Would you like to continue?") === false){
                    return
                }else{
                    let totalLoops = postcodesArray.length

                    setShowModalLoading(true);

                    for (let index = 0; index < totalLoops; index++) {

                        setCurrentRecordToImport(index)

                        origins = postcodesArray[index].origin.replaceAll('/', '')
                        destinations = postcodesArray[index].destination.replaceAll('/', '')

                        //Get distance data
                        await axios.get(process.env.REACT_APP_API_URL + '/DistanceMatrix/'+ origins +'/'+ destinations)
                        .then(response => {
                            console.log(response)
                            distances = response.data
                        })
                        .then(()=>{
                            // for (let index2 = 0; index2 < distances.rows.length; index2++) {
                                const record = postcodesArray[index].id;
                                if (distances.rows[0].elements[0].status!=="OK"){
                                    distance=0
                                }else{
                                    distance=distances.rows[0].elements[0].distance.text
                                    distance=distance.replace(/\,/g,'') //remove commas
                                    distance=distance.replace(' km','') //remove km
                                }
                                newSubItems.find(subItem => subItem.id === record).consumption = distance
                            // }
                        })
                        .catch(error => {
                            console.log(error.message)
                        })

                        //Increase the counter to get the next set of records
                        origins = ''
                        destinations = ''
                                            
                    }
                }
            }else if (originCount>1 && destinationCount===1) {
                console.log("multiple origins and single destination")
                
                setShowModalLoading(true);

                // Maximum number of objects than can be called (per origin/dest list) is 25        
                if (originsArray.length<=25){
                    endRecord=originsArray.length
                }else{
                    endRecord=startRecord+25
                }

                // Get the number of loops needed
                let totalLoops = postcodesArray.length/25

                for (let indexAll = 0; indexAll < totalLoops; indexAll++) {

                    setCurrentRecordToImport(startRecord)

                    for (let index = startRecord; index < endRecord; index++) {
                        origins = origins === '' ? origins + postcodesArray[index].origin.replaceAll('/', '') : origins + '|' + postcodesArray[index].origin.replaceAll('/', '')
                    }

                    //Get distance data
                    await axios.get(process.env.REACT_APP_API_URL + '/DistanceMatrix/'+ origins +'/'+ destPostcode)
                    .then(response => {
                        console.log(response)
                        distances = response.data
                    })
                    .then(()=>{
                        for (let index2 = 0; index2 < distances.rows.length; index2++) {
                            const record = postcodesArray[startRecord+index2].id;
                            if (distances.rows[index2].elements[0].status!=="OK"){
                                distance=0
                            }else{
                                distance=distances.rows[index2].elements[0].distance.text
                                distance=distance.replace(/\,/g,'') //remove commas
                                distance=distance.replace(' km','') //remove km
                            }
                            newSubItems.find(subItem => subItem.id === record).consumption = distance
                        }
                    })
                    .catch(error => {
                        console.log(error.message)
                    })

                    //Increase the counter to get the next set of records
                    origins = ''
                    destinations = ''
                    startRecord = startRecord+25
                    if (endRecord+25 >= postcodesArray.length){
                        endRecord=postcodesArray.length
                    }else{
                        endRecord=endRecord+25
                    }
                    
                }

            }else if (originCount===1 && destinationCount>1) {
                console.log("single origin and multiple destinations")

                setShowModalLoading(true);

                // Maximum number of objects than can be called (per origin/dest list) is 25        
                if (destinationsArray.length<=25){
                    endRecord=destinationsArray.length
                }else{
                    endRecord=startRecord+25
                }

                // Get the number of loops needed
                let totalLoops = postcodesArray.length/25

                for (let indexAll = 0; indexAll < totalLoops; indexAll++) {

                    setCurrentRecordToImport(startRecord)

                    for (let index = startRecord; index < endRecord; index++) {
                        // origins = origins === '' ? origins + postcodesArray[index].origin.replaceAll('/', '') : origins + '|' + postcodesArray[index].origin.replaceAll('/', '')
                        destinations = destinations === '' ? destinations + postcodesArray[index].destination.replaceAll('/', '') : destinations + '|' + postcodesArray[index].destination.replaceAll('/', '')
                    }

                    //Get distance data
                    await axios.get(process.env.REACT_APP_API_URL + '/DistanceMatrix/'+ originPostcode +'/'+ destinations)
                    .then(response => {
                        console.log(response)
                        distances = response.data
                    })
                    .then(()=>{
                        for (let index2 = 0; index2 < distances.rows[0].elements.length; index2++) {
                            const record = postcodesArray[startRecord+index2].id;
                            if (distances.rows[0].elements[index2].status!=="OK"){
                                distance=0
                            }else{
                                distance=distances.rows[0].elements[index2].distance.text
                                distance=distance.replace(/\,/g,'') //remove commas
                                distance=distance.replace(' km','') //remove km
                            }
                            newSubItems.find(subItem => subItem.id === record).consumption = distance
                        }
                    })
                    .catch(error => {
                        console.log(error.message)
                    })

                    //Increase the counter to get the next set of records
                    origins = ''
                    destinations = ''
                    startRecord = startRecord+25
                    if (endRecord+25 >= postcodesArray.length){
                        endRecord=postcodesArray.length
                    }else{
                        endRecord=endRecord+25
                    }
                    
                }

            }

        }else{
            window.alert("Error - There do not seem to be any records with TO and FROM Postcodes")
        }

        setSelectedComments(aggregateCommentsArray(newSubItems).map((item) => {
            return {
                label: item.comments,
                value: item.comments
            }
        }))

        setSelectedSuppliers(aggregateSuppliersArray(newSubItems).map((item) => {
            return {
                label: item.supplier,
                value: item.supplier
            }
        }))

        setSelectedFacilities(aggregateFacilitiesArray(newSubItems).map((item) => {
            return {
                label: item.facility,
                value: item.facility
            }
        }))

        setSelectedSubCategories(aggregateSubCategoriesArray(newSubItems).map((item) => {
            return {
                label: item.subCategory,
                value: item.subCategory
            }
        }))

        setSelectedUnits(aggregateUnitsArray(newSubItems).map((item) => {
            return {
                label: item.unit,
                value: item.unit
            }
        }))

        setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
            return {
                label: item.emissionFactorSource,
                value: item.emissionFactorSource
            }
        }))

        setSubItems(newSubItems)

        setShowModalLoading(false);

    };

    const csvFileToArray = string => {

            const csvHeader = ["id", "description", "supplier", "facility", "individual", "fromPostCode", "toPostCode", "transportMode", "consumption", "multiplier", "unit", "emissionFactor", "carbonEmissions", "comments", "emissionFactorSource", "multiplierFactorSource"]
            const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

            //drop the last line (blank)
            csvRows.pop()

            var lengthCheck = true

            const array = csvRows.map(i => {

                //const values = i.split(",")

                const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)

                var field

                if (values.length !== 16) {
                    lengthCheck = false
                }

                for (field = 0; field < values.length; field++) {

                    values[field] = values[field].replace(/^"|"$/g, '')
                    values[field] = values[field].replace(/""/g, '"')
                    values[field] = values[field].replace(/^"|"$/, '')

                    //If the last field has any commas then a traling quotation mark (") still remains before the end of the line
                    //This code checks for this and removes the quotation mark (")

                    if (values[field].slice(values[field].length - 2, values[field].length - 1) === '"') {
                        values[field] = values[field].slice(0, values[field].length - 2)
                    }
                }

                const obj = csvHeader.reduce((object, header, index) => {
                    object[header] = values[index];
                    return object;
                }, {});
                return obj;

            })

            if (array.filter(item => isNaN(item.consumption)).length > 0 || array.filter(item => isNaN(item.multiplier)).length > 0 || array.filter(item => isNaN(item.emissionFactor)).length > 0 || array.filter(item => isNaN(item.carbonEmissions)).length > 0) {
                window.alert("Invalid CSV file formatting.  Please ensure that all data is formatted in 'General' format")
                return
            }

            if (!lengthCheck) {
                window.alert("Invalid CSV file formatting.  Please ensure that the CSV file headers are present as follows:\nfootprintSubItemId - description - supplier - facility - individual - fromPostCode - toPostCode - transportMode - consumption - multiplier - unit - emissionFactor - carbonEmissions - comments - emissionFactorSource - multiplierFactorSource")
                return
            }

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            //Reduce to itemSubCategory and sum carbonEmissions
            var result = [];
            result = array

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            //code here to generate uniqueIds for each line
            var subItemIndex, checkWord

            for (subItemIndex = 0; subItemIndex < result.length; subItemIndex++) {
                result[subItemIndex].id = uuidv4()
                result[subItemIndex].consumption = Number(result[subItemIndex].consumption)
                result[subItemIndex].emissionFactor = Number(result[subItemIndex].emissionFactor)
                result[subItemIndex].carbonEmissions = Number(result[subItemIndex].carbonEmissions)

                //Code needed here to add footprintId, organisationId, subCategory, year
                result[subItemIndex] = { ...result[subItemIndex], footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === result[subItemIndex].comments).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === result[subItemIndex].comments).id }

                for (checkWord = 0; checkWord < keyWordsCheck.length; checkWord++) {

                    if (result[subItemIndex].description.toLowerCase().includes(keyWordsCheck[checkWord].keyWord.toLowerCase()) && footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === result[subItemIndex].comments).itemSubCategory === "3.1 Purchased Goods & Services") {
                        //USE THE FIRST ITEMDESCRIPTION WITH THAT SUBCATEGORY (NOT JUST THE SUBCATEGORY ITSELF)
                        if (footprint.organisationFootprintItems.filter(footprintItem => footprintItem.itemSubCategory === keyWordsCheck[checkWord].subCategory).length > 0) {
                            result[subItemIndex].comments = footprint.organisationFootprintItems.filter(footprintItem => footprintItem.itemSubCategory === keyWordsCheck[checkWord].subCategory)[0].itemDescription
                        } else {
                            result[subItemIndex].comments = keyWordsCheck[checkWord].subCategory
                        }
                    }

                }

            }

            var newSubItems

            newSubItems = subItems.concat(result)

            setSelectedComments(aggregateCommentsArray(newSubItems).map((item) => {
                return {
                    label: item.comments,
                    value: item.comments
                }
            }))

            setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                return {
                    comments: item.comments
                }
            }))

            setSelectedSuppliers(aggregateSuppliersArray(newSubItems).map((item) => {
                return {
                    label: item.supplier,
                    value: item.supplier
                }
            }))

            setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                return {
                    supplier: item.supplier
                }
            }))
    
            setSelectedFacilities(aggregateFacilitiesArray(newSubItems).map((item) => {
                return {
                    label: item.facility,
                    value: item.facility
                }
            }))

            setSelectedSubCategories(aggregateSubCategoriesArray(newSubItems).map((item) => {
                return {
                    label: item.subCategory,
                    value: item.subCategory
                }
            }))

            setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                return {
                    facility: item.facility
                }
            }))

            setSubCategoriesToSelect(aggregateSubCategoriesArray(newSubItems).map((item) => {
                return {
                    subCategory: item.subCategory
                }
            }))

            setSelectedUnits(aggregateUnitsArray(newSubItems).map((item) => {
                return {
                    label: item.unit,
                    value: item.unit
                }
            }))

            setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                return {
                    unit: item.unit
                }
            }))
    
            setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    label: item.emissionFactorSource,
                    value: item.emissionFactorSource
                }
            }))

            setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    emissionFactorSource: item.emissionFactorSource
                }
            }))

            setSubItems(newSubItems)

            window.alert("Pulled in " + result.length + " records")

    };

    const viewCarbonFactors = () => {
        setShowModal(true)
        setMultiplierSelection(false)
    }

    const viewCarbonFactorsForMultiplier = () => {
        setShowModal(true)
        setMultiplierSelection(true)
    }

    const setSelectedFactor = (e, datasource) => {
        setEmissionFactorToAdd(e)
        setEmissionFactorSourceToAdd(datasource)
    }

    const setSelectedMultiplier = (e, datasource) => {
        setMultiplierToAdd(e)
        setMultiplierFactorSourceToAdd(datasource)
    }

    const setSubItemsToXeroData = (e) => {

        setSubItems(e)

        setSelectedComments(aggregateCommentsArray(e).map((item) => {
            return {
                label: item.comments,
                value: item.comments
            }
        }))

        setSelectedSuppliers(aggregateSuppliersArray(e).map((item) => {
            return {
                label: item.supplier,
                value: item.supplier
            }
        }))

        setSelectedFacilities(aggregateFacilitiesArray(e).map((item) => {
            return {
                label: item.facility,
                value: item.facility
            }
        }))

        setSelectedSubCategories(aggregateSubCategoriesArray(e).map((item) => {
            return {
                label: item.subCategory,
                value: item.subCategory
            }
        }))

        setSelectedUnits(aggregateUnitsArray(e).map((item) => {
            return {
                label: item.unit,
                value: item.unit
            }
        }))

        setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(e).map((item) => {
            return {
                label: item.emissionFactorSource,
                value: item.emissionFactorSource
            }
        }))

    }

    const resetFilters = () => {

        setSearchText("")

        setSelectedComments(aggregateCommentsArray(subItems).map((item) => {
            return {
                label: item.comments,
                value: item.comments
            }
        }))

        setSelectedSuppliers(aggregateSuppliersArray(subItems).map((item) => {
            return {
                label: item.supplier,
                value: item.supplier
            }
        }))

        setSelectedFacilities(aggregateFacilitiesArray(subItems).map((item) => {
            return {
                label: item.facility,
                value: item.facility
            }
        }))

        setSelectedSubCategories(aggregateSubCategoriesArray(subItems).map((item) => {
            return {
                label: item.subCategory,
                value: item.subCategory
            }
        }))

        setSelectedUnits(aggregateUnitsArray(subItems).map((item) => {
            return {
                label: item.unit,
                value: item.unit
            }
        }))

        setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(subItems).map((item) => {
            return {
                label: item.emissionFactorSource,
                value: item.emissionFactorSource
            }
        }))

        setSubItemsFiltered(subItems)

        setSubItemsFilteredNumberOfItems(subItems.length)

        setSubItemsFilteredTotaltCO2e((subItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000).toFixed(2))

    }

    const editFootprintSubItem = (e) => {

        setEditToggle(true)
        setEditSelectedToggle(false)
        setDescriptionToAdd(e.description)
        descriptionInput.current.value = e.description
        setSupplierToAdd(e.supplier)
        // supplierInput.current.value = e.supplier
        setFacilityToAdd(e.facility)
        // facilityInput.current.value = e.facility
        setIndividualToAdd(e.individual)
        setFromPostCodeToAdd(e.fromPostCode)
        setToPostCodeToAdd(e.toPostCode)
        setFormattedConsumption(e.consumption)
        setMultiplierToAdd(e.multiplier)
        setUnitToAdd(e.unit)
        setSelectedTransportModeToAdd({value: e.transportMode, label: e.transportMode} )
        setEmissionFactorToAdd(e.emissionFactor)
        setFormattedEmissions(e.carbonEmissions)
        setCommentsToAdd(e.comments)
        setEmissionFactorSourceToAdd(e.emissionFactorSource)
        setMultiplierFactorSourceToAdd(e.multiplierFactorSource)
        setSubItemIdToAdd(e.id)
        descriptionInput.current.focus()

    }

    const editSelectedFootprintSubItems = () => {

        if (subItems.filter(item => item.individual === "SELECTED").length === 1) {
            setEditSelectedToggle(false)
            setEditToggle(true)
            setDescriptionToAdd(subItems.filter(item => item.individual === "SELECTED")[0].description)
            descriptionInput.current.value = subItems.filter(item => item.individual === "SELECTED")[0].description
            setSupplierToAdd(subItems.filter(item => item.individual === "SELECTED")[0].supplier)
            // supplierInput.current.value = subItems.filter(item => item.individual === "SELECTED")[0].supplier
            setFacilityToAdd(subItems.filter(item => item.individual === "SELECTED")[0].facility)
            // facilityInput.current.value = subItems.filter(item => item.individual === "SELECTED")[0].facility
            setFromPostCodeToAdd(subItems.filter(item => item.individual === "SELECTED")[0].fromPostCode)
            setToPostCodeToAdd(subItems.filter(item => item.individual === "SELECTED")[0].toPostCode)
            setFormattedConsumption(subItems.filter(item => item.individual === "SELECTED")[0].consumption)
            setMultiplierToAdd(subItems.filter(item => item.individual === "SELECTED")[0].multiplier)
            setUnitToAdd(subItems.filter(item => item.individual === "SELECTED")[0].unit)
            setSelectedTransportModeToAdd({value: subItems.filter(item => item.individual === "SELECTED")[0].transportMode, label: subItems.filter(item => item.individual === "SELECTED")[0].transportMode} )
            setEmissionFactorToAdd(subItems.filter(item => item.individual === "SELECTED")[0].emissionFactor)
            setFormattedEmissions(subItems.filter(item => item.individual === "SELECTED")[0].carbonEmissions)
            setCommentsToAdd(subItems.filter(item => item.individual === "SELECTED")[0].comments)
            setEmissionFactorSourceToAdd(subItems.filter(item => item.individual === "SELECTED")[0].emissionFactorSource)
            setMultiplierFactorSourceToAdd(subItems.filter(item => item.individual === "SELECTED")[0].multiplierFactorSource)
            setSubItemIdToAdd(subItems.filter(item => item.individual === "SELECTED")[0].id)
            commentsInput.current.focus()
        } else {
            setEditSelectedToggle(true)
            setEditToggle(false)
            setCommentsToAdd("<CURRENT VALUE>")
            setFacilityToAdd("<CURRENT VALUE>")
            // facilityInput.current.value = "<CURRENT VALUE>"
            setEmissionFactorToAdd(-99999)
            setEmissionFactorSourceToAdd("<CURRENT VALUE>")
            setDescriptionToAdd("")
            descriptionInput.current.value = ""
            setSupplierToAdd("")
            supplierInput.current.value = ""
            setConsumptionToAdd(0)
            setMultiplierToAdd(-99999)
            setUnitToAdd("")
            setCarbonEmissionsToAdd(0)
            setFromPostCodeToAdd("")
            setToPostCodeToAdd("")
            setSelectedTransportModeToAdd({value: "", label: ""})
            setMultiplierFactorSourceToAdd("<CURRENT VALUE>")

            commentsInput.current.focus()
        }

    }

    const cancelAddOrEditSubItem = (e) => {

        setDescriptionToAdd("")
        descriptionInput.current.value = ""
        setSupplierToAdd("")
        // supplierInput.current.value = ""
        setFacilityToAdd("")
        // facilityInput.current.value = ""
        setIndividualToAdd("")
        setFromPostCodeToAdd("")
        setToPostCodeToAdd("")
        setFormattedConsumption(0)
        setMultiplierToAdd(1)
        setUnitToAdd("")
        setSelectedTransportModeToAdd({value: "", label: ""})
        setEmissionFactorToAdd(0)
        setFormattedEmissions(0)
        setCommentsToAdd("")
        setEmissionFactorSourceToAdd("")
        setMultiplierFactorSourceToAdd("")
        setEditToggle(false)
        setEditSelectedToggle(false)
    }

    const calculateAllEmissionFactors = async () => {

        //loop through all records in subItems

        console.log("Entered Function")

        var emissionFactor
        var carbonEmissions
        var modeOfTransport
        var unit
        var subItemIndex
        var newSubItems
        var multiplier
        var consumption

        newSubItems = [ ...subItems ]

        for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {

            modeOfTransport = newSubItems[subItemIndex].transportMode
            unit = newSubItems[subItemIndex].unit
            multiplier = newSubItems[subItemIndex].multiplier
            consumption = newSubItems[subItemIndex].consumption

            emissionFactor = carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === modeOfTransport && item.UOMsimple === unit).GHGConversion
            carbonEmissions = emissionFactor * multiplier * consumption


            newSubItems[subItemIndex].emissionFactor = emissionFactor
            newSubItems[subItemIndex].carbonEmissions = carbonEmissions

        }

        console.log("exited loop")

        setSubItems(newSubItems)

    }

    const calculateAllEmissions = async () => {

        //loop through all records in subItems

        console.log("Entered Function")

        var emissionFactor
        var carbonEmissions
        var subItemIndex
        var newSubItems
        var multiplier
        var consumption

        newSubItems = [ ...subItems ]

        for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {

            multiplier = newSubItems[subItemIndex].multiplier
            consumption = newSubItems[subItemIndex].emissionFactor
            emissionFactor = newSubItems[subItemIndex].consumption

            carbonEmissions = emissionFactor * multiplier * consumption

            newSubItems[subItemIndex].carbonEmissions = carbonEmissions

        }

        console.log("exited loop")

        setSubItems(newSubItems)

    }

    const onSubmit = async () => {

        if (!isNaN(subItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0))) {

            setSaving(true)

            var newFootprint

            await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
            .then((res) => {

                newFootprint = res.data
                setFootprint(res.data)

            })
            .catch((err) => {
                console.log(err);
            })

            if (subItems.length > 0 || subItemsToDelete.length > 0) {

                var oneMonthsActual
                var numberOfMonths

                //Code here to add to emissions and consumption of footrpintIds that subItems are being moved to
                //**********************************************************************************************
                var footprintItemInstance
                var listOfFootprintItems = aggregateFootprintItemIdsArray(subItems)

                for (footprintItemInstance = 0; footprintItemInstance < listOfFootprintItems.length; footprintItemInstance++) {
                    if (!newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).itemDescription.includes("Market Based")) {
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions = subItems.filter(item => item.footprintItemId === listOfFootprintItems[footprintItemInstance].footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).unit = "various"
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).emissionFactor = 1
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).consumption = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions
        
                        oneMonthsActual = 0
                        numberOfMonths = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).numberOfMonths
        
                        if (numberOfMonths < 12) {
                            oneMonthsActual = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions / numberOfMonths
                            newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).additionalEstimatedEmissions = (oneMonthsActual * (12 - numberOfMonths))
                        } else {
                            newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).additionalEstimatedEmissions = 0
                        }
                    } else {
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions = 0
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).emissionFactor = 0
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).unit = "kgCO2e"
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).consumption = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                    }

                }
                //**********************************************************************************************

                // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit = "various"
                // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor = 1
                // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)

                // oneMonthsActual = 0
                // numberOfMonths = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).numberOfMonths

                // if (numberOfMonths < 12) {
                //     oneMonthsActual = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions / numberOfMonths
                //     newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = (oneMonthsActual * (12 - numberOfMonths))
                // } else {
                //     newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = 0
                // }

                updateForm(newFootprint).then(res => { if (window.history.state.idx > 1) {

                    navigate(-1)
        
                } else {
        
                    window.close()
                } })

            } else {

                updateForm(newFootprint).then(res => { if (window.history.state.idx > 1) {

                    navigate(-1)
        
                } else {
        
                    window.close()
                } })
            }

        } else {

            window.alert("Unable to import data.  Please review imported data for invalid data items - Tip: Search the data shown below for NaN data items and remove any carriage returns within data fields and address any non-numeric formatting in the source CSV.")

        }

    }

    const saveOnly = async () => {

        if (!isNaN(subItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0))) {

            setSaving(true)

            var newFootprint

            await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
            .then((res) => {

                newFootprint = res.data
                setFootprint(res.data)

            })
            .catch((err) => {
                console.log(err);
            })

            if (subItems.length > 0 || subItemsToDelete.length > 0) {

                var oneMonthsActual
                var numberOfMonths

                //Code here to add to emissions and consumption of footrpintIds that subItems are being moved to
                //**********************************************************************************************
                var footprintItemInstance
                var listOfFootprintItems = aggregateFootprintItemIdsArray(subItems)

                for (footprintItemInstance = 0; footprintItemInstance < listOfFootprintItems.length; footprintItemInstance++) {
                    if (!newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).itemDescription.includes("Market Based")) {
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions = subItems.filter(item => item.footprintItemId === listOfFootprintItems[footprintItemInstance].footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).unit = "various"
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).emissionFactor = 1
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).consumption = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions
        
                        oneMonthsActual = 0
                        numberOfMonths = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).numberOfMonths
        
                        if (numberOfMonths < 12) {
                            oneMonthsActual = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions / numberOfMonths
                            newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).additionalEstimatedEmissions = (oneMonthsActual * (12 - numberOfMonths))
                        } else {
                            newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).additionalEstimatedEmissions = 0
                        }
                    } else {
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions = 0
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).emissionFactor = 0
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).unit = "kgCO2e"
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).consumption = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                    }

                }
                //**********************************************************************************************

                // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit = "various"
                // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor = 1
                // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)

                // oneMonthsActual = 0
                // numberOfMonths = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).numberOfMonths

                // if (numberOfMonths < 12) {
                //     oneMonthsActual = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions / numberOfMonths
                //     newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = (oneMonthsActual * (12 - numberOfMonths))
                // } else {
                //     newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = 0
                // }

                await updateForm(newFootprint)

                // setSubItems(subItems.filter(item => item.footprintItemId === match.params.footprintItemId))

            } else {

                updateForm(newFootprint)
            
            }

        } else {

            window.alert("Unable to import data.  Please review imported data for invalid data items - Tip: Search the data shown below for NaN data items and remove any carriage returns within data fields and address any non-numeric formatting in the source CSV.")

        }
    }

    const updateForm = async (form) => {

        await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, form)

        if (subItems.filter(item => item.id.length !== 24).length > 0) {
            await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems', subItems.filter(item => item.id.length !== 24))
        }
        if (subItemsToDelete.length > 0) {
            await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems_delete', {"subitems" : subItemsToDelete})
        }
        if (subItems.filter(item => item.id.length === 24).length > 0) {
            await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems', subItems.filter(item => item.id.length === 24))
        }

        setReloadDataToggle(true)

        // console.log(AuthService.getCurrentUserName() + " Saved Sub Items for Footprint: " + match.params.footprintId + ", Footprint Item: " + match.params.footprintItemId + " Sub Items total: " + subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) + "; Item Level total: " + form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions)

        // axios.post(process.env.REACT_APP_API_URL + "/log", {message: AuthService.getCurrentUserName() + " Saved Sub Items for Footprint: " + match.params.footprintId + ", Footprint Item: " + match.params.footprintItemId + " Sub Items total: " + subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) + "; Item Level total: " + form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions})

        window.alert("Footprint Sub Items Saved")

        setSaving(false)

    }

    const onClose = () => {


        if (window.history.state.idx > 1) {

            navigate(-1)

        } else {

            window.close()
        }

    }

    const ocr_PDF = async () => {

        if (commentsToAdd.slice (0,4) === "1.1 " || commentsToAdd.slice (0,4) === "2.1 ") {

            setShowModalLoading(true);

            var document
            var result
            var consumptionForDateRange
            var emissionFactorRecord
            var tempDescriptionToAdd, tempConsumptionToAdd, tempUnitToAdd, tempEmissionFactorToAdd, tempEmissionFactorSourceToAdd, tempFormattedEmissions, tempCommentsToAdd
            var uniqueId
            var newSubItems = [...subItems ]
            var filenames = []
            var listOfScopeAndEntitiesAdded = []
            var selectedFiles = files.filter(item => item.selected === -1)

            for (document = 0; document < selectedFiles.length; document++) {
                if (selectedFiles[document].name.slice(-3) === "pdf" || selectedFiles[document].name.slice(-4) === "xlsx" || selectedFiles[document].name.slice(-4) === "tiff") {
                    filenames.push({"filename": selectedFiles[document].name})
                }
            }
            
            setTotalNumberOfRecordsToImport(filenames.length)

            for (document = 0; document < filenames.length; document++) {

                var system_role
                var user_role

                if (commentsToAdd.slice (0,4) === "1.1 " && filenames[document].filename.slice(-3) === "pdf") { 

                    system_role = "You are an expert in reading utility bills and extracting relevant information."
                    user_role = "Extract the kWh usage and the billing period from the following gas bill text.  While the usage needs to be broken down by date periods, be careful not to include any seperate total found within the document as one of the usage records otherwise the total will be overstated. (and return the results as a json array of objects (no accompanying text please in the response) with fromDate (yyyy-mm-dd), toDate (yyyy-mm-dd), unit, consumption):\n\n"

                }

                if (commentsToAdd.slice (0,4) === "1.1 " && filenames[document].filename.slice(-4) === "xlsx") { 

                    system_role = "You are an expert in reading json data exported from differently formed Excel files extracting relevant information relating to gas usage."
                    user_role = "Extract the kWh usage and the billing period from the following json data. The year we are interested in is: " + footprint.organisationFootprintYear + " - if the data does not contain information about the year then assume that the data relates to " + footprint.organisationFootprintYear + "  While the usage needs to be broken down by date periods, be careful not to include any seperate total found within the document as one of the usage records otherwise the total will be overstated. (and return the results explicitly as a json array of objects (no accompanying text please in the response) with fromDate (yyyy-mm-dd), toDate (yyyy-mm-dd), unit, consumption):\n\n"

                    console.log(user_role)

                }

                if (commentsToAdd.slice (0,4) === "2.1 " && (filenames[document].filename.slice(-3) === "pdf" || filenames[document].filename.slice(-4) === "tiff")) { 

                    system_role = "You are an expert in reading utility bills and extracting relevant information."
                    user_role = "Extract the kWh usage and the billing period from the following electricity bill text.  While the usage needs to be broken down by date periods, be careful not to include any seperate total found within the document as one of the usage records otherwise the total will be overstated. (and return the results as a json array of objects (no accompanying text please in the response) with fromDate (yyyy-mm-dd), toDate (yyyy-mm-dd), unit, consumption):\n\n"

                }

                if (commentsToAdd.slice (0,4) === "2.1 " && filenames[document].filename.slice(-4) === "xlsx") { 

                    system_role = "You are an expert in reading json data exported from differently formed Excel files extracting relevant information relating to electricity usage."
                    user_role = "Extract the kWh usage and the billing period from the following json data. The year we are interested in is: " + footprint.organisationFootprintYear + " - if the data does not contain information about the year then assume that the data relates to " + footprint.organisationFootprintYear + ". While the usage needs to be broken down by date periods, be careful not to include any seperate total found within the document as one of the usage records otherwise the total will be overstated. (and return the results explicitly as a json array of objects (no accompanying text please in the response) with fromDate (yyyy-mm-dd), toDate (yyyy-mm-dd), unit, consumption):\n\n"

                }

                setCurrentRecordToImport(document + 1)

                console.log(system_role)

                console.log(user_role)


                if (filenames[document].filename.slice(-3) === "pdf" || filenames[document].filename.slice(-4) === "tiff") {
                    result = await axios.post(process.env.REACT_APP_API_URL + '/OCR',
                        {"filename": filenames[document].filename, "system_role": system_role, "user_role": user_role}
                    )
                }

                if (filenames[document].filename.slice(-4) === "xlsx") {
                    result = await axios.post(process.env.REACT_APP_API_URL + '/ExcelDataExtraction',
                        {"filename": filenames[document].filename, "system_role": system_role, "user_role": user_role}
                    )
                }

                console.log(result)

                if (window.confirm("OK, I've done the tedious time intensive admin for you.  This is the first record from this document: " + result.data.extracted_data[0].fromDate + " - " + result.data.extracted_data[0].toDate + " consunption was " + result.data.extracted_data[0].consumption + " " + result.data.extracted_data[0].unit + "\nWould you like me to add the data based on my findings?")) {

                    for (consumptionForDateRange = 0; consumptionForDateRange < result.data.extracted_data.length; consumptionForDateRange++) {

                        if (commentsToAdd.slice (0,4) === "1.1 ") {

                            tempDescriptionToAdd = (result.data.extracted_data[consumptionForDateRange].fromDate + " - " + result.data.extracted_data[consumptionForDateRange].toDate + " Gas from document: " + filenames[document].filename.substring(37, filenames[document].filename.length))
                            tempConsumptionToAdd = (result.data.extracted_data[consumptionForDateRange].consumption)
                            tempUnitToAdd = (result.data.extracted_data[consumptionForDateRange].unit)
            
                            //************* */
                            //Need to get fuelTypeToAdd from ChatGPT also!!!!!!
                            //How to match that to Level3 fuelType in our DB?!??!?!?
                            //FOR NOW ASSUME NATURAL GAS
                            //************* */

                            if (carbonEmissionFactors.find(item => item.Level3 === fuelTypeToAdd && item.UOMsimple === result.data.extracted_data[consumptionForDateRange].unit && item.Year === footprint.organisationFootprintYear) && calculationMethodToAdd === "Actual" && commentsToAdd.slice(0,4) === "1.1 ") {
                                emissionFactorRecord = carbonEmissionFactors.find(item => item.Level3 === fuelTypeToAdd && item.UOMsimple === result.data.extracted_data[consumptionForDateRange].unit && item.Year === footprint.organisationFootprintYear)
                                tempEmissionFactorToAdd = (emissionFactorRecord.GHGConversion)
                                tempEmissionFactorSourceToAdd = (emissionFactorRecord.id + "; " + emissionFactorRecord.DataSource + "; " + emissionFactorRecord.Scope + "; " + emissionFactorRecord.Level1 + "; " + emissionFactorRecord.Level2 + "; " + emissionFactorRecord.Level3 + "; " + emissionFactorRecord.UOMsimple + "; " + emissionFactorRecord.Year + "; " + emissionFactorRecord.Region + "; " + emissionFactorRecord.Comments + "; " + emissionFactorRecord.ColumnText)
                                tempFormattedEmissions = (emissionFactorRecord.GHGConversion * result.data.extracted_data[consumptionForDateRange].consumption * multiplierToAdd)
                            }

                            uniqueId = uuidv4()

                            newSubItems.push({ id: uniqueId, description: tempDescriptionToAdd, supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: Number(removeCommas(tempConsumptionToAdd)), multiplier: multiplierToAdd, unit: tempUnitToAdd, emissionFactor: tempEmissionFactorToAdd, carbonEmissions: Number(removeCommas(tempFormattedEmissions)), comments: commentsToAdd, emissionFactorSource: tempEmissionFactorSourceToAdd, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === commentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === commentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })

                            listOfScopeAndEntitiesAdded = [{value: commentsToAdd, label: commentsToAdd}]
        
                            var tempEmissionFactorRecordFor3point3WTT_1_1 = carbonEmissionFactors.find(item => item.Level1 === "WTT- fuels" && item.Level3.toLowerCase() === fuelTypeToAdd.toLowerCase() && item.Comments.includes("Gross CV") && item.Year === footprint.organisationFootprintYear)
                            var tempEmissionFactorFor3point3SourceWTT_1_1 = tempEmissionFactorRecordFor3point3WTT_1_1.id + "; " + tempEmissionFactorRecordFor3point3WTT_1_1.DataSource + "; " + tempEmissionFactorRecordFor3point3WTT_1_1.Scope + "; " + tempEmissionFactorRecordFor3point3WTT_1_1.Level1 + "; " + tempEmissionFactorRecordFor3point3WTT_1_1.Level2 + "; " + tempEmissionFactorRecordFor3point3WTT_1_1.Level3 + "; " + tempEmissionFactorRecordFor3point3WTT_1_1.UOMsimple + "; " + tempEmissionFactorRecordFor3point3WTT_1_1.Year + "; " + tempEmissionFactorRecordFor3point3WTT_1_1.Region + "; " + tempEmissionFactorRecordFor3point3WTT_1_1.Comments + "; " + tempEmissionFactorRecordFor3point3WTT_1_1.ColumnText
                            var tempEmissionFactorFor3point3FactorWTT_1_1 = tempEmissionFactorRecordFor3point3WTT_1_1.GHGConversion
                            var tempEmissionsFor3point3TAndD = Number(removeCommas(tempConsumptionToAdd)) * multiplierToAdd * tempEmissionFactorFor3point3FactorWTT_1_1
        
                            tempCommentsToAdd = footprint.organisationFootprintItems.find(item => item.itemSubCategory.slice(0,4) === "3.3 " && item.itemEntity === footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).itemEntity).itemDescription
                            listOfScopeAndEntitiesAdded.push({value: tempCommentsToAdd, label: tempCommentsToAdd})
        
                            uniqueId = uuidv4()
        
                            newSubItems.push({ id: uniqueId, description: tempDescriptionToAdd + " WTT from document: " + filenames[document].filename.substring(37, filenames[document].filename.length), supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: removeCommas(tempConsumptionToAdd), multiplier: multiplierToAdd, unit: tempUnitToAdd, emissionFactor: tempEmissionFactorFor3point3FactorWTT_1_1, carbonEmissions: tempEmissionsFor3point3TAndD, comments: tempCommentsToAdd, emissionFactorSource: tempEmissionFactorFor3point3SourceWTT_1_1, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })
        
                        }

                        if (commentsToAdd.slice (0,4) === "2.1 ") {

                            tempDescriptionToAdd = (result.data.extracted_data[consumptionForDateRange].fromDate + " - " + result.data.extracted_data[consumptionForDateRange].toDate + " Electricty from document: " + filenames[document].filename.substring(37, filenames[document].filename.length))
                            tempConsumptionToAdd = (result.data.extracted_data[consumptionForDateRange].consumption)
                            tempUnitToAdd = (result.data.extracted_data[consumptionForDateRange].unit)
            
                            if (carbonEmissionFactors.find(item => item.Level1 === "UK electricity" && item.Level3 === "Electricity: UK" && item.UOMsimple === result.data.extracted_data[consumptionForDateRange].unit && item.Year === footprint.organisationFootprintYear) && calculationMethodToAdd === "Actual" && commentsToAdd.slice(0,4) === "2.1 ") {
                                emissionFactorRecord = carbonEmissionFactors.find(item => item.Level1 === "UK electricity" && item.Level3 === "Electricity: UK" && item.UOMsimple === "kWh" && item.Year === footprint.organisationFootprintYear)
                                tempEmissionFactorToAdd = (emissionFactorRecord.GHGConversion)
                                tempEmissionFactorSourceToAdd = (emissionFactorRecord.id + "; " + emissionFactorRecord.DataSource + "; " + emissionFactorRecord.Scope + "; " + emissionFactorRecord.Level1 + "; " + emissionFactorRecord.Level2 + "; " + emissionFactorRecord.Level3 + "; " + emissionFactorRecord.UOMsimple + "; " + emissionFactorRecord.Year + "; " + emissionFactorRecord.Region + "; " + emissionFactorRecord.Comments + "; " + emissionFactorRecord.ColumnText)
                                tempFormattedEmissions = (emissionFactorRecord.GHGConversion * result.data.extracted_data[consumptionForDateRange].consumption * multiplierToAdd)
                            }

                            uniqueId = uuidv4()

                            newSubItems.push({ id: uniqueId, description: tempDescriptionToAdd, supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: Number(removeCommas(tempConsumptionToAdd)), multiplier: multiplierToAdd, unit: tempUnitToAdd, emissionFactor: tempEmissionFactorToAdd, carbonEmissions: Number(removeCommas(tempFormattedEmissions)), comments: commentsToAdd, emissionFactorSource: tempEmissionFactorSourceToAdd, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === commentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === commentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })

                            listOfScopeAndEntitiesAdded = [{value: commentsToAdd, label: commentsToAdd}]
        
                            var tempEmissionFactorRecordFor3point3TAndD = carbonEmissionFactors.find(item => item.Level2 === "T&D- UK electricity" && item.Year === footprint.organisationFootprintYear)
                            var tempEmissionFactorFor3point3SourceTAndD = tempEmissionFactorRecordFor3point3TAndD.id + "; " + tempEmissionFactorRecordFor3point3TAndD.DataSource + "; " + tempEmissionFactorRecordFor3point3TAndD.Scope + "; " + tempEmissionFactorRecordFor3point3TAndD.Level1 + "; " + tempEmissionFactorRecordFor3point3TAndD.Level2 + "; " + tempEmissionFactorRecordFor3point3TAndD.Level3 + "; " + tempEmissionFactorRecordFor3point3TAndD.UOMsimple + "; " + tempEmissionFactorRecordFor3point3TAndD.Year + "; " + tempEmissionFactorRecordFor3point3TAndD.Region + "; " + tempEmissionFactorRecordFor3point3TAndD.Comments + "; " + tempEmissionFactorRecordFor3point3TAndD.ColumnText
                            var tempEmissionFactorFor3point3FactorTAndD = tempEmissionFactorRecordFor3point3TAndD.GHGConversion
                            var tempEmissionsFor3point3TAndD = Number(removeCommas(tempConsumptionToAdd)) * multiplierToAdd * tempEmissionFactorFor3point3FactorTAndD
        
                            tempCommentsToAdd = footprint.organisationFootprintItems.find(item => item.itemSubCategory.slice(0,4) === "3.3 " && item.itemEntity === footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).itemEntity).itemDescription
                            listOfScopeAndEntitiesAdded.push({value: tempCommentsToAdd, label: tempCommentsToAdd})
        
                            uniqueId = uuidv4()
        
                            newSubItems.push({ id: uniqueId, description: tempDescriptionToAdd + " T&D from document: " + filenames[document].filename.substring(37, filenames[document].filename.length), supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: removeCommas(tempConsumptionToAdd), multiplier: multiplierToAdd, unit: tempUnitToAdd, emissionFactor: tempEmissionFactorFor3point3FactorTAndD, carbonEmissions: tempEmissionsFor3point3TAndD, comments: tempCommentsToAdd, emissionFactorSource: tempEmissionFactorFor3point3SourceTAndD, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })
        
                            var tempEmissionFactorRecordFor3point3WTT = carbonEmissionFactors.find(item => item.Level2 === "WTT- UK electricity (generation)" && item.Year === footprint.organisationFootprintYear)
                            var tempEmissionFactorFor3point3SourceWTT = tempEmissionFactorRecordFor3point3WTT.id + "; " + tempEmissionFactorRecordFor3point3WTT.DataSource + "; " + tempEmissionFactorRecordFor3point3WTT.Scope + "; " + tempEmissionFactorRecordFor3point3WTT.Level1 + "; " + tempEmissionFactorRecordFor3point3WTT.Level2 + "; " + tempEmissionFactorRecordFor3point3WTT.Level3 + "; " + tempEmissionFactorRecordFor3point3WTT.UOMsimple + "; " + tempEmissionFactorRecordFor3point3WTT.Year + "; " + tempEmissionFactorRecordFor3point3WTT.Region + "; " + tempEmissionFactorRecordFor3point3WTT.Comments + "; " + tempEmissionFactorRecordFor3point3WTT.ColumnText
                            var tempEmissionFactorFor3point3FactorWTT = tempEmissionFactorRecordFor3point3WTT.GHGConversion
                            var tempEmissionsFor3point3WTT = Number(removeCommas(tempConsumptionToAdd)) * multiplierToAdd * tempEmissionFactorFor3point3FactorWTT
        
                            tempCommentsToAdd = footprint.organisationFootprintItems.find(item => item.itemSubCategory.slice(0,4) === "3.3 " && item.itemEntity === footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).itemEntity).itemDescription
                            listOfScopeAndEntitiesAdded.push({value: tempCommentsToAdd, label: tempCommentsToAdd})
        
                            uniqueId = uuidv4()
        
                            newSubItems.push({ id: uniqueId, description: tempDescriptionToAdd + " WTT- electricity (generation) from document: " + filenames[document].filename.substring(37, filenames[document].filename.length) , supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: removeCommas(tempConsumptionToAdd), multiplier: multiplierToAdd, unit: tempUnitToAdd, emissionFactor: tempEmissionFactorFor3point3FactorWTT, carbonEmissions: tempEmissionsFor3point3WTT, comments: tempCommentsToAdd, emissionFactorSource: tempEmissionFactorFor3point3SourceWTT, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })
        
                            var tempEmissionFactorRecordFor3point3WTTTAndD = carbonEmissionFactors.find(item => item.Level2 === "WTT- UK electricity (T&D)" && item.Year === footprint.organisationFootprintYear)
                            var tempEmissionFactorFor3point3SourceWTTTAndD = tempEmissionFactorRecordFor3point3WTTTAndD.id + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.DataSource + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Scope + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Level1 + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Level2 + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Level3 + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.UOMsimple + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Year + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Region + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Comments + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.ColumnText
                            var tempEmissionFactorFor3point3FactorWTTTAndD = tempEmissionFactorRecordFor3point3WTTTAndD.GHGConversion
                            var tempEmissionsFor3point3WTTTAndD = Number(removeCommas(tempConsumptionToAdd)) * multiplierToAdd * tempEmissionFactorFor3point3FactorWTT
        
                            tempCommentsToAdd = footprint.organisationFootprintItems.find(item => item.itemSubCategory.slice(0,4) === "3.3 " && item.itemEntity === footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).itemEntity).itemDescription
                            listOfScopeAndEntitiesAdded.push({value: tempCommentsToAdd, label: tempCommentsToAdd})
        
                            uniqueId = uuidv4()
        
                            newSubItems.push({ id: uniqueId, description: tempDescriptionToAdd + " WTT- UK electricity (T&D) from document: " + filenames[document].filename.substring(37, filenames[document].filename.length), supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: removeCommas(tempConsumptionToAdd), multiplier: multiplierToAdd, unit: tempUnitToAdd, emissionFactor: tempEmissionFactorFor3point3FactorWTTTAndD, carbonEmissions: tempEmissionsFor3point3WTTTAndD, comments: tempCommentsToAdd, emissionFactorSource: tempEmissionFactorFor3point3SourceWTTTAndD, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })
        
                        }
        
                        setSubItems(newSubItems)

                    }
        
                }

            }

            if (listOfScopeAndEntitiesAdded.length > 0) {

                setSelectedComments(listOfScopeAndEntitiesAdded)

            } else {

                setSelectedComments([{value: commentsToAdd, label: commentsToAdd}])

            }

            setDescriptionsToSelect(aggregateDescriptionsArray(newSubItems).map((item) => {
                return {
                    label: item.description,
                    value: item.description
                }
            }).sort((item1, item2) => (item1.description < item2.description) ? -1 : (item1.description > item2.description) ? 1 : 0))

            setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                return {
                    supplier: item.supplier
                }
            }))

            setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                return {
                    facility: item.facility
                }
            }))

            setSelectedFacilities([{value: facilityToAdd, label: facilityToAdd}])

            setSubCategoriesToSelect(aggregateSubCategoriesArray(newSubItems).map((item) => {
                return {
                    subCategory: item.subCategory
                }
            }))

            setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                return {
                    unit: item.unit
                }
            }))

            setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    emissionFactorSource: item.emissionFactorSource
                }
            }))

            setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                return {
                    comments: item.comments
                }
            }))

            setSelectedSuppliers(aggregateSuppliersArray(newSubItems).map((item) => {
                return {
                    label: item.supplier,
                    value: item.supplier
                }
            }))

            setSelectedSubCategories(aggregateSubCategoriesArray(newSubItems).map((item) => {
                return {
                    label: item.subCategory,
                    value: item.subCategory
                }
            }))

            setSelectedUnits(aggregateUnitsArray(newSubItems).map((item) => {
                return {
                    label: item.unit,
                    value: item.unit
                }
            }))

            setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    label: item.emissionFactorSource,
                    value: item.emissionFactorSource
                }
            }))

            setShowModalLoading(false)

            }

    }

    const addFootprintSubItem = async () => {

                var newSubItems = [...subItems ]

                var listOfScopeAndEntitiesAdded

                var tempCommentsToAdd

                var uniqueId = uuidv4()

                newSubItems.push({ id: uniqueId, description: descriptionToAdd, supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: Number(removeCommas(consumptionToAdd)), multiplier: multiplierToAdd, unit: unitToAdd, emissionFactor: emissionFactorToAdd, carbonEmissions: Number(removeCommas(carbonEmissionsToAdd)), comments: commentsToAdd, emissionFactorSource: emissionFactorSourceToAdd, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === commentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === commentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })

                //If it's 1.1 then we need to add a WTT 3.3 entry as well
        
                if (commentsToAdd.slice (0,4) === "1.1 ") {

                    listOfScopeAndEntitiesAdded = [{value: commentsToAdd, label: commentsToAdd}]

                    var tempEmissionFactorRecordFor3point3 = carbonEmissionFactors.find(item => item.Level1 === "WTT- fuels" && item.Level3.toLowerCase() === fuelTypeToAdd.toLowerCase() && item.Comments.includes("Gross CV") && item.Year === footprint.organisationFootprintYear)
                    var tempEmissionFactorFor3point3Source = tempEmissionFactorRecordFor3point3.id + "; " + tempEmissionFactorRecordFor3point3.DataSource + "; " + tempEmissionFactorRecordFor3point3.Scope + "; " + tempEmissionFactorRecordFor3point3.Level1 + "; " + tempEmissionFactorRecordFor3point3.Level2 + "; " + tempEmissionFactorRecordFor3point3.Level3 + "; " + tempEmissionFactorRecordFor3point3.UOMsimple + "; " + tempEmissionFactorRecordFor3point3.Year + "; " + tempEmissionFactorRecordFor3point3.Region + "; " + tempEmissionFactorRecordFor3point3.Comments + "; " + tempEmissionFactorRecordFor3point3.ColumnText
                    var tempEmissionFactorFor3point3Factor = tempEmissionFactorRecordFor3point3.GHGConversion
                    var tempEmissionsFor3point3 = Number(removeCommas(consumptionToAdd)) * multiplierToAdd * tempEmissionFactorFor3point3Factor

                    tempCommentsToAdd = footprint.organisationFootprintItems.find(item => item.itemSubCategory.slice(0,4) === "3.3 " && item.itemEntity === footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).itemEntity).itemDescription
                    listOfScopeAndEntitiesAdded.push({value: tempCommentsToAdd, label: tempCommentsToAdd})

                    uniqueId = uuidv4()

                    newSubItems.push({ id: uniqueId, description: descriptionToAdd + " WTT", supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: removeCommas(consumptionToAdd), multiplier: multiplierToAdd, unit: unitToAdd, emissionFactor: tempEmissionFactorFor3point3Factor, carbonEmissions: tempEmissionsFor3point3, comments: tempCommentsToAdd, emissionFactorSource: tempEmissionFactorFor3point3Source, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })

                }

                //If it's 2.1 then we need to add three WTT 3.3 entries as well

                if (commentsToAdd.slice (0,4) === "2.1 ") {

                    listOfScopeAndEntitiesAdded = [{value: commentsToAdd, label: commentsToAdd}]

                    var tempEmissionFactorRecordFor3point3TAndD = carbonEmissionFactors.find(item => item.Level2 === "T&D- UK electricity" && item.Year === footprint.organisationFootprintYear)
                    var tempEmissionFactorFor3point3SourceTAndD = tempEmissionFactorRecordFor3point3TAndD.id + "; " + tempEmissionFactorRecordFor3point3TAndD.DataSource + "; " + tempEmissionFactorRecordFor3point3TAndD.Scope + "; " + tempEmissionFactorRecordFor3point3TAndD.Level1 + "; " + tempEmissionFactorRecordFor3point3TAndD.Level2 + "; " + tempEmissionFactorRecordFor3point3TAndD.Level3 + "; " + tempEmissionFactorRecordFor3point3TAndD.UOMsimple + "; " + tempEmissionFactorRecordFor3point3TAndD.Year + "; " + tempEmissionFactorRecordFor3point3TAndD.Region + "; " + tempEmissionFactorRecordFor3point3TAndD.Comments + "; " + tempEmissionFactorRecordFor3point3TAndD.ColumnText
                    var tempEmissionFactorFor3point3FactorTAndD = tempEmissionFactorRecordFor3point3TAndD.GHGConversion
                    var tempEmissionsFor3point3TAndD = Number(removeCommas(consumptionToAdd)) * multiplierToAdd * tempEmissionFactorFor3point3FactorTAndD

                    tempCommentsToAdd = footprint.organisationFootprintItems.find(item => item.itemSubCategory.slice(0,4) === "3.3 " && item.itemEntity === footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).itemEntity).itemDescription
                    listOfScopeAndEntitiesAdded.push({value: tempCommentsToAdd, label: tempCommentsToAdd})

                    uniqueId = uuidv4()

                    newSubItems.push({ id: uniqueId, description: descriptionToAdd + " T&D", supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: removeCommas(consumptionToAdd), multiplier: multiplierToAdd, unit: unitToAdd, emissionFactor: tempEmissionFactorFor3point3FactorTAndD, carbonEmissions: tempEmissionsFor3point3TAndD, comments: tempCommentsToAdd, emissionFactorSource: tempEmissionFactorFor3point3SourceTAndD, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })

                    var tempEmissionFactorRecordFor3point3WTT = carbonEmissionFactors.find(item => item.Level2 === "WTT- UK electricity (generation)" && item.Year === footprint.organisationFootprintYear)
                    var tempEmissionFactorFor3point3SourceWTT = tempEmissionFactorRecordFor3point3WTT.id + "; " + tempEmissionFactorRecordFor3point3WTT.DataSource + "; " + tempEmissionFactorRecordFor3point3WTT.Scope + "; " + tempEmissionFactorRecordFor3point3WTT.Level1 + "; " + tempEmissionFactorRecordFor3point3WTT.Level2 + "; " + tempEmissionFactorRecordFor3point3WTT.Level3 + "; " + tempEmissionFactorRecordFor3point3WTT.UOMsimple + "; " + tempEmissionFactorRecordFor3point3WTT.Year + "; " + tempEmissionFactorRecordFor3point3WTT.Region + "; " + tempEmissionFactorRecordFor3point3WTT.Comments + "; " + tempEmissionFactorRecordFor3point3WTT.ColumnText
                    var tempEmissionFactorFor3point3FactorWTT = tempEmissionFactorRecordFor3point3WTT.GHGConversion
                    var tempEmissionsFor3point3WTT = Number(removeCommas(consumptionToAdd)) * multiplierToAdd * tempEmissionFactorFor3point3FactorWTT

                    tempCommentsToAdd = footprint.organisationFootprintItems.find(item => item.itemSubCategory.slice(0,4) === "3.3 " && item.itemEntity === footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).itemEntity).itemDescription
                    listOfScopeAndEntitiesAdded.push({value: tempCommentsToAdd, label: tempCommentsToAdd})

                    uniqueId = uuidv4()

                    newSubItems.push({ id: uniqueId, description: descriptionToAdd + " WTT- electricity (generation)", supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: removeCommas(consumptionToAdd), multiplier: multiplierToAdd, unit: unitToAdd, emissionFactor: tempEmissionFactorFor3point3FactorWTT, carbonEmissions: tempEmissionsFor3point3WTT, comments: tempCommentsToAdd, emissionFactorSource: tempEmissionFactorFor3point3SourceWTT, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })

                    var tempEmissionFactorRecordFor3point3WTTTAndD = carbonEmissionFactors.find(item => item.Level2 === "WTT- UK electricity (T&D)" && item.Year === footprint.organisationFootprintYear)
                    var tempEmissionFactorFor3point3SourceWTTTAndD = tempEmissionFactorRecordFor3point3WTTTAndD.id + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.DataSource + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Scope + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Level1 + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Level2 + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Level3 + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.UOMsimple + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Year + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Region + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.Comments + "; " + tempEmissionFactorRecordFor3point3WTTTAndD.ColumnText
                    var tempEmissionFactorFor3point3FactorWTTTAndD = tempEmissionFactorRecordFor3point3WTTTAndD.GHGConversion
                    var tempEmissionsFor3point3WTTTAndD = Number(removeCommas(consumptionToAdd)) * multiplierToAdd * tempEmissionFactorFor3point3FactorWTT

                    tempCommentsToAdd = footprint.organisationFootprintItems.find(item => item.itemSubCategory.slice(0,4) === "3.3 " && item.itemEntity === footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).itemEntity).itemDescription
                    listOfScopeAndEntitiesAdded.push({value: tempCommentsToAdd, label: tempCommentsToAdd})

                    uniqueId = uuidv4()

                    newSubItems.push({ id: uniqueId, description: descriptionToAdd + " WTT- UK electricity (T&D)", supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: removeCommas(consumptionToAdd), multiplier: multiplierToAdd, unit: unitToAdd, emissionFactor: tempEmissionFactorFor3point3FactorWTTTAndD, carbonEmissions: tempEmissionsFor3point3WTTTAndD, comments: tempCommentsToAdd, emissionFactorSource: tempEmissionFactorFor3point3SourceWTTTAndD, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === tempCommentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd })

                
                }

                setSubItems(newSubItems)

                window.alert("Footprint Data Added")

        
        if (listOfScopeAndEntitiesAdded.length > 0) {

            setSelectedComments(listOfScopeAndEntitiesAdded)

        } else {

            setSelectedComments([{value: commentsToAdd, label: commentsToAdd}])

        }

        setDescriptionsToSelect(aggregateDescriptionsArray(newSubItems).map((item) => {
            return {
                label: item.description,
                value: item.description
            }
        }).sort((item1, item2) => (item1.description < item2.description) ? -1 : (item1.description > item2.description) ? 1 : 0))

        setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
            return {
                supplier: item.supplier
            }
        }))

        setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
            return {
                facility: item.facility
            }
        }))

        setSelectedFacilities([{value: facilityToAdd, label: facilityToAdd}])

        setSubCategoriesToSelect(aggregateSubCategoriesArray(newSubItems).map((item) => {
            return {
                subCategory: item.subCategory
            }
        }))

        setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
            return {
                unit: item.unit
            }
        }))

        setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
            return {
                emissionFactorSource: item.emissionFactorSource
            }
        }))

        setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
            return {
                comments: item.comments
            }
        }))

        setSelectedSuppliers(aggregateSuppliersArray(newSubItems).map((item) => {
            return {
                label: item.supplier,
                value: item.supplier
            }
        }))

        setSelectedSubCategories(aggregateSubCategoriesArray(newSubItems).map((item) => {
            return {
                label: item.subCategory,
                value: item.subCategory
            }
        }))

        setSelectedUnits(aggregateUnitsArray(newSubItems).map((item) => {
            return {
                label: item.unit,
                value: item.unit
            }
        }))

        setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
            return {
                label: item.emissionFactorSource,
                value: item.emissionFactorSource
            }
        }))

    }

    const updateFootprintSubItem = () => {

            var index

            var newSelectedComments = [...selectedComments]

            var newSelectedEmissionFactorSources = [...selectedEmissionFactorSources]

            var newSelectedSuppliers = [...selectedSuppliers]

            var newSelectedFacilities = [...selectedFacilities]

            var newSelectedUnits = [...selectedUnits]

            var newSubItems = [ ...subItems ]

            index = newSubItems.indexOf(newSubItems.find(item => item.id === subItemIdToAdd))

            newSubItems[index] = { id: subItemIdToAdd, description: descriptionToAdd, supplier: supplierToAdd, facility: facilityToAdd, individual: "SELECTED", fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: Number(removeCommas(consumptionToAdd)), multiplier: multiplierToAdd, unit: unitToAdd, emissionFactor: emissionFactorToAdd, carbonEmissions: Number(removeCommas(carbonEmissionsToAdd)), comments: commentsToAdd, emissionFactorSource: emissionFactorSourceToAdd, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === commentsToAdd).itemSubCategory, footprintItemId: footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === commentsToAdd).id, multiplierFactorSource: multiplierFactorSourceToAdd }

            if (newSelectedComments.filter(item => item.label === commentsToAdd).length === 0) {
                newSelectedComments.push({label: commentsToAdd, value: commentsToAdd})
            }
            
            if (newSelectedEmissionFactorSources.filter(item => item.label === emissionFactorSourceToAdd).length === 0) {
                newSelectedEmissionFactorSources.push({label: emissionFactorSourceToAdd, value: emissionFactorSourceToAdd})
            }

            if (newSelectedSuppliers.filter(item => item.label === supplierToAdd).length === 0) {
                newSelectedSuppliers.push({label: supplierToAdd, value: supplierToAdd})
            }

            if (newSelectedFacilities.filter(item => item.label === facilityToAdd).length === 0) {
                newSelectedFacilities.push({label: facilityToAdd, value: facilityToAdd})
            }

            if (newSelectedUnits.filter(item => item.label === unitToAdd).length === 0) {
                newSelectedUnits.push({label: unitToAdd, value: unitToAdd})
            }

            setSelectedComments(newSelectedComments)
            setSelectedEmissionFactorSources(newSelectedEmissionFactorSources)
            setSelectedSuppliers(newSelectedSuppliers)
            setSelectedFacilities(newSelectedFacilities)
            setSelectedUnits(newSelectedUnits)

            setSubItems(newSubItems)

            setEditToggle(false)

            setDescriptionToAdd("")
            descriptionInput.current.value = ""
            setSupplierToAdd("")
            // supplierInput.current.value = ""
            setFacilityToAdd("")
            // facilityInput.current.value = ""
            setIndividualToAdd("")
            setFromPostCodeToAdd("")
            setToPostCodeToAdd("")
            setFormattedConsumption(0)
            setMultiplierToAdd(1)
            setUnitToAdd("")
            setSelectedTransportModeToAdd({value: "", label: ""})
            setEmissionFactorToAdd(0)
            setFormattedEmissions(0)
            setCommentsToAdd("")
            setEmissionFactorSourceToAdd("")

            setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                return {
                    supplier: item.supplier
                }
            }))
    
            setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                return {
                    facility: item.facility
                }
            }))

            setSubCategoriesToSelect(aggregateSubCategoriesArray(newSubItems).map((item) => {
                return {
                    subCategory: item.subCategory
                }
            }))
    
            setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                return {
                    unit: item.unit
                }
            }))
    
            setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    emissionFactorSource: item.emissionFactorSource
                }
            }))
    
            setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                return {
                    comments: item.comments
                }
            }))

    }

    const updateSelectedFootprintSubItems = () => {

            var newSelectedComments = [...selectedComments]

            var newSelectedEmissionFactorSources = [...selectedEmissionFactorSources]

            var newSelectedFacilities = [...selectedFacilities]

            var newSubItems = [ ...subItems ]

            var subItemIndex

            var selectedItems = newSubItems.filter(item => item.individual === "SELECTED")

            for (subItemIndex = 0; subItemIndex < selectedItems.length; subItemIndex++) {

                if (facilityToAdd !== "<CURRENT VALUE>") {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].facility = facilityToAdd

                }

                if (emissionFactorToAdd !== -99999) {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].emissionFactor = emissionFactorToAdd

                }

                if (multiplierToAdd !== -99999) {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].multiplier = multiplierToAdd

                }

                if (emissionFactorSourceToAdd !== "<CURRENT VALUE>") {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].emissionFactorSource = emissionFactorSourceToAdd

                }

                if (multiplierFactorSourceToAdd !== "<CURRENT VALUE>") {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].multiplierFactorSource = multiplierFactorSourceToAdd

                }

                if (commentsToAdd !== "<CURRENT VALUE>") {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].comments = commentsToAdd
                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].footprintItemId = footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === commentsToAdd).id
                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].subCategory = footprint.organisationFootprintItems.find(footprintItem => footprintItem.itemDescription === commentsToAdd).itemSubCategory

                }

                if (emissionFactorToAdd !== -99999) {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].carbonEmissions = emissionFactorToAdd * newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].multiplier * newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].consumption
                
                }

                if (multiplierToAdd !== -99999) {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].carbonEmissions = newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].emissionFactor * multiplierToAdd * newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].consumption
                
                }

                if (commentsToAdd === "RECYCLE BIN") {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].carbonEmissions = 0

                }

            }

            setSubItems(newSubItems)

            setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                return {
                    supplier: item.supplier
                }
            }))
    
            setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                return {
                    facility: item.facility
                }
            }))

            setSubCategoriesToSelect(aggregateSubCategoriesArray(newSubItems).map((item) => {
                return {
                    subCategory: item.subCategory
                }
            }))

            if (newSelectedFacilities.filter(item => item.label === facilityToAdd).length === 0 && facilityToAdd !== "<CURRENT VALUE>") {
                newSelectedFacilities.push({label: facilityToAdd, value: facilityToAdd})
            }

            setSelectedFacilities(newSelectedFacilities)

            setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                return {
                    unit: item.unit
                }
            }))
    
            setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    emissionFactorSource: item.emissionFactorSource
                }
            }))

            if (newSelectedEmissionFactorSources.filter(item => item.label === emissionFactorSourceToAdd).length === 0 && emissionFactorSourceToAdd !== "<CURRENT VALUE>") {
                newSelectedEmissionFactorSources.push({label: emissionFactorSourceToAdd, value: emissionFactorSourceToAdd})
            }

            setSelectedEmissionFactorSources(newSelectedEmissionFactorSources)
    
            setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                return {
                    comments: item.comments
                }
            }))

            if (newSelectedComments.filter(item => item.label === commentsToAdd).length === 0 && commentsToAdd !== "<CURRENT VALUE>") {
                newSelectedComments.push({label: commentsToAdd, value: commentsToAdd})
            }

            setSelectedComments(newSelectedComments)

            setEditSelectedToggle(false)

            setDescriptionToAdd("")
            descriptionInput.current.value = ""
            setSupplierToAdd("")
            supplierInput.current.value = ""
            setFacilityToAdd("")
            facilityInput.current.value = ""
            setIndividualToAdd("")
            setFromPostCodeToAdd("")
            setToPostCodeToAdd("")
            setFormattedConsumption(0)
            setMultiplierToAdd(1)
            setUnitToAdd("")
            setSelectedTransportModeToAdd({value: "", label: ""})
            setEmissionFactorToAdd(0)
            setFormattedEmissions(0)
            setCommentsToAdd("")
            setEmissionFactorSourceToAdd("")
            setMultiplierFactorSourceToAdd("")

        // }

    }

    const cloneFootprintSubItem = async (organisationFootprintSubItem) => {

        //Code here to clone the footprintSubItem

        var newSubItems = [ ...subItems ]

        newSubItems.push({...organisationFootprintSubItem, footprintSubItemId: uuidv4(), id: uuidv4()})

        setSubItems(newSubItems)
    
      }

    const deleteFootprintSubItem = (id) => {

        if (window.confirm('Are you sure you want to delete this sub item?')) {


                var newSubItems = [ ...subItems ]

                if (id.length === 24) {
                    setSubItemsToDelete([ ...subItemsToDelete, id ])
                }

                setSubItems(newSubItems.filter((item) => item.id !== id))

                setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                    return {
                        supplier: item.supplier
                    }
                }))
        
                setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                    return {
                        facility: item.facility
                    }
                }))

                setSubCategoriesToSelect(aggregateSubCategoriesArray(newSubItems).map((item) => {
                    return {
                        subCategory: item.subCategory
                    }
                }))
        
                setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                    return {
                        unit: item.unit
                    }
                }))
        
                setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                    return {
                        emissionFactorSource: item.emissionFactorSource
                    }
                }))
        
                setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                    return {
                        comments: item.comments
                    }
                }))

            }

        // }

    }

    const deleteSelectedFootprintSubItems = () => {

        if (window.confirm('Are you sure you want to delete these sub items?')) {

                var subItemIndex
                var newSubItems = [ ...subItems ]
                var selectedSubItemsToDelete = []

                for (subItemIndex = 0; subItemIndex < newSubItems.filter((item) => item.id.length === 24 && item.individual === "SELECTED").length; subItemIndex++) {

                    selectedSubItemsToDelete.push(newSubItems.filter((item) => item.id.length === 24 && item.individual === "SELECTED")[subItemIndex].id)

                }

                setSubItemsToDelete(subItemsToDelete.concat(selectedSubItemsToDelete))
                console.log(subItemsToDelete.concat(selectedSubItemsToDelete))

                newSubItems = newSubItems.filter(item => item.individual !== "SELECTED")

                setSubItems(newSubItems)

                setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                    return {
                        supplier: item.supplier
                    }
                }))
        
                setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                    return {
                        facility: item.facility
                    }
                }))

                setSubCategoriesToSelect(aggregateSubCategoriesArray(newSubItems).map((item) => {
                    return {
                        subCategory: item.subCategory
                    }
                }))
        
                setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                    return {
                        unit: item.unit
                    }
                }))
        
                setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                    return {
                        emissionFactorSource: item.emissionFactorSource
                    }
                }))
        
                setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                    return {
                        comments: item.comments
                    }
                }))

            // }

        }

    }

    const switchEmissionFactors = async () => {

        var subItemIndex
        var newSubItems = [ ...subItems ]

        var dataSource, level1, region, year
        var level1Alternative
        var alternativeFactor

        if (selectedDatabases.length === 1) {

            setAnimationSwitch(true)

            await axios.get(process.env.REACT_APP_API_URL + '/carbon_factors_database_mapping')
            
            for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {

                if (carbonEmissionFactors.find(item => item.id === newSubItems[subItemIndex].emissionFactorSource.slice(0,24))) {
                    //check the subItem[i].factorSource id (first 24 chars) and get the Level1 description, year and region
                    dataSource = carbonEmissionFactors.find(item => item.id === newSubItems[subItemIndex].emissionFactorSource.slice(0,24)).DataSource
                    year = selectedDatabases[0].label.slice(-4)
                    level1 = carbonEmissionFactors.find(item => item.id === newSubItems[subItemIndex].emissionFactorSource.slice(0,24)).Level1
                    region = carbonEmissionFactors.find(item => item.id === newSubItems[subItemIndex].emissionFactorSource.slice(0,24)).Region

                    //check if it's already set to a factor from the selected factor database
                    if (dataSource !== selectedDatabases[0].label.slice(0,selectedDatabases[0].label.length-5)) {

                        //Get the corresponding databaseB factor data
                        if (databaseMapping.find(item => item.descriptionA === level1 && item.databaseA === dataSource)) {
                            // console.log("changing TO DB B")
                            level1Alternative = databaseMapping.find(item => item.descriptionA === level1 && item.databaseA === dataSource && item.databaseB === selectedDatabases[0].label.slice(0,selectedDatabases[0].label.length-5)).descriptionB
                            alternativeFactor = carbonEmissionFactors.find(item => item.Level1 === level1Alternative && item.Year === year && item.DataSource === selectedDatabases[0].label.slice(0,selectedDatabases[0].label.length-5))
                            newSubItems[subItemIndex].emissionFactor = (alternativeFactor.GHGConversion / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(year), 0, 1, 0)).toFixed(5) * -1
                            newSubItems[subItemIndex].carbonEmissions = ((alternativeFactor.GHGConversion / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(year), 0, 1, 0)).toFixed(5)) * -1 * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption
                            newSubItems[subItemIndex].emissionFactorSource = alternativeFactor.id + "; " + alternativeFactor.DataSource + "; " + alternativeFactor.Scope + "; " + alternativeFactor.Level1 + "; " + alternativeFactor.Level2 + "; " + alternativeFactor.Level3 + "; " + alternativeFactor.UOMsimple + "; " + alternativeFactor.Year + "; " + alternativeFactor.Region + "; " + alternativeFactor.Comments + "; " + alternativeFactor.ColumnText
                        
                        }

                        if (databaseMapping.find(item => item.descriptionB === level1 && item.databaseB === dataSource)) {
                            // console.log("changing TO DB A")
                            level1Alternative = databaseMapping.find(item => item.descriptionB === level1 && item.databaseB === dataSource && item.databaseA === selectedDatabases[0].label.slice(0,selectedDatabases[0].label.length-5)).descriptionA
                            alternativeFactor = carbonEmissionFactors.find(item => item.Level1 === level1Alternative && item.DataSource === selectedDatabases[0].label.slice(0,selectedDatabases[0].label.length-5))
                            newSubItems[subItemIndex].emissionFactor = (alternativeFactor.GHGConversion / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(year), 0, 1, 0)).toFixed(5) * -1
                            newSubItems[subItemIndex].carbonEmissions = ((alternativeFactor.GHGConversion / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(year), 0, 1, 0)).toFixed(5)) * -1 * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption
                            newSubItems[subItemIndex].emissionFactorSource = alternativeFactor.id + "; " + alternativeFactor.DataSource + "; " + alternativeFactor.Scope + "; " + alternativeFactor.Level1 + "; " + alternativeFactor.Level2 + "; " + alternativeFactor.Level3 + "; " + alternativeFactor.UOMsimple + "; " + alternativeFactor.Year + "; " + alternativeFactor.Region + "; " + alternativeFactor.Comments + "; " + alternativeFactor.ColumnText
                        
                        }
                    }
                
                }

            }

            setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    label: item.emissionFactorSource,
                    value: item.emissionFactorSource
                }
            }))

            setSubItems(newSubItems)
        
        } else if (selectedDatabases.length === 0) {

            window.alert("Please select a carbon factor database")

        } else if (selectedDatabases.length > 1) {

            window.alert("Please select only one carbon factor database")

        }

        setAnimationSwitch(false)
    }

    const automaticallySelectEmissionFactors = async () => {

        if (selectedDatabases.length === 1) {

        //create a full array of keyWordsAndPhrases matched to emissions factor
        
        var factorIndex, subItemIndex, keyWordOrPhraseIndex
        var allKeyWordsAndPhrasesWithMatchingFactor = []
        var previousYearsSubItems = []
        var newSubItems = [ ...subItems ]
        var subItem
        var subItemList
        var unit
        var adjustedEmissionFactorSourceText
        var positionOfInflationText
        var originalEmissionFactorFromDB
        var numberOfYears
        var dataSourceToUse, level1ToUse, level2ToUse, level3ToUse, scopeToUse, columnTextToUse, UOMSimpleToUse, regionToUse, idToUse, commentsToUse
        var yearToUse
        var carbonEmissionFactorsFilteredToSelected = carbonEmissionFactors.filter(item => item.DataSource === selectedDatabases[0].value.slice(0,selectedDatabases[0].value.length-5) && item.Year === selectedDatabases[0].value.slice(-4))

        if (window.confirm('Would you like to check data from prior year?')) {
            await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems_dashboard', {"organisations": [match.params.organisationId]})
            .then((res) => {

                if (res.data.filter(item => item.year === (Number(footprint.organisationFootprintYear) - 1).toString())) {

                    setPreviousYearsData(res.data.filter(item => item.year === (Number(footprint.organisationFootprintYear) - 1).toString()))
            
                }
            
            })
            .catch((err) => {
                console.log(err);
            })
        }

        // if there is a previous year create an array of previous year subitems
        if (previousYearsData.length > 0) {
            previousYearsSubItems = previousYearsData
        }

        // Key words and phrases from Rob
        // go though factor database and make a list of all keywords or phrases along with UOM, emission factor, year and db source
        for (factorIndex = 0; factorIndex < carbonEmissionFactorsFilteredToSelected.length; factorIndex++) {
            for (keyWordOrPhraseIndex = 0; keyWordOrPhraseIndex < carbonEmissionFactorsFilteredToSelected[factorIndex].keyWordsAndPhrases.length; keyWordOrPhraseIndex++) {
                allKeyWordsAndPhrasesWithMatchingFactor.push({keyWordOrPhrase: carbonEmissionFactorsFilteredToSelected[factorIndex].keyWordsAndPhrases[keyWordOrPhraseIndex], unit: carbonEmissionFactorsFilteredToSelected[factorIndex].UOMsimple, emissionFactor: carbonEmissionFactorsFilteredToSelected[factorIndex].GHGConversion,  emissionFactorSource: carbonEmissionFactorsFilteredToSelected[factorIndex].id + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].DataSource + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Scope + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Level1 + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Level2 + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Level3 + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].UOMsimple + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Year + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Region + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Comments + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].ColumnText, year: carbonEmissionFactorsFilteredToSelected[factorIndex].Year })
            }
        }

        // go through every single item in subitems list, for example: each item in 3.1. Purchased goods and services
        for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {

            // Code here to check for key words / phrases match along with its factor 
            for (keyWordOrPhraseIndex = 0; keyWordOrPhraseIndex < allKeyWordsAndPhrasesWithMatchingFactor.length; keyWordOrPhraseIndex++) {

                unit = newSubItems[subItemIndex].unit

                if (newSubItems[subItemIndex].description.toLowerCase().includes(allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].keyWordOrPhrase.toLowerCase()) && newSubItems[subItemIndex].unit === allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].unit) {
                    // // Get emission factor related to that keyword and phrase and pair it with subitem
                    // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].emissionFactor = ((allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].year), 0, 1, 0)).toFixed(5))*-1
                    // // multiply the factor by consumption and get total emission per subitem
                    // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].carbonEmissions = (((allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].year), 0, 1, 0)).toFixed(5))*-1) * newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].multiplier * newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].consumption 
                    // // Update the data source, add #MAP - means that this carbon factor comes from keyword and phrases mapping
                    if (unit === "GBP" || unit === "EUR" || unit === "USD") {
                        newSubItems[subItemIndex].emissionFactor = ((allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].year), 0, 1, 0)).toFixed(5))*-1
                        newSubItems[subItemIndex].carbonEmissions = (((allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].year), 0, 1, 0)).toFixed(5))*-1) * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption 
                        newSubItems[subItemIndex].emissionFactorSource = allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactorSource + "; Inflation Adjusted (at rate: " + inflationFactor * 100 + "%); #MAP"
                    } else {
                        newSubItems[subItemIndex].emissionFactor = allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor
                        newSubItems[subItemIndex].carbonEmissions = allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption 
                        newSubItems[subItemIndex].emissionFactorSource = allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactorSource + "; #MAP"
                    }
                }
            }

            //Code here to check previous years' carbon factors where there is a subItem description match
            //#PRE means factor came from matching description from previous year subItem

            if (previousYearsSubItems.length > 0) {
                // for (subItemList = 0; subItemList < previousYearsSubItems.length; subItemList++) {
                    for (subItem = 0; subItem < previousYearsSubItems.length; subItem++) {
                        // console.log(newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].description.replace(/,/g, ""))
                        if ((newSubItems[subItemIndex].description.toLowerCase().includes(previousYearsSubItems[subItem].description.toLowerCase()) || newSubItems[subItemIndex].description.replace(/,/g, "").toLowerCase().includes(previousYearsSubItems[subItem].description.toLowerCase())) && newSubItems[subItemIndex].unit === previousYearsSubItems[subItem].unit) {
                            if (unit === "GBP" || unit === "EUR" || unit === "USD") {

                                //code here to check previous emissionFactorSource, reselect it from the database and apply the new inflation figure
                                
                                if (carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24))) {

                                    if (selectedDatabases.find(item => item.label === carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).DataSource + " " + carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Year)) {

                                        //IF USER HAS SELECTED THE SAME DATABASE THAT THE PREVIOUSLY USED FACTOR WAS IN THEN
                                        originalEmissionFactorFromDB = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).GHGConversion
                                        numberOfYears = footprint.organisationFootprintYear - carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Year
                                        adjustedEmissionFactorSourceText = previousYearsSubItems[subItem].emissionFactorSource

                                    } else {

                                        //IS THE SAME DATASOURCE (BUT DIFFERENT YEAR) SELECTED?  IF SO USE THAT
                                        if (selectedDatabases.find(item => item.label.slice(0,item.label.length-5) === carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).DataSource)) {
                                        
                                            dataSourceToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).DataSource
                                            yearToUse = selectedDatabases.find(item => item.label.slice(0,item.label.length-5) === carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).DataSource).label.slice(-4)
                                            level1ToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Level1
                                            level2ToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Level2
                                            level3ToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Level3
                                            scopeToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Scope
                                            columnTextToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).ColumnText
                                            UOMSimpleToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).UOMsimple
                                            regionToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Region
                                            idToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).id
                                            commentsToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Comments


                                            //NEED TO UPDATE THIS TO FILTER TO ALL FIELDS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                            if (carbonEmissionFactors.find(item => item.DataSource === dataSourceToUse && item.Year === yearToUse && item.Level1 === level1ToUse && item.Level2 === level2ToUse && item.Level3 === level3ToUse && item.Scope === scopeToUse && item.UOMsimple === UOMSimpleToUse && item.Region === regionToUse && item.Comments === commentsToUse && item.ColumnText === columnTextToUse)) {
                                                originalEmissionFactorFromDB = carbonEmissionFactors.find(item => item.DataSource === dataSourceToUse && item.Year === yearToUse && item.Level1 === level1ToUse && item.Level2 === level2ToUse && item.Level3 === level3ToUse && item.Scope === scopeToUse && item.UOMsimple === UOMSimpleToUse && item.Region === regionToUse && item.Comments === commentsToUse && item.ColumnText === columnTextToUse).GHGConversion
                                                numberOfYears = footprint.organisationFootprintYear - yearToUse
                                                idToUse = carbonEmissionFactors.find(item => item.DataSource === dataSourceToUse && item.Year === yearToUse && item.Level1 === level1ToUse && item.Level2 === level2ToUse && item.Level3 === level3ToUse && item.Scope === scopeToUse && item.UOMsimple === UOMSimpleToUse && item.Region === regionToUse && item.Comments === commentsToUse && item.ColumnText === columnTextToUse).id
                                                
                                                adjustedEmissionFactorSourceText = idToUse + "; " + dataSourceToUse + "; " + scopeToUse + "; " + level1ToUse + "; " + level2ToUse + "; " + level3ToUse + "; " + UOMSimpleToUse + "; " + yearToUse + "; " + regionToUse + "; " + commentsToUse + "; " + columnTextToUse
                                            } else {

                                                originalEmissionFactorFromDB = 0
                                                numberOfYears = 0
                                                adjustedEmissionFactorSourceText = previousYearsSubItems[subItem].emissionFactorSource
                                            }
                            
                                        } else {

                                            originalEmissionFactorFromDB = 0
                                            numberOfYears = 0
                                            adjustedEmissionFactorSourceText = previousYearsSubItems[subItem].emissionFactorSource
                                        }

                                    }

                                } else {

                                    originalEmissionFactorFromDB = 0
                                    numberOfYears = 0
                                    adjustedEmissionFactorSourceText = previousYearsSubItems[subItem].emissionFactorSource

                                }

                                //Also need to replace any mention of "Inflation Adjusted (at rate: x.xxx%) ;" with " ;"
                                positionOfInflationText = adjustedEmissionFactorSourceText.indexOf("Inflation Adjusted (at rate:")

                                if (positionOfInflationText !== -1) {
                                    adjustedEmissionFactorSourceText = adjustedEmissionFactorSourceText.slice(0, positionOfInflationText -2)
                                } else {
                                    adjustedEmissionFactorSourceText = adjustedEmissionFactorSourceText.slice(0, positionOfInflationText)
                                }

                                if (originalEmissionFactorFromDB !==0) {
                                    newSubItems[subItemIndex].emissionFactor = originalEmissionFactorFromDB / FV(inflationFactor, numberOfYears, 0, 1, 0).toFixed(5)*-1
                                    newSubItems[subItemIndex].carbonEmissions = (originalEmissionFactorFromDB / FV(inflationFactor, numberOfYears, 0, 1, 0).toFixed(5)*-1) * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption 
                                    newSubItems[subItemIndex].emissionFactorSource = adjustedEmissionFactorSourceText + "; Inflation Adjusted (at rate: " + inflationFactor * 100 + "%); #PRE"      
                                }
                            } else {
                                newSubItems[subItemIndex].emissionFactor = previousYearsSubItems[subItem].emissionFactor
                                newSubItems[subItemIndex].carbonEmissions = previousYearsSubItems[subItem].emissionFactor * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption 
                                newSubItems[subItemIndex].emissionFactorSource = previousYearsSubItems[subItem].emissionFactorSource + "; #PRE"
                            }
                        }
                    }

            }

            if ((newSubItems[subItemIndex].emissionFactor === "0" || newSubItems[subItemIndex].emissionFactor === 0)) {
                
            
                if ((newSubItems[subItemIndex].unit === "GBP" || newSubItems[subItemIndex].unit === "EUR" || newSubItems[subItemIndex].unit === "USD") && newSubItems[subItemIndex].consumption <= 100 && newSubItems[subItemIndex].consumption > 0 && footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemSubCategory.substring(0,3)==="3.1") {

                    dataSourceToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").DataSource
                    yearToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").Year
                    level1ToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").Level1
                    level2ToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").Level2
                    level3ToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").Level3
                    scopeToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").Scope
                    columnTextToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").ColumnText
                    UOMSimpleToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").UOMsimple
                    regionToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").Region
                    idToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").id
                    commentsToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").Comments

                    numberOfYears = Number(footprint.organisationFootprintYear) - Number(yearToUse)


                    adjustedEmissionFactorSourceText = idToUse + "; " + dataSourceToUse + "; " + scopeToUse + "; " + level1ToUse + "; " + level2ToUse + "; " + level3ToUse + "; " + UOMSimpleToUse + "; " + yearToUse + "; " + regionToUse + "; " + commentsToUse + "; " + columnTextToUse

                    newSubItems[subItemIndex].emissionFactor = (carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").GHGConversion / FV(inflationFactor, numberOfYears, 0, 1, 0)).toFixed(5)*-1
                    newSubItems[subItemIndex].carbonEmissions = ((carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").GHGConversion / FV(inflationFactor, numberOfYears, 0, 1, 0).toFixed(5))*-1) * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption 
                    newSubItems[subItemIndex].emissionFactorSource = adjustedEmissionFactorSourceText + "; Inflation Adjusted (at rate: " + inflationFactor * 100 + "%); #MAP"

                } else {

                    newSubItems[subItemIndex].comments = ""
                }
            
            }

            //Code here to carry out factor selection using AI
            //
            // if((newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].emissionFactorSource.slice(-4))!==("#MAP"||"#PRE"))
                // if last 4 chars are not #MAP or #PRE
                // var desc = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].description
                // api call and response await
                
                // assing emissionFactor

        }

        setSelectedComments(aggregateCommentsArray(newSubItems).map((item) => {
            return {
                label: item.comments,
                value: item.comments
            }
        }))

        setSelectedSuppliers(aggregateSuppliersArray(newSubItems).map((item) => {
            return {
                label: item.supplier,
                value: item.supplier
            }
        }))

        setSelectedFacilities(aggregateFacilitiesArray(newSubItems).map((item) => {
            return {
                label: item.facility,
                value: item.facility
            }
        }))

        setSelectedSubCategories(aggregateSubCategoriesArray(newSubItems).map((item) => {
            return {
                label: item.subCategory,
                value: item.subCategory
            }
        }))

        setSelectedUnits(aggregateUnitsArray(newSubItems).map((item) => {
            return {
                label: item.unit,
                value: item.unit
            }
        }))

        setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
            return {
                label: item.emissionFactorSource,
                value: item.emissionFactorSource
            }
        }))

        setSubItems(newSubItems)

    } else if (selectedDatabases.length === 0) {

        window.alert("Please select a carbon factor database")

    } else if (selectedDatabases.length > 1) {

        window.alert("Please select only one carbon factor database")

    }

    }

    ///////////////////////////////////////////////////////



    ///////////////////////////////////////////////////////

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const markSubItemAsSelected = (id) => {

        var index

        var newSubItems = [ ...subItems ]

        index = newSubItems.indexOf(newSubItems.find(item => item.id === id))

        if (newSubItems[index].individual !== "SELECTED") {
            newSubItems[index] = { ...newSubItems[index], individual: "SELECTED" }
        } else {
            newSubItems[index] = { ...newSubItems[index], individual: "" }
        }
        setSubItems(newSubItems)

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Xero API Link Code Here

    const [authCode, setAuthCode] = useState('')
    const XeroUserExists = () => {

        //console.log("XeroUserExists")
        axios.get(process.env.REACT_APP_API_URL + '/XeroUserExists')
            .then((res) => {
                if (res.data==="Yes"){ //User exists in tokens collection
                    setShowModalUpdate(true)
                    setValueToSendToModal(match.params.footprintId)
                    setFieldToSendToModal("footprintItemId")
                    //NEED TO UPDATE THIS TO WORK FOR ALL FOOTPRINT ITEMS????
                    setObjectIdToSendToModal("match.params.footprintItemId")
                    setCollectionToSendToModal(footprint)
                } else { //User doesn't exist in the tokens collection
                    XeroFirstAuth()
                }

            })
            .catch((err) => {
                console.log(err);
            })

        }

    const XeroFirstAuth = async () => {

        const auth_url = await axios.get(process.env.REACT_APP_API_URL + '/XeroFirstAuth')
        window.alert('Please login to your Xero account on the next screen and click on "Allow Access"')
        let params = `popup=true,left=100,top=100,width=500,height=700`;
        window.open (auth_url.data,"XeroWindow",params);

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setFirstRecord(0)
            setLastRecord(100)

        setSuppliersToSelect(aggregateSuppliersArray(subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSubCategories.find((itemB) => {
                return itemA.subCategory === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase()))
        ).map((item) => {
            return {
                supplier: item.supplier
            }
        }))

        setFacilitiesToSelect(aggregateFacilitiesArray(subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSubCategories.find((itemB) => {
                return itemA.subCategory === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase()))
        ).map((item) => {
            return {
                facility: item.facility
            }
        }))

        setUnitsToSelect(aggregateUnitsArray(subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSubCategories.find((itemB) => {
                return itemA.subCategory === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase()))
        ).map((item) => {
            return {
                unit: item.unit
            }
        }))

        setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSubCategories.find((itemB) => {
                return itemA.subCategory === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase()))
        ).map((item) => {
            return {
                emissionFactorSource: item.emissionFactorSource
            }
        }))

        setCommentsToSelect(aggregateCommentsArray(subItems.filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSubCategories.find((itemB) => {
                return itemA.subCategory === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase()))
        ).map((item) => {
            return {
                comments: item.comments
            }
        }))

        setSubCategoriesToSelect(aggregateSubCategoriesArray(subItems.filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase()))
        ).map((item) => {
            return {
                subCategory: item.subCategory
            }
        }))

    }

    }, [selectedSubCategories, selectedComments, selectedEmissionFactorSources, selectedSuppliers, selectedFacilities, selectedUnits, company, role, userPropertiesLoading, searchText])


    useEffect(() => {

        var tempSubItemsFiltered = subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSubCategories.find((itemB) => {
                return itemA.subCategory === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase()))

        setSubItemsFiltered(tempSubItemsFiltered)

        setSubItemsFilteredNumberOfItems(tempSubItemsFiltered.length)

        setSubItemsFilteredTotaltCO2e((tempSubItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000).toFixed(2))

    }, [selectedComments, selectedDescriptions, selectedEmissionFactorSources, selectedSuppliers, selectedFacilities, selectedUnits, selectedSubCategories, searchText, subItems])



    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            axios.get(process.env.REACT_APP_API_URL + '/organisations/' + match.params.organisationId)
                .then((res) => {
                    if ((role === 3001 || role === 6001) && res.data.clientId !== company) {
                        navigate(`/errorPage`)
                    }

                    setOrganisation(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setOrganisationsLoading(false);
                });

                axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems_dashboard', {"organisations": [match.params.organisationId]})
                .then((res) => {
                    // console.log(res.data.filter(item => item.footprintItemId === match.params.footprintItemId))
                    setSubItems(res.data.filter(item => item.footprintId === match.params.footprintId))
                    // setSubItemsFiltered(res.data.filter(item => item.footprintId === match.params.footprintId))

                    setSubItemsFilteredNumberOfItems(res.data.filter(item => item.footprintId === match.params.footprintId).length)

                    setSubItemsFilteredTotaltCO2e((res.data.filter(item => item.footprintId === match.params.footprintId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000).toFixed(2))

                    setSelectedComments(aggregateCommentsArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            label: item.comments,
                            value: item.comments
                        }
                    }))

                    setSuppliersToSelect(aggregateSuppliersArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            supplier: item.supplier
                        }
                    }))
            
                    setFacilitiesToSelect(aggregateFacilitiesArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            facility: item.facility
                        }
                    }))

                    setSubCategoriesToSelect(aggregateSubCategoriesArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            subCategory: item.subCategory
                        }
                    }))
            
                    setUnitsToSelect(aggregateUnitsArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            unit: item.unit
                        }
                    }))
            
                    setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            emissionFactorSource: item.emissionFactorSource
                        }
                    }))
            
                    setCommentsToSelect(aggregateCommentsArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            comments: item.comments
                        }
                    }))

                    setSelectedSuppliers(aggregateSuppliersArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            label: item.supplier,
                            value: item.supplier
                        }
                    }))
            
                    setSelectedFacilities(aggregateFacilitiesArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            label: item.facility,
                            value: item.facility
                        }
                    }))

                    setSelectedSubCategories(aggregateSubCategoriesArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            label: item.subCategory,
                            value: item.subCategory
                        }
                    }))

                    setSelectedUnits(aggregateUnitsArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            label: item.unit,
                            value: item.unit
                        }
                    }))
            
                    setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(res.data.filter(item => item.footprintId === match.params.footprintId)).map((item) => {
                        return {
                            label: item.emissionFactorSource,
                            value: item.emissionFactorSource
                        }
                    }))

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setSubItemsLoading(false);
                });

            axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
                .then((res) => {
                    if (res.data.organisationId !== match.params.organisationId) {
                        navigate(`/errorPage`)
                    }

                    setFootprint(res.data)

                    setCommentsToAdd("")

                    setFromDateToAdd(res.data.organisationFootprintYear + "-01-01")
                    setToDateToAdd(res.data.organisationFootprintYear + "-12-31")

                    // axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems_dashboard', {"organisations": [match.params.organisationId]})
                    // .then((res2) => {

                    //     if (res2.data.filter(item => item.year === (Number(res.data.organisationFootprintYear) - 1).toString())) {

                    //         setPreviousYearsData(res2.data.filter(item => item.year === (Number(res.data.organisationFootprintYear) - 1).toString()))
                    
                    //     }
                    
                    // })
                    // .catch((err) => {
                    //     console.log(err);
                    // })
    
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

                if (role !== 3001 && role !== 6001 && role !== 6002) {

                    axios.get(process.env.REACT_APP_API_URL + '/carbon_factors')
                        .then((res) => {
                            setCarbonEmissionFactors(res.data)
                            setTransportModeOptions(res.data.filter(item => item.Level1.slice(0, 15) === "Business travel"))
                        })
                        .catch((err) => {
                            console.log(err);
                        }).finally(() => {
                            setCarbonFactorsLoading(false);
                        });

                    axios.get(process.env.REACT_APP_API_URL + '/carbon_factors_database_mapping')
                        .then((res) => {
                            setDatabaseMapping(res.data)
                        })
                        .catch((err) => {
                            console.log(err);
                        }).finally(() => {
                            setDatabaseMappingLoading(false);
                        });

                } else {

                    setCarbonFactorsLoading(false)
                    setDatabaseMappingLoading(false);
                    setCarbonEmissionFactors(["EMPTY"])
                }

        }

        setReloadDataToggle(false)

    }, [match.params.footprintId, company, role, userPropertiesLoading, match.params.organisationId, reloadDataToggle])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            if (selectedTransportModeToAdd.value && carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === selectedTransportModeToAdd.value)) {

                setEmissionFactorToAdd(carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === selectedTransportModeToAdd.value).GHGConversion)
                setFormattedEmissions(carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === selectedTransportModeToAdd.value).GHGConversion * removeCommas(consumptionToAdd) * multiplierToAdd)
            } else {

                setFormattedEmissions(emissionFactorToAdd * removeCommas(consumptionToAdd) * multiplierToAdd)

            }

        }

    }, [selectedTransportModeToAdd, company, role, userPropertiesLoading, carbonEmissionFactors, consumptionToAdd, multiplierToAdd, emissionFactorToAdd])
    
    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            var emissionFactorRecord
            var multiplierRecord

            //1.1

            if (fuelTypeToAdd && unitToAdd && calculationMethodToAdd === "Actual" && commentsToAdd.slice(0,4) === "1.1 " && carbonEmissionFactors.find(item => item.Level3 === fuelTypeToAdd && item.UOMsimple === unitToAdd)) {
                emissionFactorRecord = carbonEmissionFactors.find(item => item.Level3 === fuelTypeToAdd && item.UOMsimple === unitToAdd)
                setEmissionFactorToAdd(emissionFactorRecord.GHGConversion)
                setEmissionFactorSourceToAdd(emissionFactorRecord.id + "; " + emissionFactorRecord.DataSource + "; " + emissionFactorRecord.Scope + "; " + emissionFactorRecord.Level1 + "; " + emissionFactorRecord.Level2 + "; " + emissionFactorRecord.Level3 + "; " + emissionFactorRecord.UOMsimple + "; " + emissionFactorRecord.Year + "; " + emissionFactorRecord.Region + "; " + emissionFactorRecord.Comments + "; " + emissionFactorRecord.ColumnText)
            }

            if (fuelTypeToAdd && calculationMethodToAdd === "Use Benchmark" && organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd) && commentsToAdd.slice(0,4) === "1.1 ") {
                multiplierRecord = carbonEmissionFactors.find(item => item.Level1 === organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).buildingTypeL1 && item.Level2 === organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).buildingTypeL2 && item.Level3 === "Typical practice fossil fuels" && item.UOMsimple === "kWh/m2 (Treated floor area)")
                setMultiplierToAdd(multiplierRecord.GHGConversion)
                setMultiplierFactorSourceToAdd(multiplierRecord.id + "; " + multiplierRecord.DataSource + "; " + multiplierRecord.Scope + "; " + multiplierRecord.Level1 + "; " + multiplierRecord.Level2 + "; " + multiplierRecord.Level3 + "; " + multiplierRecord.UOMsimple + "; " + multiplierRecord.Year + "; " + multiplierRecord.Region + "; " + multiplierRecord.Comments + "; " + multiplierRecord.ColumnText)
                if (organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).floorAreaUnit === "square feet") {
                    setConsumptionToAdd(organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).floorArea * 0.092903)
                } else {
                    setConsumptionToAdd(organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).floorArea)
                }
                setUnitToAdd("square metres")
                emissionFactorRecord = carbonEmissionFactors.find(item => item.Level3 === fuelTypeToAdd && item.UOMsimple === "kWh" && item.Year === footprint.organisationFootprintYear)
                setEmissionFactorToAdd(emissionFactorRecord.GHGConversion)
                setEmissionFactorSourceToAdd(emissionFactorRecord.id + "; " + emissionFactorRecord.DataSource + "; " + emissionFactorRecord.Scope + "; " + emissionFactorRecord.Level1 + "; " + emissionFactorRecord.Level2 + "; " + emissionFactorRecord.Level3 + "; " + emissionFactorRecord.UOMsimple + "; " + emissionFactorRecord.Year + "; " + emissionFactorRecord.Region + "; " + emissionFactorRecord.Comments + "; " + emissionFactorRecord.ColumnText)
            }


            //1.3

            if (carbonEmissionFactors.find(item => item.Level3 === "R410A" && item.UOMsimple === unitToAdd && item.Year === footprint.organisationFootprintYear) && calculationMethodToAdd === "Actual" && commentsToAdd.slice(0,4) === "1.3 ") {
                emissionFactorRecord = carbonEmissionFactors.find(item => item.Level3 === "R410A" && item.UOMsimple === unitToAdd && item.Year === footprint.organisationFootprintYear)
                setEmissionFactorToAdd(emissionFactorRecord.GHGConversion)
                setEmissionFactorSourceToAdd(emissionFactorRecord.id + "; " + emissionFactorRecord.DataSource + "; " + emissionFactorRecord.Scope + "; " + emissionFactorRecord.Level1 + "; " + emissionFactorRecord.Level2 + "; " + emissionFactorRecord.Level3 + "; " + emissionFactorRecord.UOMsimple + "; " + emissionFactorRecord.Year + "; " + emissionFactorRecord.Region + "; " + emissionFactorRecord.Comments + "; " + emissionFactorRecord.ColumnText)
            }

            if (calculationMethodToAdd === "Use Benchmark" && organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd) && commentsToAdd.slice(0,4) === "1.3 ") {
                setMultiplierToAdd(0.07)
                setMultiplierFactorSourceToAdd("Refrigerants Benchmark Sheet")
                setConsumptionToAdd(organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).floorArea)
                setUnitToAdd("square metres")
                emissionFactorRecord = carbonEmissionFactors.find(item => item.Level3 === "R410A" && item.UOMsimple === "kg" && item.Year === footprint.organisationFootprintYear)
                setEmissionFactorToAdd(emissionFactorRecord.GHGConversion)
                setEmissionFactorSourceToAdd(emissionFactorRecord.id + "; " + emissionFactorRecord.DataSource + "; " + emissionFactorRecord.Scope + "; " + emissionFactorRecord.Level1 + "; " + emissionFactorRecord.Level2 + "; " + emissionFactorRecord.Level3 + "; " + emissionFactorRecord.UOMsimple + "; " + emissionFactorRecord.Year + "; " + emissionFactorRecord.Region + "; " + emissionFactorRecord.Comments + "; " + emissionFactorRecord.ColumnText)
            }


            //2.1

            if (carbonEmissionFactors.find(item => item.Level1 === "UK electricity" && item.Level3 === "Electricity: UK" && item.UOMsimple === unitToAdd && item.Year === footprint.organisationFootprintYear) && calculationMethodToAdd === "Actual" && commentsToAdd.slice(0,4) === "2.1 ") {
                emissionFactorRecord = carbonEmissionFactors.find(item => item.Level1 === "UK electricity" && item.Level3 === "Electricity: UK" && item.UOMsimple === unitToAdd && item.Year === footprint.organisationFootprintYear)
                setEmissionFactorToAdd(emissionFactorRecord.GHGConversion)
                setEmissionFactorSourceToAdd(emissionFactorRecord.id + "; " + emissionFactorRecord.DataSource + "; " + emissionFactorRecord.Scope + "; " + emissionFactorRecord.Level1 + "; " + emissionFactorRecord.Level2 + "; " + emissionFactorRecord.Level3 + "; " + emissionFactorRecord.UOMsimple + "; " + emissionFactorRecord.Year + "; " + emissionFactorRecord.Region + "; " + emissionFactorRecord.Comments + "; " + emissionFactorRecord.ColumnText)
            }

            if (calculationMethodToAdd === "Use Benchmark" && organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd) && commentsToAdd.slice(0,4) === "2.1 ") {
                multiplierRecord = carbonEmissionFactors.find(item => item.Level1 === organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).buildingTypeL1 && item.Level2 === organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).buildingTypeL2 && item.Level3 === "Typical practice electricity" && item.UOMsimple === "kWh/m2 (Treated floor area)")
                setMultiplierToAdd(multiplierRecord.GHGConversion)
                setMultiplierFactorSourceToAdd(multiplierRecord.id + "; " + multiplierRecord.DataSource + "; " + multiplierRecord.Scope + "; " + multiplierRecord.Level1 + "; " + multiplierRecord.Level2 + "; " + multiplierRecord.Level3 + "; " + multiplierRecord.UOMsimple + "; " + multiplierRecord.Year + "; " + multiplierRecord.Region + "; " + multiplierRecord.Comments + "; " + multiplierRecord.ColumnText)
                if (organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).floorAreaUnit === "square feet") {
                    setConsumptionToAdd(organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).floorArea * 0.092903)
                } else {
                    setConsumptionToAdd(organisation.organisationFacilities.find(item => item.facilityName === facilityToAdd).floorArea)
                }
                setUnitToAdd("square metres")
                emissionFactorRecord = carbonEmissionFactors.find(item => item.Level1 === "UK electricity" && item.Level3 === "Electricity: UK" && item.Year === footprint.organisationFootprintYear)
                setEmissionFactorToAdd(emissionFactorRecord.GHGConversion)
                setEmissionFactorSourceToAdd(emissionFactorRecord.id + "; " + emissionFactorRecord.DataSource + "; " + emissionFactorRecord.Scope + "; " + emissionFactorRecord.Level1 + "; " + emissionFactorRecord.Level2 + "; " + emissionFactorRecord.Level3 + "; " + emissionFactorRecord.UOMsimple + "; " + emissionFactorRecord.Year + "; " + emissionFactorRecord.Region + "; " + emissionFactorRecord.Comments + "; " + emissionFactorRecord.ColumnText)
            }

        }

    }, [company, role, userPropertiesLoading, fuelTypeToAdd, calculationMethodToAdd, unitToAdd, carbonEmissionFactors, facilityToAdd, organisation, fromDateToAdd, toDateToAdd, footprint.organisationFootprintYear, commentsToAdd])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            // if (commentsToAdd.slice(0,4) === "1.1 ") {
            //     setFuelTypeToAdd(carbonEmissionFactors.filter(item => item.Level1 === "Fuels" && item.Year === footprint.organisationFootprintYear && item.UOMsimple === "kWh" && item.Comments.includes("Gross CV"))[0].Level3)
            // } else {
            //     setFuelTypeToAdd("")
            // }
            
            if (organisation.organisationFacilities) {
                if (commentsToAdd && organisation.organisationFacilities.find(item => item.entity.toLowerCase() === commentsToAdd.toLowerCase().slice(4,commentsToAdd.length))) {
                    setFacilityToAdd(organisation.organisationFacilities.find(item => item.entity.toLowerCase() === commentsToAdd.toLowerCase().slice(4,commentsToAdd.length)).facilityName)
                }
            }

            if (commentsToAdd.slice(0,4) === "1.1 ") {
                setFuelTypeToAdd("Natural gas")
            } else {
                setFuelTypeToAdd("")
            }

            if (footprint.organisationFootprintItems && commentsToAdd !== "") {
                axios.get(process.env.REACT_APP_API_URL + '/presigned_url/' + footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).id)
                .then((res) => {
                    setFiles(res.data.filter((Item) => Item.name.substring(0, footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).id.length) === footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).id && !Item.name.substring(footprint.organisationFootprintItems.find(item => item.itemDescription === commentsToAdd).id.length + 1, Item.name.length).includes("/")).map(item => ({
                        ...item, // Spread existing key-value pairs
                        selected: -1 // Add new key-value pair
                      })))
                    console.log(res.data)
                })
                .catch((err) => {
                    console.log(err);
                })
            }

        }

    }, [company, role, userPropertiesLoading, commentsToAdd, footprint.organisationFootprintYear, carbonEmissionFactors, organisation.organisationFacilities])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            if (commentsToAdd && fuelTypeToAdd && calculationMethodToAdd && inputMethodToAdd === "New Record") {
                setDescriptionToAdd(fromDateToAdd + " - " + toDateToAdd + " " + commentsToAdd + " " + fuelTypeToAdd + " " + calculationMethodToAdd)
                descriptionInput.current.value = fromDateToAdd + " - " + toDateToAdd + " " + commentsToAdd + " " + fuelTypeToAdd + " " + calculationMethodToAdd
            }

            if (commentsToAdd.slice(0,4) === "2.1 " && calculationMethodToAdd && inputMethodToAdd === "New Record") {
                setDescriptionToAdd(fromDateToAdd + " - " + toDateToAdd + " " + commentsToAdd + " " + calculationMethodToAdd)
                descriptionInput.current.value = fromDateToAdd + " - " + toDateToAdd + " " + commentsToAdd + " " + calculationMethodToAdd
            }

        }

    }, [company, role, userPropertiesLoading, commentsToAdd, calculationMethodToAdd, toDateToAdd, fuelTypeToAdd, fromDateToAdd, inputMethodToAdd])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            if (calculationMethodToAdd === "Actual") {
                setUnitToAdd("")
                setConsumptionToAdd(0)
                setMultiplierFactorSourceToAdd("")
                setMultiplierToAdd(1)
                setEmissionFactorSourceToAdd("")
                setEmissionFactorToAdd(0)
            }

        }

    }, [company, role, userPropertiesLoading, calculationMethodToAdd])


    if (carbonEmissionFactors.length > 0 && !carbonFactorsLoading && !loading && !subItemsLoading && !organisationsLoading && !databaseMappingLoading && organisation) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        
                        <Header title="Organisation Footprint" section="Organisation Footprint - Data Browser" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <br></br>

                        <div>
                            <div className="flex items-center">
                                <div className="w-full mx-auto mb-5 px-3">

                                <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300 mb-5">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Entities & Facilities: </div>
                                                {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl m-4 text-xs">
                                                    <table className="objects-8A min-w-full divide-y divide-gray-200">

                                                        <thead className="bg-pacific-blue-50">
                                                            <tr>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Entity</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Facility</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Country</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Floor Area</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 1.1</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 1.2</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 1.3</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 2.1</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.1</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.2</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.3</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.4</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.5</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.6</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.7</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.8</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.9</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.10</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.11</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.12</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.13</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.14</th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> 3.15</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {organisation.organisationFacilities.map((facility) => (
                                                                <tr key={facility.id}>
                                                                    <td className="px-4 md:px-6  py-2"> {facility.entity} </td>
                                                                    <td className="px-4 md:px-6  py-2 hover: cursor-pointer" onClick={() => setSelectedFacilities([{value: facility.facilityName, label: facility.facilityName}])}> {facility.facilityName} </td>
                                                                    <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {facility.country} </td>
                                                                    <td className="px-4 md:px-6  py-2"> {facility.floorArea}</td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "1.1 Stationary Combustion") ? "px-4 md:px-6  py-2 bg-emerald-400 hover:cursor-pointer" : "px-4 md:px-6  py-2"} onClick={() => {setSelectedFacilities([{value: facility.facilityName, label: facility.facilityName}]); setSelectedSubCategories([{value: "1.1 Stationary Combustion", label: "1.1 Stationary Combustion"}])}}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "1.2 Mobile Combustion") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "1.3 Refrigerants") ? "px-4 md:px-6  py-2 bg-emerald-400 hover:cursor-pointer" : "px-4 md:px-6  py-2"} onClick={() => {setSelectedFacilities([{value: facility.facilityName, label: facility.facilityName}]); setSelectedSubCategories([{value: "1.3 Refrigerants", label: "1.3 Refrigerants"}])}}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "2.1 Purchased Electricity") ? "px-4 md:px-6  py-2 bg-emerald-400 hover:cursor-pointer" : "px-4 md:px-6  py-2"} onClick={() => {setSelectedFacilities([{value: facility.facilityName, label: facility.facilityName}]); setSelectedSubCategories([{value: "2.1 Purchased Electricity", label: "2.1 Purchased Electricity"}])}}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.1 Purchased Goods & Services") ? "px-4 md:px-6  py-2 bg-emerald-400 hover:cursor-pointer" : "px-4 md:px-6  py-2"} onClick={() => {setSelectedFacilities([{value: facility.facilityName, label: facility.facilityName}]); setSelectedSubCategories([{value: "3.1 Purchased Goods & Services", label: "3.1 Purchased Goods & Services"}])}}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.2 Capital Goods") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.3 Fuel & Energy") ? "px-4 md:px-6  py-2 bg-emerald-400 hover:cursor-pointer" : "px-4 md:px-6  py-2"}onClick={() => {setSelectedFacilities([{value: facility.facilityName, label: facility.facilityName}]); setSelectedSubCategories([{value: "3.3 Fuel & Energy", label: "3.3 Fuel & Energy"}])}}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.4 Upstream Transportation & Distribution") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.5 Waste") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.6 Business Travel") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.7 Employee Commuting") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.8 Upstream Leased Assets") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.9 Downstream Transportation & Distribution") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.10 Processing of Sold Products") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.11 Use of Sold Products") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.12 End-of-Life Treatment of Sold Products") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.13 Downstream Leased Assets") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.14 Franchises") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                    <td className={subItems.find(item => item.facility === facility.facilityName && footprint.organisationFootprintItems.find(item => item.itemEntity === facility.entity) && item.subCategory === "3.15 Investments") ? "px-4 md:px-6  py-2 bg-emerald-400" : "px-4 md:px-6  py-2"}></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>

                                                    </table>
                                                </div>

                                                }
    
                                            </div>

                                        </div>
                                    <div className="grid gap-2 sm:gap-7 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Data Import: </div>
                                                {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                <div>

                                                    <div className="flex items-center pt-1">
                                                        <form>

                                                            <input id="csvFileInput" accept={".csv"} type={"file"} onChange={handleOnChange} onClick={handleOnChange}/>
                                                            <label className="p-2" htmlFor="csvFileInput">Choose a File</label>
                                                            {file &&
                                                                <Button color="bondi-blue" text="Import Data" onClick={(e) => { handleOnSubmit(e) }} />
                                                                // <Button color="bondi-blue" text="Import Data " />
                                                            }
                                                        </form>

                                                    </div>

                                                    <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                            <div className="form-control-8A">
                                                                <label className="pl-3 text-xs">Import from your Management System</label>

                                                                <select value={managementSystem} onChange={(e) => setManagementSystem(e.target.value)}>
                                                                    <option value="Xero">Financial - Xero</option>
                                                                    <option value="Oracle">Financial - Oracle</option>
                                                                    <option value="Sage">Financial - Sage</option>
                                                                    <option value="QuickBooks">Financial - QuickBooks</option>
                                                                    <option value="SAP">Financial - SAP</option>
                                                                    <option value="Certinia formerly FinancialForce">Financial - Certinia formerly FinancialForce</option>
                                                                    <option value="Eco Driver">Scope 1 and 2 - Eco Driver</option>

                                                                </select>
                                                            </div></td>
                                                            
                                                            {/* <td className='pl-10 pr-5 hover:cursor-pointer'><img className="pt-4" onClick={() => XeroUserExists()} src={managementSystem === "Xero" ? '/images/xero_software_logo.svg' : managementSystem === "SAP" ? '/images/sap.svg' : managementSystem === "Oracle" ? '/images/Oracle.png' : managementSystem === "Certinia formerly FinancialForce" ? '/images/Certinia.svg' : managementSystem === "QuickBooks" ? '/images/quickbooks.svg' : '/images/Sage.png'} width="80px"></img></td> */}
                                                            <td className='pl-10 pr-5 hover:cursor-pointer'><img className="pt-4" src={managementSystem === "Xero" ? '/images/xero_software_logo.svg' : managementSystem === "SAP" ? '/images/sap.svg' : managementSystem === "Oracle" ? '/images/Oracle.png' : managementSystem === "Certinia formerly FinancialForce" ? '/images/Certinia.svg' : managementSystem === "QuickBooks" ? '/images/quickbooks.svg' : managementSystem === "Eco Driver" ? '/images/ecodriver.png' : '/images/Sage.png'} width="80px"></img></td>
                                                        </tr>
                                                        <tr>
                                                            <td hidden>
                                                                {/* <Button color="bondi-blue" text="Flight Calc" onClick={ () => handleFlights() } /> */}
                                                                <Button color="bondi-blue" text="Flight Calc " />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                </div>

                                                }
    
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                                                </svg>


                                            </div>

                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Actions: </div>
                                                {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                <div>

                                                <div className="flex items-center">
                                                        <div className="w-full mx-auto m-5 px-3">
                                                            <div className="grid grid-cols-2">
                                                            {/* <label className = "text-xs">Carbon Factor Databases</label> */}
                                                                <div className = "text-xs">
                                                                <label className = "text-xs">Carbon Factor Databases</label>
                                                                <MultiSelect
                                                                    options={aggregateCarbonFactorDatabasesArray(carbonEmissionFactors).map((item) => {
                                                                        return {
                                                                            label: item.DataSource,
                                                                            value: item.DataSource
                                                                        }
                                                                    })}
                                                                    value={selectedDatabases}
                                                                    onChange={setSelectedDatabases}
                                                                    labelledBy="Select"
                                                                />
                                                                </div>
                                                                <div className="pl-5 pt-4">
                                                                {/* <button className="rounded-full m-1 p-1 pr-1 text-white t-10 bg-pacific-blue-500 text-center" onClick={() => { switchEmissionFactors() }} data-tip data-for="switchFactors">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`float-left h-6 w-6 text-white ${animationSwitch === true ? "animate-spin" : ""}`} >
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                                </svg>
                                                                Switch Factors
                                                                </button> */}

                                                                <button className="rounded-full m-1 p-1 pr-1 text-white t-10 bg-pacific-blue-500 text-center" data-tip data-for="switchFactors">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`float-left h-6 w-6 text-white ${animationSwitch === true ? "animate-spin" : ""}`} >
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                                </svg>
                                                                Switch Factors 
                                                                </button>

                                                                </div>
                                                            </div>
                                                            </div>
                                                            </div>

                                                    <div className="flex items-center pt-1">

                                                            <div>
                                                                {/* <Button color="bondi-blue" text="Auto Select Factors" onClick={() => {automaticallySelectEmissionFactors()}} />
                                                                <Button color="bondi-blue" text="Delete All Items" onClick={() => { deleteAllFootprintSubItems() }} /> */}
                                                                <Button color="bondi-blue" text="Auto Select Factors " />
                                                            </div>

                                                            <div className="form-control-8A p-3">
                                                                <label className = "text-xs">Inflation Factor</label>
                                                                <input className = "text-xs" type="number" placeholder="Enter Inflation Factor Here" value={inflationFactor} onChange={(e) => setInflationFactor(Number(e.target.value))} />
                                                            </div>

                                                    </div>

                                                </div>

                                                }
    
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                                                </svg>


                                            </div>

                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                {files.length > 0 &&
                                                <div className="text-sm text-gray-500 mb-5">Uploaded Documents:</div>
                                                }
                                                {files.length === 0 &&
                                                <div className="text-sm text-gray-500 ">?? AI Assisted Actions: ??</div>
                                                }
                                                {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                <div>
                                                    {files.length > 0 &&
                                                    
                                                    <table>

                                                    <tbody className="bg-white divide-y divide-gray-200 text-xs">
                                                    {files.map((file) => (
                                                        <tr key={file.name}>
                                                            <td><input
                                                            checked={file.selected}
                                                            onChange={() => selectOrDeselectFile(file.name)}
                                                            type="checkbox"
                                                        /></td>
                                                            <td className="px-4 md:px-6  py-2 hover: cursor-pointer" > {file.name.substring(37, file.name.length)} </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>

                                                    </table>}
                                                    {files.length === 0 &&
                                                    <div className="flex items-center">

                                                            <div className="w-full mx-auto m-5 px-3">
                                                                <div className="grid grid-cols-2 items-center">
                                                                <label className="text-xs">? Scan PDF, Excel, Email or Word Documents and Automatically Assign, Extract and Transform Data ?</label>

                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="pl-11 size-6 h-12">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                                                </svg>

                                                                </div>

                                                            </div>
                                                        
                                                    </div>
                                                    }

                                                    {files.length === 0 &&
                                                    <div className="flex items-center">
                                                            <div className="w-full mx-auto m-5 px-3">
                                                                <div className="grid grid-cols-2">
                                                                <label className="text-xs">? Assign Emission Factors ?</label>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="pl-11 size-6 h-12">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                                                </svg>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    }

                                                    {files.length === 0 &&
                                                    <div className="flex items-center">
                                                            <div className="w-full mx-auto m-5 px-3">
                                                                <div className="grid grid-cols-2">
                                                                <label className="text-xs">? Provide Insights ?</label>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="pl-11 size-6 h-12">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                                                </svg>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    }

                                                </div>

                                                }
    
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                                                </svg>


                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                        <div>
                        {!editToggle && !editSelectedToggle &&
                        <h1 className='ml-5 header-8A mt-10'>Input Data</h1>
                        }
                        {editToggle &&
                        <h1 className='ml-5 header-8A mt-10'>Edit footprint item</h1>
                        }
                        {editSelectedToggle &&
                        <h1 className='ml-5 header-8A mt-10'>Edit selected footprint items</h1>
                        }

                        <div className="flex items-center">
                            <div className="w-full mx-auto mb-5 px-3">
                                <div className="grid grid-cols-2 gap-2 grid-col-1 sm:grid-cols-3 lg:grid-cols-10">
                                    <div className="form-control-8A">
                                        <label className="text-center">Input Method</label>
                                        <select className={editToggle ? "bg-pacific-blue-50" : ""} value={inputMethodToAdd} onChange={(e) => setInputMethodToAdd(e.target.value)}>
                                        <option value="From Documents">From Documents</option>
                                        <option value="From File">From File</option>
                                        <option value="New Record">New Record</option>
                                        </select>
                                    </div>

                                    <div className="form-control-8A">
                                        <label className="text-center">Scope & Entity</label>
                                        <select ref={commentsInput} className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} value={commentsToAdd} onChange={(e) => setCommentsToAdd(e.target.value)}>
                                        <option value={commentsToAdd}>{commentsToAdd}</option>
                                        <option className="bg-slate-300" value="">BLANK</option>
                                        <option className="bg-red-300" value="RECYCLE BIN">RECYCLE BIN</option>
                                        {footprint.organisationFootprintItems.sort((item1, item2) => Number(item1.itemCategory) < Number(item2.itemCategory) ? -1 : Number(item1.itemCategory) > Number(item2.itemCategory) ? 1 : Number(item1.itemSubCategory.slice(2,4)) < Number(item2.itemSubCategory.slice(2,4)) ? -1 : Number(item1.itemSubCategory.slice(2,4)) > Number(item2.itemSubCategory.slice(2,4)) ? 1 : item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0).map((footprintItem) => (                                    
                                            <option key={footprintItem.id} value={footprintItem.itemDescription} > {footprintItem.itemDescription}</option>
                                        ))}
                                        </select>
                                        {/* <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={commentsToAdd} onChange={(e) => setCommentsToAdd(e.target.value)} /> */}
                                    </div>
                                    {commentsToAdd &&
                                    <div className="form-control-8A">
                                        <label className="text-center">Facility</label>
                                        <select ref={commentsInput} className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} value={facilityToAdd} onChange={(e) => setFacilityToAdd(e.target.value)}>
                                        {organisation.organisationFacilities.filter(item => item.entity.toLowerCase() === commentsToAdd.toLowerCase().slice(4,commentsToAdd.length)).map((facility) => (                                    
                                            <option key={facility.facilityName} value={facility.facilityName} > {facility.facilityName}</option>
                                        ))}
                                        </select>
                                    </div>
                                    }
                                    {inputMethodToAdd === "From Documents" &&
                                        <div className="form-control-8A">
                                            <label>Process</label>
                                            <div className ="pt-1">
                                                <Button color="bondi-blue" text="Scan Documents" onClick={() => ocr_PDF()}/>
                                            </div>
                                        </div>
                                    }
                                    {inputMethodToAdd === "From File" &&
                                        <div className="form-control-8A">
                                            <label>Process</label>
                                            <div className ="pt-1">
                                                <Button color="bondi-blue" text="Scan File" onClick={() => ocr_PDF()}/>
                                            </div>
                                        </div>
                                    }
                                    {inputMethodToAdd === "New Record" &&
                                    <>

                                    <div className="form-control-8A">
                                        <label className="text-center">Calculation Method</label>
                                        <select className={editToggle ? "bg-pacific-blue-50" : ""} value={calculationMethodToAdd} onChange={(e) => setCalculationMethodToAdd(e.target.value)}>
                                        <option value="Actual">Actual</option>
                                        <option value="Use Benchmark">Use Benchmark</option>
                                        </select>
                                    </div>
                                    
                                    {commentsToAdd.slice(0,4) === "1.1 " &&
                                    <div className="form-control-8A">
                                        <label className="text-center">Fuel Type</label>
                                        <select className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} value={fuelTypeToAdd} onChange={(e) => setFuelTypeToAdd(e.target.value)}>
                                        {carbonEmissionFactors.filter(item => item.Level1 === "Fuels" && item.Year === footprint.organisationFootprintYear && item.UOMsimple === "kWh" && item.Comments.includes("Gross CV")).map((factor) => (                                    
                                            <option key={factor.id} value={factor.Level3} > {factor.Level3}</option>
                                        ))}
                                        </select>
                                    </div>
                                    }

                                    <div className="form-control-8A">
                                        <label className="text-center">From Date</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="date" value={fromDateToAdd} onChange={(e) => setFromDateToAdd(e.target.value)} />
                                    </div>

                                    <div className="form-control-8A">
                                        <label className="text-center">To Date</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="date" value={toDateToAdd} onChange={(e) => setToDateToAdd(e.target.value)} />
                                    </div>

                                    </>
                                }

                                </div>

                                <div className="grid grid-cols-2 gap-2 grid-col-1 sm:grid-cols-3 lg:grid-cols-11">



                                    {inputMethodToAdd === "New Record" &&
                                    <>
                                    
                                    <div className="form-control-8A col-span-3">
                                        <label className="text-center">Description</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} ref={descriptionInput} type="text" onBlur={() => setDescriptionToAdd(descriptionInput.current.value)} />
                                    </div>
                                    {commentsToAdd.slice(0,3) === "3.1" &&
                                    <div className="form-control-8A">
                                        <label className="text-center">Supplier</label>
                                        {/* <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={supplierToAdd} onChange={(e) => setSupplierToAdd(e.target.value)} /> */}
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} ref={supplierInput} type="text" onBlur={() => setSupplierToAdd(supplierInput.current.value)} />
                                    </div>
                                    }

                                    {(commentsToAdd.slice(0,3) === "3.7" || commentsToAdd.slice(0,3) === "3.6" || commentsToAdd.slice(0,3) === "3.9" || commentsToAdd.slice(0,3) === "3.4") &&
                                    <div className="form-control-8A">
                                        <label className="text-center">From</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={fromPostCodeToAdd} onChange={(e) => setFromPostCodeToAdd(e.target.value)} />
                                    </div>
                                    }

                                    {(commentsToAdd.slice(0,3) === "3.7" || commentsToAdd.slice(0,3) === "3.6" || commentsToAdd.slice(0,3) === "3.9" || commentsToAdd.slice(0,3) === "3.4") &&
                                    <div className="form-control-8A">
                                        <label className="text-center">To</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={toPostCodeToAdd} onChange={(e) => setToPostCodeToAdd(e.target.value)} />
                                    </div>
                                    }

                                    {calculationMethodToAdd === "Actual" &&
                                    <>
                                    <div className="form-control-8A">
                                        <label className="text-center">Consumption</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={consumptionToAdd} onChange={handleChangeForConsumption} />
                                    </div>

                                    <div className="form-control-8A">
                                        <label className="text-center">Unit</label>
                                        <select className={editToggle ? "bg-pacific-blue-50" : ""} value={unitToAdd} onChange={(e) => setUnitToAdd(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="kg">kg</option>
                                        <option value="tonnes">tonnes</option>
                                        <option value="kWh">kWh</option>
                                        <option value="miles">miles</option>
                                        <option value="km">km</option>
                                        <option value="passenger.km">passenger.km</option>
                                        <option value="tonne.km">tonne.km</option>
                                        <option value="litres">litres</option>
                                        <option value="cubic metres">cubic metres</option>
                                        <option value="square metres">square metres</option>
                                        <option value="GJ">GJ</option>
                                        <option value="GBP">GBP</option>
                                        <option value="EUR">EUR</option>
                                        <option value="USD">USD</option>
                                        <option value="unit">unit</option>
                                        <option value="other">other</option>
                                        <option value="various">various</option>
					                    <option value="kgCO2e">kgCO2e</option>
                                        </select>
                                    </div>
                                    </>
                                    }

                                    {/* <div className="form-control-8A whitespace-nowrap">
                                        <label className="text-center">Multiplier Factor Source</label>
                                        <input className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} type="text" value={multiplierFactorSourceToAdd} onChange={(e) => setMultiplierFactorSourceToAdd(e.target.value)} />
                                    </div> */}

                                    <div className={role === 6001 || role === 6002 ? "grid grid-cols-1" : "grid grid-cols-2"}>
                                        <div className="form-control-8A">
                                        <label className="text-center">Multiplier</label>
                                        <input className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} type="number" value={multiplierToAdd} onChange={(e) => setMultiplierToAdd(e.target.value)} />
                                        </div>
                                        {role !== 6001 && role !== 3001 && role !== 6002 &&
                                        <div className="form-control-8A p-1">
                                            <label className="text-center text-slate-100">Select</label>
                                            <Button color="bondi-blue" text={"Select"} onClick={() => viewCarbonFactorsForMultiplier()} />
                                        </div>
                                        }
                                    </div>

                                    
                                    {(commentsToAdd.slice(0,3) === "3.7" || commentsToAdd.slice(0,3) === "3.6" || commentsToAdd.slice(0,3) === "3.9" || commentsToAdd.slice(0,3) === "3.4") &&
                                    <div className="pl-1 pt-5 z-20">
                                        <div className="text-center pb-1">
                                            <label className="text-center">Transport Mode</label>
                                        </div>
                                        <ReactSelect
                                            styles={!editToggle ? styles : stylesWhenEditing}
                                            value={selectedTransportModeToAdd}
                                            onChange={setSelectedTransportModeToAdd}
                                            onClick={setSelectedTransportModeToAdd}
                                            options={transportModeOptions.sort((item1, item2) => (item1.Level2 < item2.Level2) ? -1 : (item1.Level2 > item2.Level2) ? 1 : 0).map((item) => {
                                                return {
                                                    label: item.Level2 + " " + item.Lookup + " " + item.ColumnText,
                                                    value: item.Level2 + " " + item.Lookup + " " + item.ColumnText
                                                }
                                            })} />
                                    </div>
                                    }

                                    {/* <div className="form-control-8A whitespace-nowrap">
                                        <label className="text-center">Emission Factor Source</label>
                                        <input className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} type="text" value={emissionFactorSourceToAdd} onChange={(e) => setEmissionFactorSourceToAdd(e.target.value)} />
                                    </div> */}

                                    {role !== 3001 &&
                                    <div className={role === 6001 || role === 6002 ? "grid grid-cols-1" : "grid grid-cols-2"}>
                                        <div className="form-control-8A">
                                            <label className="text-center">Factor</label>
                                            <input className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} type="number" value={emissionFactorToAdd} onChange={(e) => setEmissionFactorToAdd(e.target.value)} />
                                        </div>
                                        {role !== 6001 && role !== 6002 &&
                                        <div className="form-control-8A p-1">
                                            <label className="text-center text-slate-100">Select</label>
                                            <Button color="bondi-blue" text={"Select"} onClick={() => viewCarbonFactors()} />
                                        </div>
                                        }
                                    </div>
                                    }
                                    <div className="form-control-8A">
                                        <label className="text-center">Emissions (kgCO2e)</label>
                                        <input disabled={commentsToAdd === "RECYCLE BIN" ? true : false} className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={carbonEmissionsToAdd} onChange={handleChangeForEmissions} />
                                    </div>
                                    </>
                                }

                                </div>

                                <div className="flex">
                                    {!editToggle && !editSelectedToggle && (
                                    <Button color="bg-bondi-blue" text="Add Data" onClick={() => addFootprintSubItem()} />
                                    // <Button color="bg-bondi-blue" text="Add Data " />
                                    )}
                                    {editToggle && (
                                    <Button color="bg-bondi-blue" text="Update Item" onClick={() => updateFootprintSubItem()} />
                                    // <Button color="bg-bondi-blue" text="Update Item " />
                                    )}
                                    {editSelectedToggle && (
                                    <Button color="bg-bondi-blue" text="Update Items" onClick={() => updateSelectedFootprintSubItems()} />
                                    // <Button color="bg-bondi-blue" text="Update Items " />
                                    )}
                                    <Button color="bg-bondi-blue" text="Cancel" onClick={() => cancelAddOrEditSubItem()} />

                                </div>
                                {role !== 3001 &&
                                    <div className="float-right">
                                        <Button color="bondi-blue" text="Reset Filters" onClick={() => resetFilters()}/>
                                        <Button color="bondi-blue" text="Select All Filtered Items" onClick={() => selectAllFilteredItems()}/>
                                        <Button color="bondi-blue" text={"Clear Selected Items (" + subItems.filter(item => item.individual === "SELECTED").length + ")"} onClick={() => clearSelectedItems()}/>
                                        <Button color="bondi-blue" text="Edit Selected Items" onClick={() => editSelectedFootprintSubItems()}/>
                                        <Button color="bondi-blue" text="Delete Selected Items" onClick={() => deleteSelectedFootprintSubItems()}/>
                                        {/* <Button color="bondi-blue" text="Delete Selected Items "/> */}

                                    </div>
                                }
                            </div>
                        </div>
                        </div>
                        }

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div className="shadow-md shadow-slate-300 rounded-2xl">
                                        <table id = "subItemsTable" className="objects-8A min-w-full divide-y divide-gray-200">
                                            <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                                                <tr>
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Select</th>
                                                    }
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Scope Category<div className="w-40">
                                                        <MultiSelect
                                                            options={subCategoriesToSelect.map((item) => {
                                                                return {
                                                                    label: item.subCategory,
                                                                    value: item.subCategory
                                                                }
                                                            })}
                                                            value={selectedSubCategories}
                                                            onChange={setSelectedSubCategories}
                                                            labelledBy="Select"
                                                        />
                                                        </div></th> 
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase"> Item Description
                                                                <div className="w-40">
                                                                <MultiSelect
                                                                    options={commentsToSelect.map((item) => {
                                                                        return {
                                                                            label: item.comments,
                                                                            value: item.comments
                                                                        }
                                                                    })}
                                                                    value={selectedComments}
                                                                    onChange={setSelectedComments}
                                                                    labelledBy="Select"
                                                                />
                                                                </div>
                                                        </th> 
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Sub Item Description<button className="uppercase" type="button" onClick={() => sortByDescription()}>{sortedField ==='description' && sortedOrder==='asc' ? '↑' : sortedField==='description' && sortedOrder==='desc' ? '↓' : '⇅'}</button>
                                                        <div className="w-52">

                                                            <div className="form-control-no-margin-8A whitespace-nowrap">
                                                                <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                                            </div>

                                                        </div>
                                                        </th>
                                                        {selectedComments.find(item => item.value.slice(0,3) === "3.1") &&
                                                        <>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Supplier<div className="w-40">
                                                        <MultiSelect
                                                            options={suppliersToSelect.map((item) => {
                                                                return {
                                                                    label: item.supplier,
                                                                    value: item.supplier
                                                                }
                                                            })}
                                                            value={selectedSuppliers}
                                                            onChange={setSelectedSuppliers}
                                                            labelledBy="Select"
                                                        />
                                                        </div></th> 
                                                        </>
                                                        } 

                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Facility<div className="w-40">
                                                        <MultiSelect
                                                            options={facilitiesToSelect.map((item) => {
                                                                return {
                                                                    label: item.facility,
                                                                    value: item.facility
                                                                }
                                                            })}
                                                            value={selectedFacilities}
                                                            onChange={setSelectedFacilities}
                                                            labelledBy="Select"
                                                        />
                                                        </div></th>

                                                        {selectedComments.find(item => item.value.slice(0,3) === "3.7" || item.value.slice(0,3) === "3.4" || item.value.slice(0,3) === "3.9" || item.value.slice(0,3) === "3.6") &&
                                                        <>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> From</th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> To</th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Transport Mode</th>
                                                        </>
                                                        }

                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <Button color="bg-bondi-blue" text="Consumption" onClick={() => handlePostcodesPreCheck()}/><button className="uppercase" type="button" onClick={() => sortByConsumption()}>{sortedField ==='consumption' && sortedOrder==='asc' ? '↑' : sortedField==='consumption' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                    // <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <Button color="bg-bondi-blue" text="Consumption" onClick={() => handlePostcodes()}/><button className="uppercase" type="button" onClick={() => sortByConsumption()}>{sortedField ==='consumption' && sortedOrder==='asc' ? '↑' : sortedField==='consumption' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                    // <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <Button color="bg-bondi-blue" text="Consumption" /><button className="uppercase" type="button" onClick={() => sortByConsumption()}>{sortedField ==='consumption' && sortedOrder==='asc' ? '↑' : sortedField==='consumption' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                     }
                                                    {(role === 3001 || role === 5001 || role === 6002 || role === 2003) &&
                                                     <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Consumption<button className="uppercase" type="button" onClick={() => sortByConsumption()}>{sortedField ==='consumption' && sortedOrder==='asc' ? '↑' : sortedField==='consumption' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                    }
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit<div className="w-24">
                                                        <MultiSelect
                                                            options={unitsToSelect.map((item) => {
                                                                return {
                                                                    label: item.unit,
                                                                    value: item.unit
                                                                }
                                                            })}
                                                            value={selectedUnits}
                                                            onChange={setSelectedUnits}
                                                            labelledBy="Select"
                                                        />
                                                        </div></th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Multiplier</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Multiplier Factor</th>
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <Button color="bg-bondi-blue" text="Emissions Factor" onClick={() => calculateAllEmissionFactors()} /><button className="uppercase" type="button" onClick={() => sortByEmissionFactors()}>{sortedField ==='emissionsFactor' && sortedOrder==='asc' ? '↑' : sortedField==='emissionsFactor' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                    }
                                                    {(role === 5001 || role === 6002 || role === 2003) &&
                                                     <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions Factor <button className="uppercase" type="button" onClick={() => sortByEmissionFactors()}>{sortedField ==='emissionsFactor' && sortedOrder==='asc' ? '↑' : sortedField==='emissionsFactor' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                    }
                                                    {role !== 3001 &&

                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emission Factor Source<div className="w-52">
                                                     
                                                     <MultiSelect
                                                         options={emissionFactorsToSelect.map((item) => {
                                                             return {
                                                                 label: item.emissionFactorSource.indexOf(";") === 24 ? item.emissionFactorSource.slice(26, item.emissionFactorSource.length) : item.emissionFactorSource,
                                                                 value: item.emissionFactorSource
                                                             }
                                                         })}
                                                         value={selectedEmissionFactorSources}
                                                         onChange={setSelectedEmissionFactorSources}
                                                         labelledBy="Select"
                                                     />
                                                     </div></th>

                                                    }
                                                    {/* {role === 3001 &&
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions Factor</th>
                                            } */}
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <Button color="bg-bondi-blue" text="Emissions (kgCO2e)" onClick={() => calculateAllEmissions()} /><button className="uppercase" type="button" onClick={() => sortByEmissions()}>{sortedField ==='emissions' && sortedOrder==='asc' ? '↑' : sortedField==='emissions' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                    }
                                                    {(role === 3001 || role === 5001 || role === 6002 || role === 2003) &&
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap">Emissions (kgCO2e) <button className="uppercase" type="button" onClick={() => sortByEmissions()}>{sortedField ==='emissions' && sortedOrder==='asc' ? '↑' : sortedField==='emissions' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                    }

                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                                    }
                                                    </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {subItemsFiltered.slice(firstRecord, lastRecord).map((footprintSubItem) => (
                                                    <tr key={footprintSubItem.id}>
                                                        {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <td onClick={() => markSubItemAsSelected(footprintSubItem.id)} className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.individual === "SELECTED" ? <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="radio-button-on"><rect width="24" height="24" opacity="0"/><path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/><path d="M12 7a5 5 0 1 0 5 5 5 5 0 0 0-5-5z"/></g></g></svg> : <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="radio-button-off"><rect width="24" height="24" opacity="0"/><path d="M12 22a10 10 0 1 1 10-10 10 10 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8 8 0 0 0-8-8z"/></g></g></svg>} </td>
                                                        }

                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 hover:cursor-pointer "}> {footprintSubItem.subCategory}</td>

                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 hover:cursor-pointer "}> {footprintSubItem.comments}</td>

                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 hover:cursor-pointer "}> {footprintSubItem.description}</td>
                                                        {selectedComments.find(item => item.value.slice(0,3) === "3.1") &&
                                                        <>
                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : (footprintSubItem.subCategory.slice(0,4) !== "3.6 " && footprintSubItem.subCategory.slice(0,4) !== "3.7 " && footprintSubItem.subCategory.slice(0,4) !== "3.4 ") ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-slate-300"  :  "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.supplier}</td>
                                                        </>
                                                        }
                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 hover:cursor-pointer "}> {footprintSubItem.facility}</td>
                                                        {selectedComments.find(item => item.value.slice(0,3) === "3.7" || item.value.slice(0,3) === "3.4" || item.value.slice(0,3) === "3.9" || item.value.slice(0,3) === "3.6") &&
                                                            <>
                                                            <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : (footprintSubItem.subCategory.slice(0,4) !== "3.6 " && footprintSubItem.subCategory.slice(0,4) !== "3.7 " && footprintSubItem.subCategory.slice(0,4) !== "3.4 ") ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-slate-300"  : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.fromPostCode}</td>
                                                            <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : (footprintSubItem.subCategory.slice(0,4) !== "3.6 " && footprintSubItem.subCategory.slice(0,4) !== "3.7 " && footprintSubItem.subCategory.slice(0,4) !== "3.4 ") ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-slate-300"  : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.toPostCode}</td>
                                                            <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : (footprintSubItem.subCategory.slice(0,4) !== "3.6 " && footprintSubItem.subCategory.slice(0,4) !== "3.7 " && footprintSubItem.subCategory.slice(0,4) !== "3.4 ") ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-slate-300"  : "px-4 md:px-6 py-2 hover:cursor-pointer "}> {footprintSubItem.transportMode}</td>
                                                            </>
                                                        }
                                                       
                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300 text-right" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white text-right" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer text-right"}> {internationalNumberFormat.format(footprintSubItem.consumption)}</td>
                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.unit}</td>
                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.multiplier}</td>
                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300 text-xs" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white text-xs" : "px-4 md:px-6 py-2 hover:cursor-pointer text-xs"}> {footprintSubItem.multiplierFactorSource}</td>
                                                        {role !== 3001 &&
                                                            <td className={footprintSubItem.emissionFactorSource.slice(-4) === "#MAP" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-daintree-400 text-white" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : footprintSubItem.emissionFactorSource.slice(-4) === "#PRE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-emerald-200 text-black" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.emissionFactor}</td>
                                                        }
                                                        {role !== 3001 &&
                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300 text-xs" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white text-xs" : "px-4 md:px-6 py-2 hover:cursor-pointer text-xs"}> {footprintSubItem.emissionFactorSource.indexOf(";") === 24 ? footprintSubItem.emissionFactorSource.slice(26, footprintSubItem.emissionFactorSource.length) : footprintSubItem.emissionFactorSource}</td>
                                                        }
                                                        {/* {role === 3001 &&
                                                <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.emissionFactor}</td>
                                                } */}
                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300 text-right" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white text-right" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer text-right"}> {internationalNumberFormat.format(footprintSubItem.carbonEmissions)}</td>
                                                        
                                                        {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <td className={footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 hover:cursor-pointer "}>
                                                           
                                                            {/* <Options cloneFunction={cloneFootprintSubItem} cloneObject={footprintSubItem} deleteFunction={deleteFootprintSubItem} deleteObject={footprintSubItem.id} editFunction={editFootprintSubItem} editObject={{...footprintSubItem}}></Options> */}
                                                            <Options editFunction={editFootprintSubItem} editObject={{...footprintSubItem}}></Options>
                                                            </td>
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                        <Modal reportingYear={footprint.organisationFootprintYear} tableData={carbonEmissionFactors} onChange={multiplierSelection ? setSelectedMultiplier : setSelectedFactor} onClose={() => setShowModal(false)} show={showModal} title="Browse Emission Factor Database" inflationFact={inflationFactor} allSubItems={subItems} multiplierOrFactor={multiplierSelection ? "multiplier" : "factor"}/>
                                        <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={fieldToSendToModal === "itemConsultantComment" ? [footprint] : subItems} onChange={setSubItemsToXeroData} onClose={() => setShowModalUpdate(false)} show={showModalUpdate} arrayIdToUpdate={collectionArrayIdToSendToModal} title="Import Data" />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='inline-block min-w-full px-3 my-5'>
                            {role !== 6002 && role !== 3001 && !saving &&
                            <Button color="bg-bondi-blue" text="Save" onClick={() => saveOnly()} />
                            // <Button color="bg-bondi-blue" text="Save " />
                            }
                            {role !== 6002 && role !== 3001 && !saving &&
                            <Button color="bg-bondi-blue" text="Save and Close" onClick={() => onSubmit()} />
                            // <Button color="bg-bondi-blue" text="Save and Close " />
                            }
                            {role !== 6002 && role !== 3001 && saving &&
                            <Button color="bg-bondi-blue" text="Saving...."/>
                            }
                            {role !== 6002 && role !== 3001 && saving &&
                            <Button color="bg-bondi-blue" text="Saving...." />
                            }
                            <Button color="bg-bondi-blue" text="Close" onClick={() => onClose()} />
                            {role !== 6002 && role !== 3001 &&
                            <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(subItems)} />
                            }
            
                            <div className="float-right">{firstRecord + " to " + lastRecord + " of " +
                                                        subItemsFilteredNumberOfItems + " Sub Items  " + subItemsFilteredTotaltCO2e + " tCO2e"}
                                                    
                                                        <span className = "p-2"></span>
                                                        <Button text="<< Previous 100" onClick={() => prev100Records()}></Button>
                                                        <Button text="Next 100 >>" onClick={() => next100Records()}></Button>

                                                    </div>

                        </div>
                    </div>
                </div>
                <ModalLoading onClose={() => setShowModalLoading(false)} show={showModalLoading} title="Data Processing" totalRecords={totalNumberOfRecordsToImport} currentRecord={currentRecordToImport}/>
                <InfoModal onClose={() => {setProcessWhat("");setShowInfoModal(false)}} onZero={() => {setProcessWhat("Zero");setShowInfoModal(false)}} onAll={() => {setProcessWhat("All");setShowInfoModal(false)}} show={showInfoModal} title="Distance Matrix" message="Some of the records already have a distance set.  Would you like to just calculate distances for any record with 0km currently set?" />
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Organisation Footprint" section="Organisation Footprint - Data Browser" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

};

export default ViewOrganisationFootprintItemHelperToolAllSubItems;