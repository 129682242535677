import Header from '../UI/Header'
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import axios from 'axios'
import Button from '../UI/Button'
import { v4 as uuidv4 } from 'uuid'
import Modal from '../UI/Modal'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import ModalLoading from '../UI/ModalLoading'
import InfoModal from '../UI/InfoModal'
import ReactSelect from 'react-select'
import Options from '../UI/Options'
import { ExportToCsv } from 'export-to-csv'
import { MultiSelect } from "react-multi-select-component";
import FileUploadAndDownload from '../FileUploadAndDownload'
import AuthService from '../../services/AuthService'

const ViewOrganisationFootprintItemHelperTool = (props) => {

    const descriptionInput = useRef(null);
    const supplierInput = useRef(null);
    const facilityInput = useRef(null);
    const commentsInput = useRef(null);
    const [sortedField, setSortedField] = useState(null);
    const [sortedOrder, setSortedOrder] = useState(null);
    const match = useMatch('/viewOrganisationFootprintItemHelperTool/:organisationId/:footprintId/:footprintItemId')
    const [firstRecord, setFirstRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(100)
    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [loading, setLoading] = useState(true)
    const [subItemsLoading, setSubItemsLoading] = useState(true)
    const [organisationsLoading, setOrganisationsLoading] = useState(true)
    const [footprint, setFootprint] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [editToggle, setEditToggle] = useState(false)
    const [editSelectedToggle, setEditSelectedToggle] = useState(false)
    const [multiplierSelection, setMultiplierSelection] = useState(false)
    const [subItemsFilteredNumberOfItems, setSubItemsFilteredNumberOfItems] = useState(0)
    const [subItemsFilteredTotaltCO2e, setSubItemsFilteredTotaltCO2e] = useState(0)
    const [subItemsFilteredTotalConsumption, setSubItemsFilteredTotalConsumption] = useState(0)
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState('')
    const [usersLoading, setUsersLoading] = useState(true);
    const [selectedCommentTypes, setSelectedCommentTypes] = useState([{label: "QA", value: "QA"}]);
    const [keyWordsCheck, setKeyWordsCheck] = useState([{keyWord: "train ", subCategory: "3.6 Business Travel"},
    {keyWord: " trains ", subCategory: "3.6 Business Travel"},
    {keyWord: "accomodation", subCategory: "3.6 Business Travel"},
    {keyWord: "Uber", subCategory: "3.6 Business Travel"},
    {keyWord: "Inn ", subCategory: "3.6 Business Travel"},
    {keyWord: "Travel ", subCategory: "3.6 Business Travel"},
    {keyWord: "Tube ", subCategory: "3.6 Business Travel"},
    {keyWord: " prop ", subCategory: "#CHECK"},
    {keyWord: "Membership ", subCategory: "#CHECK"},
    {keyWord: "Recharge ", subCategory: "#CHECK"},
    {keyWord: " Car ", subCategory: "3.6 Business Travel"},
    {keyWord: " bus ", subCategory: "3.6 Business Travel"},
    {keyWord: "minibus", subCategory: "3.6 Business Travel"},
    {keyWord: " Cars", subCategory: "3.6 Business Travel"},
    {keyWord: "Car ", subCategory: "3.6 Business Travel"},
    {keyWord: " Cab ", subCategory: "3.6 Business Travel"},
    {keyWord: " Cabs ", subCategory: "3.6 Business Travel"},
    {keyWord: "hotel", subCategory: "3.6 Business Travel"},
    {keyWord: " ibis ", subCategory: "3.6 Business Travel"},
    {keyWord: " Hilton ", subCategory: "3.6 Business Travel"},
    {keyWord: " Night", subCategory: "3.6 Business Travel"},
    {keyWord: "flight", subCategory: "3.6 Business Travel"},
    {keyWord: "Addison Lee", subCategory: "3.6 Business Travel"},
    {keyWord: "Add Lee", subCategory: "3.6 Business Travel"},
    {keyWord: "bike ", subCategory: "3.6 Business Travel"},
    {keyWord: "Esso ", subCategory: "3.6 Business Travel"},
    {keyWord: "bikes  ", subCategory: "3.6 Business Travel"},
    {keyWord: "Mileage", subCategory: "3.6 Business Travel"},
    {keyWord: "Trainline", subCategory: "3.6 Business Travel"},
    {keyWord: "TfL ", subCategory: "3.6 Business Travel"},
    {keyWord: "Postage", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Shipping Charge", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Royal Mail", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Production supplies ", subCategory: "#CHECK"},
    {keyWord: "Utilities", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Utility", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "service charge", subCategory: "#CHECK"},
    {keyWord: "Machinery", subCategory: "#CHECK"},
    {keyWord: "Diesel", subCategory: "#CHECK"},
    {keyWord: "Deposit", subCategory: "#CHECK"},
    {keyWord: "Limousine", subCategory: "3.6 Business Travel"},
    {keyWord: "Black Lane", subCategory: "3.6 Business Travel"},
    {keyWord: "Blacklane", subCategory: "3.6 Business Travel"},
    {keyWord: "taxi", subCategory: "3.6 Business Travel"},
    {keyWord: "courier", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Delivery ", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Parcel", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: " Rail ", subCategory: "3.6 Business Travel"},
    {keyWord: "Fuel", subCategory: "#CHECK"},
    {keyWord: "Petrol", subCategory: "#CHECK"},
    {keyWord: " Accom", subCategory: "3.6 Business Travel"},
    {keyWord: "Travel", subCategory: "3.6 Business Travel"},
    {keyWord: "Transport", subCategory: "3.6 Business Travel"},
    {keyWord: "Editing cost", subCategory: "#CHECK"},
    {keyWord: "Water Rate", subCategory: "#CHECK"},
    {keyWord: "Waste Water", subCategory: "#CHECK"},
    {keyWord: "Water Supply", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Awards entry", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "License fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Parking", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "BBC Public Service Rights", subCategory: "#CHECK EXCLUDE"},
    {keyWord: " Tax ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Online charge", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Toll ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Exclude ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Bank charge", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Carbon offset", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Business Rate", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Admin Fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Wetransfer", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Deposit", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Refund", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Cancelled", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Cancellation", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Archive", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Business Charges", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Recycling", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Location Fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Flight Change", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Offset", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Overdraft", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Payroll", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Interest", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Debit", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Credit", subCategory: "#CHECK EXCLUDE"},
    {keyWord: " ATM ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Handling Fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Donation", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Charity", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Entry", subCategory: "#CHECK EXCLUDE"},
    {keyWord: " Gas ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Electricity", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "waste disposal", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "levies payable", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "levy", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "visa ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "exchange", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "salaries", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "wages", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "sponsorship", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "pension", subCategory: "#CHECK EXCLUDE"}
    ]) 

    const [subItems, setSubItems] = useState([])
    const [subItemsFiltered, setSubItemsFiltered] = useState([])
    const [subItemsToDelete, setSubItemsToDelete] = useState([])

    const [databaseMapping, setDatabaseMapping] = useState([])
    const [databaseMappingLoading, setDatabaseMappingLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const [selectedDatabases, setSelectedDatabases] = useState([])
    const [selectedComments, setSelectedComments] = useState([])
    const [commentsToSelect, setCommentsToSelect] = useState([])
    const [selectedDescriptions, setSelectedDescriptions] = useState([])
    const [descriptionsToSelect, setDescriptionsToSelect] = useState([])
    const [selectedSuppliers, setSelectedSuppliers] = useState([])
    const [suppliersToSelect, setSuppliersToSelect] = useState([])
    const [selectedFacilities, setSelectedFacilities] = useState([])
    const [selectedTransportModes, setSelectedTransportModes] = useState([])
    const [transportModesToSelect, setTransportModesToSelect] = useState([])
    const [facilitiesToSelect, setFacilitiesToSelect] = useState([])
    const [selectedEmissionFactorSources, setSelectedEmissionFactorSources] = useState([])
    const [emissionFactorsToSelect, setEmissionFactorsToSelect] = useState([])
    const [selectedUnits, setSelectedUnits] = useState([])
    const [unitsToSelect, setUnitsToSelect] = useState([])
    const [carbonEmissionFactors, setCarbonEmissionFactors] = useState([])
    const [carbonFactorsLoading, setCarbonFactorsLoading] = useState(false)
    const [fromPostcodeCoordinates, setFromPostcodeCoordinates] = useState('')
    const [toPostcodeCoordinates, setToPostcodeCoordinates] = useState('')
    const [searchText, setSearchText] = useState('')
    const [searchTextConsumption, setSearchTextConsumption] = useState('')
    const [searchTextMultiplier, setSearchTextMultiplier] = useState('')
    const [searchTextEmissionFactor, setSearchTextEmissionFactor] = useState('')
    const [searchTextFromPostCode, setSearchTextFromPostCode] = useState('')
    const [searchTextToPostCode, setSearchTextToPostCode] = useState('')
    const [searchTextEmissions, setSearchTextEmissions] = useState('')
    const instance = axios.create({});
    const [selectedTransportModeToAdd, setSelectedTransportModeToAdd] = useState({value: "", label: ""})
    const [transportModeOptions, setTransportModeOptions] = useState([])
    const [previousYearsData, setPreviousYearsData] = useState({})
    const [managementSystem, setManagementSystem] = useState('Xero')
    const [descriptionToAdd, setDescriptionToAdd] = useState('')
    const [supplierToAdd, setSupplierToAdd] = useState('')
    const [facilityToAdd, setFacilityToAdd] = useState('')
    const [individualToAdd, setIndividualToAdd] = useState('')
    const [fromPostCodeToAdd, setFromPostCodeToAdd] = useState('')
    const [toPostCodeToAdd, setToPostCodeToAdd] = useState('')
    const [consumptionToAdd, setConsumptionToAdd] = useState(0)
    const [unitToAdd, setUnitToAdd] = useState('')
    const [emissionFactorToAdd, setEmissionFactorToAdd] = useState(0)
    const [carbonEmissionsToAdd, setCarbonEmissionsToAdd] = useState(0)
    const [commentsToAdd, setCommentsToAdd] = useState('')
    const [multiplierToAdd, setMultiplierToAdd] = useState(1)
    const [emissionFactorSourceToAdd, setEmissionFactorSourceToAdd] = useState('')
    const [multiplierFactorSourceToAdd, setMultiplierFactorSourceToAdd] = useState('')
    const [subItemIdToAdd, setSubItemIdToAdd] = useState('')
    const [showModalUpdate, setShowModalUpdate] = useState(false)
    const [showModalLoading, setShowModalLoading] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')
    const internationalNumberFormat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})
    const [inflationFactor, setInflationFactor] = useState(0)
    const [totalNumberOfRecordsToImport, setTotalNumberOfRecordsToImport] = useState(0)
    const [currentRecordToImport, setCurrentRecordToImport] = useState(1)
    const [animationSwitch, setAnimationSwitch] = useState(false)
    const [reloadDataToggle, setReloadDataToggle] = useState(false)
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [processWhat, setProcessWhat] = useState('')

    const URL_REGEX =
	/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    
    const TextWithLinks = (content) =>  {

        const words = content.content.split('\n')

        // console.log(words)
    
        return <div className="text-sm font-medium text-pacific-blue"><p className="my-1 whitespace-pre-line" >{words.map((word) => {
            return word.match(URL_REGEX) ? (
                <>
                    <a href={word} target="_blank" className="underline">{word}</a>{'\n'}
                </>
            ) : (
                word + '\n'
            );
        })}</p></div>
    
    }

        const setUpdatedNumberOfFilesUploaded = async (e, id, operationType) => {

        var newAllFootprintData
        
        await axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints/" + match.params.footprintId)
            .then((res) => {

                newAllFootprintData = res.data

                if (operationType === "upload") {

                    newAllFootprintData.organisationFootprintItems.find(item => item.id === id).filesUploaded = e + 1

                }

                if (operationType === "delete") {

                    newAllFootprintData.organisationFootprintItems.find(item => item.id === id).filesUploaded = e - 1

                }

                setFootprint(newAllFootprintData)

            })
            .catch((err) => {
                console.log(err);
            })
        
        await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, { ...newAllFootprintData })

    }

    const aggregateCarbonFactorDatabasesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.DataSource === val.DataSource + " " + val.Year);
            if (index === -1) {
                acc.push({
                    DataSource: val.DataSource + " " + val.Year,
                });
            };
            return acc.sort((item1, item2) => item1.DataSource < item2.DataSource ? -1 : 0)
        }, []);
    };

    const aggregateCommentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.comments === val.comments);
            if (index === -1) {
                acc.push({
                    comments: val.comments,
                });
            };
            return acc.sort((item1, item2) => item1.comments < item2.comments ? -1 : 0)
        }, []);
    };

    const aggregateSuppliersArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.supplier === val.supplier);
            if (index === -1) {
                acc.push({
                    supplier: val.supplier,
                });
            };
            return acc.sort((item1, item2) => item1.supplier < item2.supplier ? -1 : 0)
        }, []);
    };

    const aggregateFacilitiesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.facility === val.facility);
            if (index === -1) {
                acc.push({
                    facility: val.facility,
                });
            };
            return acc.sort((item1, item2) => item1.facility < item2.facility ? -1 : 0)
        }, []);
    };

    const aggregateTransportModesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.transportMode === val.transportMode);
            if (index === -1) {
                acc.push({
                    transportMode: val.transportMode,
                });
            };
            return acc.sort((item1, item2) => item1.transportMode < item2.transportMode ? -1 : 0)
        }, []);
    };

    const aggregateEmissionFactorSourcesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.emissionFactorSource === val.emissionFactorSource);
            if (index === -1) {
                acc.push({
                    emissionFactorSource: val.emissionFactorSource,
                });
            };
            return acc.sort((item1, item2) => item1.emissionFactorSource < item2.emissionFactorSource ? -1 : 0)
        }, []);
    };

    const aggregateUnitsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.unit === val.unit);
            if (index === -1) {
                acc.push({
                    unit: val.unit,
                });
            };
            return acc.sort((item1, item2) => item1.unit < item2.unit ? -1 : 0)
        }, []);
    };

    const aggregateFootprintItemIdsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.footprintItemId === val.footprintItemId);
            if (index === -1) {
                acc.push({
                    footprintItemId: val.footprintItemId,
                });
            };
            return acc
        }, []);
    };

    const aggregateDescriptionsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.description === val.description);
            if (index === -1) {
                acc.push({
                    description: val.description,
                });
            };
            return acc
        }, []);
    };

    const FV = (rate, nper, pmt, pv, type) => {
        var pow = Math.pow(1 + rate, nper),
           fv;
        if (rate) {
         fv = (pmt*(1+rate*type)*(1-pow)/rate)-pv*pow;
        } else {
         fv = -1 * (pv + pmt * nper);
        }
        return fv;
      }

      const scrollTop = () => {

        //This should scroll to the top of the page
        document.getElementById("subItemsTable").scrollIntoView()
        
    }

    const removeCommas = (num) => num.toString().replace(/,/g, "");

    // const handleChangeForConsumption = (e) => {
    //     const value = e.target.value;
    //     const clean = value.replace(/,/g, "");
    //     const regex = /^[0-9]*\.?[0-9]*$/;

    //     if (value && clean.match(regex)) {
    //         if (!value.includes(".")) {
    //             const formatted = new Intl.NumberFormat('en-US', {
    //                 minimumFractionDigits: 0,
    //                 maximumFractionDigits: 10,
    //             }).format(parseFloat(clean));
    //             setConsumptionToAdd(formatted);
    //         } else {
    //             setConsumptionToAdd(value);
    //         }
    //     } else {
    //         setConsumptionToAdd(value.replace(/[^0-9,.]/g, ''))
    //     }
    // };

    const handleChangeForConsumption = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, ""); // Remove commas
        const regex = /^-?[0-9]*\.?[0-9]*$/; // Allow negative numbers, decimals
    
        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setConsumptionToAdd(formatted);
            } else {
                setConsumptionToAdd(value);
            }
        } else {
            setConsumptionToAdd(value.replace(/[^0-9,.\-]/g, '')); // Remove invalid characters, retain minus sign
        }
    };

    // const setFormattedConsumption = (e) => {
    //     const value = e.toString();
    //     const clean = value.replace(/,/g, "");
    //     const regex = /^[0-9]*\.?[0-9]*$/;

    //     if (value && clean.match(regex)) {

    //         const formatted = new Intl.NumberFormat('en-US', {
    //             minimumFractionDigits: 0,
    //             maximumFractionDigits: 10,
    //         }).format(parseFloat(clean));
    //         setConsumptionToAdd(formatted);

    //     } else {
    //         setConsumptionToAdd("");
    //     }

    // };

    const setFormattedConsumption = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, ""); // Remove commas
        const regex = /^-?[0-9]*\.?[0-9]*$/; // Allow negative numbers and decimals
    
        if (value && clean.match(regex)) {
            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setConsumptionToAdd(formatted);
        } else {
            // Remove invalid characters but retain minus sign, decimals, and numbers
            const sanitized = value.replace(/[^0-9,.\-]/g, "").replace(/(?!^)-/g, ""); // Retain only leading minus
            setConsumptionToAdd(sanitized);
        }
    };

    // const handleChangeForEmissions = (e) => {
    //     const value = e.target.value;
    //     const clean = value.replace(/,/g, "");
    //     const regex = /^[0-9]*\.?[0-9]*$/;

    //     if (value && clean.match(regex)) {
    //         if (!value.includes(".")) {
    //             const formatted = new Intl.NumberFormat('en-US', {
    //                 minimumFractionDigits: 0,
    //                 maximumFractionDigits: 10,
    //             }).format(parseFloat(clean));
    //             setCarbonEmissionsToAdd(formatted);
    //         } else {
    //             setCarbonEmissionsToAdd(value);
    //         }
    //     } else {
    //         setCarbonEmissionsToAdd(value.replace(/[^0-9,.]/g, ''))
    //     }
    // };

    const handleChangeForEmissions = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, ""); // Remove commas
        const regex = /^-?[0-9]*\.?[0-9]*$/; // Allow negative numbers, decimals
    
        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setCarbonEmissionsToAdd(formatted);
            } else {
                setCarbonEmissionsToAdd(value);
            }
        } else {
            setCarbonEmissionsToAdd(value.replace(/[^0-9,.\-]/g, '')); // Remove invalid characters, retain minus sign
        }
    };

    // const setFormattedEmissions = (e) => {
    //     const value = e.toString();
    //     const clean = value.replace(/,/g, "");
    //     const regex = /^[0-9]*\.?[0-9]*$/;

    //     if (value && clean.match(regex)) {

    //         const formatted = new Intl.NumberFormat('en-US', {
    //             minimumFractionDigits: 0,
    //             maximumFractionDigits: 10,
    //         }).format(parseFloat(clean));
    //         setCarbonEmissionsToAdd(formatted);

    //     } else {
    //         setCarbonEmissionsToAdd("");
    //     }

    // };

    const setFormattedEmissions = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, ""); // Remove commas
        const regex = /^-?[0-9]*\.?[0-9]*$/; // Allow negative numbers and decimals
    
        if (value && clean.match(regex)) {
            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setCarbonEmissionsToAdd(formatted);
        } else {
            // Remove invalid characters but retain minus sign, decimals, and numbers
            const sanitized = value.replace(/[^0-9,.\-]/g, "").replace(/(?!^)-/g, ""); // Retain only leading minus
            setCarbonEmissionsToAdd(sanitized);
        }
    };

    const prev100Records = () => {
        if (firstRecord > 99) {

            setFirstRecord(firstRecord - 100)
            setLastRecord(lastRecord - 100)

        }

        scrollTop()

    }

    const next100Records = () => {

        if (lastRecord <= subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedTransportModes.find((itemB) => {
                return itemA.transportMode === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase())).filter(item => item.consumption.toString().toLowerCase().includes(searchTextConsumption.toLowerCase())).filter(item => item.multiplier.toString().toLowerCase().includes(searchTextMultiplier.toLowerCase())).filter(item => item.emissionFactor.toString().toLowerCase().includes(searchTextEmissionFactor.toLowerCase())).filter(item => item.carbonEmissions.toString().toLowerCase().includes(searchTextEmissions.toLowerCase())).filter(item => item.fromPostCode.toString().toLowerCase().includes(searchTextFromPostCode.toLowerCase())).filter(item => item.toPostCode.toString().toLowerCase().includes(searchTextToPostCode.toLowerCase())).length) {

            setFirstRecord(firstRecord + 100)
            setLastRecord(lastRecord + 100)

        }

        scrollTop()

    }

    const clearSelectedItems = () => {

        var subItemIndex
        var newSubItems
        newSubItems = [ ...subItems ]

        for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
            newSubItems[subItemIndex].individual = ""
        }

        setSubItems(newSubItems)

    };

    const sortByEmissions = () => {

        var newSubItems
        newSubItems = [ ...subItems ]
       
        if (sortedOrder === "asc") {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.carbonEmissions) > Number(item2.carbonEmissions) ? -1 : Number(item1.carbonEmissions) < Number(item2.carbonEmissions) ? 1 : 0)
            setSortedOrder("desc")
        } else {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.carbonEmissions) < Number(item2.carbonEmissions) ? -1 : Number(item1.carbonEmissions) > Number(item2.carbonEmissions) ? 1 : 0)
            setSortedOrder("asc")
        }

        setSubItems(newSubItems)

        setSortedField("emissions")

    };

    const sortByEmissionFactors = () => {

        var newSubItems
        newSubItems = [ ...subItems ]
       
        if (sortedOrder === "asc") {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.emissionFactor) > Number(item2.emissionFactor) ? -1 : Number(item1.emissionFactor) < Number(item2.emissionFactor) ? 1 : 0)
            setSortedOrder("desc")
        } else {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.emissionFactor) < Number(item2.emissionFactor) ? -1 : Number(item1.emissionFactor) > Number(item2.emissionFactor) ? 1 : 0)
            setSortedOrder("asc")
        }

        setSubItems(newSubItems)

        setSortedField("emissionsFactor")

    };

    const sortByConsumption = () => {

        var newSubItems
        newSubItems = [ ...subItems ]
       
        if (sortedOrder === "asc") {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.consumption) > Number(item2.consumption) ? -1 : Number(item1.consumption) < Number(item2.consumption) ? 1 : 0)
            setSortedOrder("desc")
        } else {
            newSubItems = newSubItems.sort((item1, item2) => Number(item1.consumption) < Number(item2.consumption) ? -1 : Number(item1.consumption) > Number(item2.consumption) ? 1 : 0)
            setSortedOrder("asc")
        }

        setSubItems(newSubItems)

        setSortedField("consumption")

    };

    const sortByDescription = () => {

        var newSubItems
        newSubItems = [ ...subItems ]
       
        if (sortedOrder === "asc") {
            newSubItems = newSubItems.sort((item1, item2) => item1.description > item2.description ? -1 : item1.description < item2.description ? 1 : 0)
            setSortedOrder("desc")
        } else {
            newSubItems = newSubItems.sort((item1, item2) => item1.description < item2.description ? -1 : item1.description > item2.description ? 1 : 0)
            setSortedOrder("asc")
        }

        setSubItems(newSubItems)

        setSortedField("description")

    };

    const selectAllFilteredItems = () => {

        var subItemIndex
        var filteredItemIndex
        var newSubItems
        // var allFilteredItems
        newSubItems = [ ...subItems ]

        // allFilteredItems = subItems.filter((itemA) => {
        //     return selectedComments.find((itemB) => {
        //         return itemA.comments === itemB.value;
        //     })
        // }).filter((itemA) => {
        //     return selectedSuppliers.find((itemB) => {
        //         return itemA.supplier === itemB.value;
        //     })
        // }).filter((itemA) => {
        //     return selectedFacilities.find((itemB) => {
        //         return itemA.facility === itemB.value;
        //     })
        // }).filter((itemA) => {
        //     return selectedUnits.find((itemB) => {
        //         return itemA.unit === itemB.value;
        //     })
        // }).filter((itemA) => {
        //     return selectedEmissionFactorSources.find((itemB) => {
        //         return itemA.emissionFactorSource === itemB.value;
        //     })
        // }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase()))

        // for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
        
        //     newSubItems[subItemIndex].individual = ""

        // }

        // for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
        //     for (filteredItemIndex = 0; filteredItemIndex < allFilteredItems.length; filteredItemIndex++) {
        //         if (allFilteredItems[filteredItemIndex].id === newSubItems[subItemIndex].id)
        //         newSubItems[subItemIndex].individual = "SELECTED"
        //     }
        // }

        if (subItemsFiltered.length === newSubItems.length) {

            for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
                newSubItems[subItemIndex].individual = "SELECTED"
            }

        } else {

            for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
                newSubItems[subItemIndex].individual = ""
            }

            for (filteredItemIndex = 0; filteredItemIndex < subItemsFiltered.length; filteredItemIndex++) {
                newSubItems.find(item => item.id === subItemsFiltered[filteredItemIndex].id).individual = "SELECTED"
            }

        }

        setSubItems(newSubItems)

    };

    const deleteCommentFromThread = async (id, footprintItemId) => {

        var newAllFootprintData

        await axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints/" + match.params.footprintId)
            .then((res) => {

                newAllFootprintData = res.data

                newAllFootprintData.organisationFootprintItems.find(item => item.id === footprintItemId).commentThread = newAllFootprintData.organisationFootprintItems.find(item => item.id === footprintItemId).commentThread.filter(item => item.id !== id)

                axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, { ...newAllFootprintData })

                setFootprint(newAllFootprintData)

            })
            .catch((err) => {
                console.log(err);
            })


    }

    const commentThread = (e, footprintItemId, itemValidated) => {

        //const commentThreadFiltered = e.filter(item => item.commentType === "Client" || item.commentType === "Consultant")

        var lastCommentId = ""
        
        const allComments = e.filter(item => item.commentType !== "")

        if (allComments.length > 0) {

            lastCommentId = allComments[allComments.length -1].id

        }
        
        const commentThreadFiltered = allComments.filter((itemA) => {
            return selectedCommentTypes.find((itemB) => {
                return itemA.commentType === itemB.value;
            })
        })

        if (commentThreadFiltered.length > 0) {

        return (
        <div className="pb-2">
            <table className="w-full">

                <tbody>
                    {commentThreadFiltered.map((commentInThread, i) => (
                        <tr key={commentInThread.id} className="bg-transparent">
                            <td className = "pr-2 w-14 border-b-2">{commentInThread.commentDate.slice(0,21)}</td>
                            <td className = "pr-2 w-96 border-b-2">{commentInThread.comment + " (" + commentInThread.commentType + ")"}</td>
                            <td className = "w-14 border-b-2">{users.find(item => item.id === commentInThread.commentUser) ? users.find(item => item.id === commentInThread.commentUser).firstName + " " + users.find(item => item.id === commentInThread.commentUser).lastName : ""}</td>
                            <td className = "pr-2 w-14 border-b-2">{userId === commentInThread.commentUser && commentInThread.id === lastCommentId && itemValidated !=="Validated" ? <button className="hover:cursor-pointer float-right" onClick={() => { deleteCommentFromThread(commentInThread.id, footprintItemId) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> </svg></button> : <></>}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
        )
        }

    };

    const arrayToCsv = (data) => {


        //THIS IS TEMPORARY CODE ONLY TO SUBTLY UPDATE SUBCATEGORY CORRECTLY
        var subItemIndex
        var newSubItems = [...subItems]

        for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {

            newSubItems[subItemIndex].subCategory = footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemSubCategory

        }

        setSubItems(newSubItems)
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Sub Items Export from Helper Tool',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        var dataWithHeadersOrdered = []

        for (subItemIndex = 0; subItemIndex < data.length; subItemIndex++) {

            if (role === 3001 || role === 6001) {
                dataWithHeadersOrdered.push({id: data[subItemIndex].id, description: data[subItemIndex].description, supplier: data[subItemIndex].supplier, facility: data[subItemIndex].facility, individual: data[subItemIndex].individual, fromPostCode: data[subItemIndex].fromPostCode, toPostCode: data[subItemIndex].toPostCode, transportMode: data[subItemIndex].transportMode, consumption: data[subItemIndex].consumption, multiplier: data[subItemIndex].multiplier, unit: data[subItemIndex].unit, carbonEmissions: data[subItemIndex].carbonEmissions, comments: data[subItemIndex].comments})
            } else {
                dataWithHeadersOrdered.push({id: data[subItemIndex].id, description: data[subItemIndex].description, supplier: data[subItemIndex].supplier, facility: data[subItemIndex].facility, individual: data[subItemIndex].individual, fromPostCode: data[subItemIndex].fromPostCode, toPostCode: data[subItemIndex].toPostCode, transportMode: data[subItemIndex].transportMode, consumption: data[subItemIndex].consumption, multiplier: data[subItemIndex].multiplier, unit: data[subItemIndex].unit, emissionFactor: data[subItemIndex].emissionFactor, carbonEmissions: data[subItemIndex].carbonEmissions, comments: data[subItemIndex].comments, emissionFactorSource: data[subItemIndex].emissionFactorSource, multiplierFactorSource: data[subItemIndex].multiplierFactorSource})
            }
        }

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(dataWithHeadersOrdered)

    }

    //set styles for ReactSelect
    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            fontSize: 17,
            minWidth: "100%",
            borderRadius: "0%",
            height: "41px"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
            background: "#bfe3f1"
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: "500px"
        }),
    }

    const stylesWhenEditing = {
        option: provided => ({
            ...provided,
            color: 'black',
            background: "#bfe3f1"
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            fontSize: 17,
            minWidth: "100%",
            borderRadius: "0%",
            height: "41px",
            background: "#bfe3f1"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
            background: "#bfe3f1"
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: "500px",
            background: "#bfe3f1"
        }),
    }

    const navigate = useNavigate()

    const [file, setFile] = useState()

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        const fileReader = new FileReader()

        if (file) {
            fileReader.onload = function (organisation) {
                const text = organisation.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
            setFile()
            document.getElementById("csvFileInput").value = ""

        }else{
            window.alert("No file selected")
        }

    };
    
    const handlePostcodesPreCheck = async () => {
        var subItemIndex
        let iCount = 0
        
        for (subItemIndex = 0; subItemIndex < subItems.length; subItemIndex++) {
            
            let originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
            let destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()
            let kms = subItems[subItemIndex].consumption

            if (originPostcode!=='' && destPostcode!=='' && kms!==0){
                iCount++
            }

        }

        if (iCount>0){
            setShowInfoModal(true)
        }else{
            handlePostcodes()
        }
    }

    function deg2rad(deg) {
        return deg * (Math.PI/180)
    }

    const handleFlights = async () => {

        let origin, destination
        let record
        let postcodesArray = []
        let lat1, lon1, lat2, lon2
        var subItemIndex
        let originPostcode = ''
        let destPostcode = ''
        let transportMode = ''
        var newSubItems = [ ...subItems ]
        let postcodeCount = 0
        let distance = 0

        for (subItemIndex = 0; subItemIndex < subItems.length; subItemIndex++) {
                
            originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
            destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()
            transportMode= subItems[subItemIndex].transportMode

            if (originPostcode!=="" && destPostcode!=="" && originPostcode!=="#N/A" && destPostcode!=="#N/A" && transportMode.toLowerCase().includes("flights")) {
                
                //Get all the postcodes into an array
                postcodesArray.push({id: subItems[subItemIndex].id, origin: subItems[subItemIndex].fromPostCode, destination: subItems[subItemIndex].toPostCode});
                postcodeCount++

            }
        }

        setTotalNumberOfRecordsToImport(postcodesArray.length)

        let totalLoops = postcodesArray.length

        if (postcodesArray.length>0){

            setShowModalLoading(true);

            var arrAirports

            await axios.get(process.env.REACT_APP_API_URL + '/airports')
            .then(response => {
                arrAirports = response.data
            })
            .catch(error => {
                console.log(error.message)
            })

            for (let index = 0; index < totalLoops; index++) {

                setCurrentRecordToImport(index)

                origin = postcodesArray[index].origin.replaceAll('/', '')
                destination = postcodesArray[index].destination.replaceAll('/', '')
                record = postcodesArray[index].id

                //Get origin coordinates
                // await axios.get(process.env.REACT_APP_API_URL + '/airports/'+ origin)
                //     .then(response => {
                //         lat1 = response.data.lat
                //         lon1 = response.data.lon
                //     })
                //     .catch(error => {
                //         console.log(error.message)
                //     })

                lat1 = arrAirports.find(item => item.iata_code === origin).lat
                lon1 = arrAirports.find(item => item.iata_code === origin).lon

                lat2 = arrAirports.find(item => item.iata_code === destination).lat
                lon2 = arrAirports.find(item => item.iata_code === destination).lon

                //Get dest coordinates
                // await axios.get(process.env.REACT_APP_API_URL + '/airports/'+ destination)
                // .then(response => {
                //     lat2 = response.data.lat
                //     lon2 = response.data.lon
                // })
                // .catch(error => {
                //     console.log(error.message)
                // })
                
                var R = 6371; // Radius of the earth in km
                var dLat = deg2rad(lat2-lat1);  // deg2rad below
                var dLon = deg2rad(lon2-lon1); 
                var a = 
                    Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
                    Math.sin(dLon/2) * Math.sin(dLon/2)
                    ; 
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
                var d = R * c; // Distance in km
                distance = d.toFixed(2)

                newSubItems.find(subItem => subItem.id === record).consumption = distance
                
            }

            setShowModalLoading(false);
        }else{
            window.alert('No records with "flight" in the transport mode')
        }

    }

    const handlePostcodes = async () => {

        let origins = ''
        let destinations = ''
        let distance = ''
        let distances = []
        let postcodesArray = []
        let originsArray = []
        let destinationsArray = []
        var subItemIndex
        let startRecord = 0
        let endRecord = 0
        let originPostcode = ''
        let destPostcode = ''
        var newSubItems = [ ...subItems ]
        let postcodeCount = 0
        let originCount = 0
        let destinationCount = 0
        let kms = 0

        if (processWhat==="All" || processWhat===""){
            // check that there are some records with from and to data
            for (subItemIndex = 0; subItemIndex < subItems.length; subItemIndex++) {
                
                // originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
                // destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()

                if (subItems[subItemIndex].fromPostCode!=="" && subItems[subItemIndex].toPostCode!=="") {
                    originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
                    destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()
                    //check for multiple postcodes
                    //first postcode
                    if (subItemIndex===0){
                        originsArray.push(originPostcode)
                        originCount=1
                        destinationsArray.push(destPostcode)
                        destinationCount=1
                    }else{
                        if (!originsArray.includes(originPostcode)){
                            originsArray.push(originPostcode)
                            originCount++
                        }
                        if (!destinationsArray.includes(destPostcode)){
                            destinationsArray.push(destPostcode)
                            destinationCount++
                        }
                    }
                    
                    //Get all the postcodes into an array
                    postcodesArray.push({id: subItems[subItemIndex].id, origin: subItems[subItemIndex].fromPostCode, destination: subItems[subItemIndex].toPostCode});
                    postcodeCount++

                }
            }
        }else if (processWhat==="Zero"){
            // check that there are some records with from and to data
            for (subItemIndex = 0; subItemIndex < subItems.length; subItemIndex++) {
                
                // originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
                // destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()
                kms = subItems[subItemIndex].consumption

                if (subItems[subItemIndex].fromPostCode!=='' && subItems[subItemIndex].toPostCode!=='' && kms===0){

                    originPostcode = subItems[subItemIndex].fromPostCode.toUpperCase()
                    destPostcode = subItems[subItemIndex].toPostCode.toUpperCase()

                    //check for multiple postcodes
                    //first postcode
                    if (subItemIndex===0){
                        originsArray.push(originPostcode)
                        originCount=1
                        destinationsArray.push(destPostcode)
                        destinationCount=1
                    }else{
                        if (!originsArray.includes(originPostcode)){
                            originsArray.push(originPostcode)
                            originCount++
                        }
                        if (!destinationsArray.includes(destPostcode)){
                            destinationsArray.push(destPostcode)
                            destinationCount++
                        }
                    }
                    
                    //Get all the postcodes into an array
                    postcodesArray.push({id: subItems[subItemIndex].id, origin: subItems[subItemIndex].fromPostCode, destination: subItems[subItemIndex].toPostCode});
                    postcodeCount++

                }
            }
        }

        //If they are, run the code that checks 25 origins per pass
        if (postcodeCount > 0){

            setTotalNumberOfRecordsToImport(postcodesArray.length)

            //Check number of origins and destinations to determine which code to run
            if ((originCount>1 && destinationCount>1) || (originCount===1 && destinationCount===1)) {
                if (window.confirm("Postcodes will be calculated on a line by line basis.  Would you like to continue?") === false){
                    return
                }else{
                    let totalLoops = postcodesArray.length

                    setShowModalLoading(true);

                    for (let index = 0; index < totalLoops; index++) {

                        setCurrentRecordToImport(index)

                        origins = postcodesArray[index].origin.replaceAll('/', '')
                        destinations = postcodesArray[index].destination.replaceAll('/', '')

                        //Get distance data
                        await axios.get(process.env.REACT_APP_API_URL + '/DistanceMatrix/'+ origins +'/'+ destinations)
                        .then(response => {
                            console.log(response)
                            distances = response.data
                        })
                        .then(()=>{
                            // for (let index2 = 0; index2 < distances.rows.length; index2++) {
                                const record = postcodesArray[index].id;
                                if (distances.rows[0].elements[0].status!=="OK"){
                                    distance=0
                                    newSubItems.find(subItem => subItem.id === record).comments = "#CHECK"
                                }else{
                                    distance=distances.rows[0].elements[0].distance.text
                                    distance=distance.replace(/\,/g,'') //remove commas
                                    distance=distance.replace(' km','') //remove km
                                    if (distance.includes("m")) {
                                        distance=distance.replace(' m','') //remove m
                                        distance=distance/1000
                                    }
                                }
                                newSubItems.find(subItem => subItem.id === record).consumption = distance
                            // }
                        })
                        .catch(error => {
                            console.log(error.message)
                        })

                        //Increase the counter to get the next set of records
                        origins = ''
                        destinations = ''
                                            
                    }
                }
            }else if (originCount>1 && destinationCount===1) {
                console.log("multiple origins and single destination")
                
                setShowModalLoading(true);

                // Maximum number of objects than can be called (per origin/dest list) is 25        
                if (originsArray.length<=25){
                    endRecord=originsArray.length
                }else{
                    endRecord=startRecord+25
                }

                // Get the number of loops needed
                let totalLoops = postcodesArray.length/25

                for (let indexAll = 0; indexAll < totalLoops; indexAll++) {

                    setCurrentRecordToImport(startRecord)

                    for (let index = startRecord; index < endRecord; index++) {
                        origins = origins === '' ? origins + postcodesArray[index].origin.replaceAll('/', '') : origins + '|' + postcodesArray[index].origin.replaceAll('/', '')
                    }

                    //Get distance data
                    await axios.get(process.env.REACT_APP_API_URL + '/DistanceMatrix/'+ origins +'/'+ destPostcode)
                    .then(response => {
                        console.log(response)
                        distances = response.data
                    })
                    .then(()=>{
                        for (let index2 = 0; index2 < distances.rows.length; index2++) {
                            const record = postcodesArray[startRecord+index2].id;
                            if (distances.rows[index2].elements[0].status!=="OK"){
                                distance=0
                                newSubItems.find(subItem => subItem.id === record).comments = "#CHECK"
                            }else{
                                distance=distances.rows[index2].elements[0].distance.text
                                distance=distance.replace(/\,/g,'') //remove commas
                                distance=distance.replace(' km','') //remove km
                                if (distance.includes("m")) {
                                    distance=distance.replace(' m','') //remove m
                                    distance=distance/1000
                                }
                            }
                            newSubItems.find(subItem => subItem.id === record).consumption = distance
                        }
                    })
                    .catch(error => {
                        console.log(error.message)
                    })

                    //Increase the counter to get the next set of records
                    origins = ''
                    destinations = ''
                    startRecord = startRecord+25
                    if (endRecord+25 >= postcodesArray.length){
                        endRecord=postcodesArray.length
                    }else{
                        endRecord=endRecord+25
                    }
                    
                }

            }else if (originCount===1 && destinationCount>1) {
                console.log("single origin and multiple destinations")

                setShowModalLoading(true);

                // Maximum number of objects than can be called (per origin/dest list) is 25        
                if (destinationsArray.length<=25){
                    endRecord=destinationsArray.length
                }else{
                    endRecord=startRecord+25
                }

                // Get the number of loops needed
                let totalLoops = postcodesArray.length/25

                for (let indexAll = 0; indexAll < totalLoops; indexAll++) {

                    setCurrentRecordToImport(startRecord)

                    for (let index = startRecord; index < endRecord; index++) {
                        // origins = origins === '' ? origins + postcodesArray[index].origin.replaceAll('/', '') : origins + '|' + postcodesArray[index].origin.replaceAll('/', '')
                        destinations = destinations === '' ? destinations + postcodesArray[index].destination.replaceAll('/', '') : destinations + '|' + postcodesArray[index].destination.replaceAll('/', '')
                    }

                    //Get distance data
                    await axios.get(process.env.REACT_APP_API_URL + '/DistanceMatrix/'+ originPostcode +'/'+ destinations)
                    .then(response => {
                        console.log(response)
                        distances = response.data
                    })
                    .then(()=>{
                        for (let index2 = 0; index2 < distances.rows[0].elements.length; index2++) {
                            const record = postcodesArray[startRecord+index2].id;
                            if (distances.rows[0].elements[index2].status!=="OK"){
                                distance=0
                                newSubItems.find(subItem => subItem.id === record).comments = "#CHECK"
                            }else{
                                distance=distances.rows[0].elements[index2].distance.text
                                distance=distance.replace(/\,/g,'') //remove commas
                                distance=distance.replace(' km','') //remove km
                                if (distance.includes("m")) {
                                    distance=distance.replace(' m','') //remove m
                                    distance=distance/1000
                                }
                            }
                            newSubItems.find(subItem => subItem.id === record).consumption = distance
                        }
                    })
                    .catch(error => {
                        console.log(error.message)
                    })

                    //Increase the counter to get the next set of records
                    origins = ''
                    destinations = ''
                    startRecord = startRecord+25
                    if (endRecord+25 >= postcodesArray.length){
                        endRecord=postcodesArray.length
                    }else{
                        endRecord=endRecord+25
                    }
                    
                }

            }

        }else{
            window.alert("Error - There do not seem to be any records with TO and FROM Postcodes")
        }

        setSelectedComments(aggregateCommentsArray(newSubItems).map((item) => {
            return {
                label: item.comments,
                value: item.comments
            }
        }))

        setSelectedSuppliers(aggregateSuppliersArray(newSubItems).map((item) => {
            return {
                label: item.supplier,
                value: item.supplier
            }
        }))

        setSelectedFacilities(aggregateFacilitiesArray(newSubItems).map((item) => {
            return {
                label: item.facility,
                value: item.facility
            }
        }))

        setSelectedTransportModes(aggregateTransportModesArray(newSubItems).map((item) => {
            return {
                label: item.transportMode,
                value: item.transportMode
            }
        }))

        setSelectedUnits(aggregateUnitsArray(newSubItems).map((item) => {
            return {
                label: item.unit,
                value: item.unit
            }
        }))

        setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
            return {
                label: item.emissionFactorSource,
                value: item.emissionFactorSource
            }
        }))

        setSubItems(newSubItems)

        setShowModalLoading(false);

    };

    const csvFileToArray = string => {

        if (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified === "Compliant") {

            window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

        } else {

            var tempCarbonFactorFromDatabase
            var startOfRateNumber
            var endOfRateNumber
            var inflationRateUsed
            var factorDBYear

            //below line of code coudl be useful if we want to dynamically handle different column names (keys)
            //const csvHeader = string.slice(0, string.indexOf("\n")).split(",");

            const csvHeader = ["id", "description", "supplier", "facility", "individual", "fromPostCode", "toPostCode", "transportMode", "consumption", "multiplier", "unit", "emissionFactor", "carbonEmissions", "comments", "emissionFactorSource", "multiplierFactorSource"]
            const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

            //drop the last line (blank)
            csvRows.pop()

            var lengthCheck = true

            const array = csvRows.map(i => {

                //const values = i.split(",")

                const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)

                var field

                if (values.length !== 16) {
                    lengthCheck = false
                }

                for (field = 0; field < values.length; field++) {

                    values[field] = values[field].replace(/^"|"$/g, '')
                    values[field] = values[field].replace(/""/g, '"')
                    values[field] = values[field].replace(/^"|"$/, '')

                    //If the last field has any commas then a traling quotation mark (") still remains before the end of the line
                    //This code checks for this and removes the quotation mark (")

                    if (values[field].slice(values[field].length - 2, values[field].length - 1) === '"') {
                        values[field] = values[field].slice(0, values[field].length - 2)
                    }
                }

                const obj = csvHeader.reduce((object, header, index) => {
                    object[header] = values[index];
                    return object;
                }, {});
                return obj;

            }).filter(item => item.comments === footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription);

            if (array.filter(item => isNaN(item.consumption)).length > 0 || array.filter(item => isNaN(item.multiplier)).length > 0 || array.filter(item => isNaN(item.emissionFactor)).length > 0 || array.filter(item => isNaN(item.carbonEmissions)).length > 0) {
                window.alert("Invalid CSV file formatting.  Please ensure that all data is formatted in 'General' format")
                return
            }

            if (!lengthCheck) {
                window.alert("Invalid CSV file formatting.  Please ensure that the CSV file headers are present as follows:\nfootprintSubItemId - description - supplier - facility - individual - fromPostCode - toPostCode - transportMode - consumption - multiplier - unit - emissionFactor - carbonEmissions - comments - emissionFactorSource - multiplierFactorSource")
                return
            }

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            //Reduce to itemSubCategory and sum carbonEmissions
            var result = [];
            // if (footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemSubCategory.substring(0,3)==="3.1"){  //Purchased Goods and Services
            //     array.reduce(function (res, value) {
            //         if (!res[value.description]) {
            //             res[value.description] = {
            //                 description: value.description,
            //                 supplier: value.supplier,
            //                 facility: value.facility,
            //                 individual: value.individual,
            //                 fromPostCode: value.fromPostCode,
            //                 toPostCode: value.toPostCode,
            //                 transportMode: value.transportMode,
            //                 consumption: 0,
            //                 multiplier: value.multiplier,
            //                 unit: value.unit,
            //                 emissionFactor: value.emissionFactor,
            //                 carbonEmissions: value.carbonEmissions,
            //                 comments: value.comments,
            //                 emissionFactorSource: value.emissionFactorSource,
            //             };
            //             result.push(res[value.description]);
            //         }
            //         res[value.description].consumption = res[value.description].consumption +
            //             Number(value.consumption);
            //         return res;
            //     }, {});
            // }else{
                // array.reduce(function (res, value) {
                //     if (!res[value.description]) {
                //         res[value.description] = {
                //             description: value.description,
                //             supplier: value.supplier,
                //             facility: value.facility,
                //             individual: value.individual,
                //             fromPostCode: value.fromPostCode,
                //             toPostCode: value.toPostCode,
                //             transportMode: value.transportMode,
                //             consumption: value.consumption,
                //             multiplier: value.multiplier,
                //             unit: value.unit,
                //             emissionFactor: value.emissionFactor,
                //             carbonEmissions: value.carbonEmissions,
                //             comments: value.comments,
                //             emissionFactorSource: value.emissionFactorSource,
                //         };
                //         result.push(res[value.description]);
                //     }
                //     return res;
                // }, {});
            //     result = array
            // }

            result = array

            if (footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemSubCategory.substring(0,3)==="3.1"){  //Purchased Goods and Services
                result = result.filter(item => item.consumption !== 0)
            }

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            //code here to generate uniqueIds for each line
            var subItemIndex, checkWord

            for (subItemIndex = 0; subItemIndex < result.length; subItemIndex++) {
                result[subItemIndex].id = uuidv4()
                result[subItemIndex].consumption = Number(result[subItemIndex].consumption)
                result[subItemIndex].emissionFactor = Number(result[subItemIndex].emissionFactor)
                result[subItemIndex].carbonEmissions = Number(result[subItemIndex].carbonEmissions)

                //Code needed here to add footprintId, organisationId, subCategory, year
                result[subItemIndex] = { ...result[subItemIndex], footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory, footprintItemId: match.params.footprintItemId }

                for (checkWord = 0; checkWord < keyWordsCheck.length; checkWord++) {

                    if (result[subItemIndex].description.toLowerCase().includes(keyWordsCheck[checkWord].keyWord.toLowerCase()) && footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.1 Purchased Goods & Services") {
                        //USE THE FIRST ITEMDESCRIPTION WITH THAT SUBCATEGORY (NOT JUST THE SUBCATEGORY ITSELF)
                        if (footprint.organisationFootprintItems.filter(footprintItem => footprintItem.itemSubCategory === keyWordsCheck[checkWord].subCategory).length > 0) {
                            result[subItemIndex].comments = footprint.organisationFootprintItems.filter(footprintItem => footprintItem.itemSubCategory === keyWordsCheck[checkWord].subCategory)[0].itemDescription
                        } else {
                            result[subItemIndex].comments = keyWordsCheck[checkWord].subCategory
                        }
                    }

                }

                if (result[subItemIndex].consumption < 0) {
                    result[subItemIndex].comments = "#CHECK"
                }

                if (carbonEmissionFactors.find(item => item.id === result[subItemIndex].emissionFactorSource.slice(0,24))) {

                    tempCarbonFactorFromDatabase = carbonEmissionFactors.find(item => item.id === result[subItemIndex].emissionFactorSource.slice(0,24)).GHGConversion
    
                    if (result[subItemIndex].emissionFactorSource.includes("Inflation Adjusted")) {
                        startOfRateNumber = result[subItemIndex].emissionFactorSource.indexOf("at rate:") + 8
                        endOfRateNumber = result[subItemIndex].emissionFactorSource.indexOf("%)")
                        inflationRateUsed = result[subItemIndex].emissionFactorSource.slice(startOfRateNumber, endOfRateNumber)
                        factorDBYear = carbonEmissionFactors.find(item => item.id === result[subItemIndex].emissionFactorSource.slice(0,24)).Year
                        tempCarbonFactorFromDatabase = (tempCarbonFactorFromDatabase / FV(inflationRateUsed/100, Number(footprint.organisationFootprintYear)-Number(factorDBYear), 0, 1, 0)).toFixed(5) * -1
                    }
    
                    if (Number(result[subItemIndex].emissionFactor).toFixed(5) !== Number(tempCarbonFactorFromDatabase).toFixed(5)) {
                        result[subItemIndex].comments = "#CHECK"
                    }
    
                }

                // for (checkWord = 0; checkWord < keyWordsCheckExclude.length; checkWord++) {

                //     if (result[subItemIndex].description.toLowerCase().includes(keyWordsCheckExclude[checkWord].toLowerCase()) && footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.1 Purchased Goods & Services") {
                //         result[subItemIndex].comments = "#CHECK EXCLUDE"
                //     }

                // }

            }

            var newSubItems
            // var newFootprint

            //newFootprint = { ...footprint }

            newSubItems = subItems.concat(result)

            // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems = newFootprintSubItems

            setSelectedComments(aggregateCommentsArray(newSubItems).map((item) => {
                return {
                    label: item.comments,
                    value: item.comments
                }
            }))

            setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                return {
                    comments: item.comments
                }
            }))

            setDescriptionsToSelect(aggregateDescriptionsArray(newSubItems).map((item) => {
                return {
                    label: item.description,
                    value: item.description
                }
            }))

            setSelectedSuppliers(aggregateSuppliersArray(newSubItems).map((item) => {
                return {
                    label: item.supplier,
                    value: item.supplier
                }
            }))

            setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                return {
                    supplier: item.supplier
                }
            }))
    
            setSelectedFacilities(aggregateFacilitiesArray(newSubItems).map((item) => {
                return {
                    label: item.facility,
                    value: item.facility
                }
            }))

            setSelectedTransportModes(aggregateTransportModesArray(newSubItems).map((item) => {
                return {
                    label: item.transportMode,
                    value: item.transportMode
                }
            }))

            setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                return {
                    facility: item.facility
                }
            }))

            setTransportModesToSelect(aggregateTransportModesArray(newSubItems).map((item) => {
                return {
                    transportMode: item.transportMode
                }
            }))

            setSelectedUnits(aggregateUnitsArray(newSubItems).map((item) => {
                return {
                    label: item.unit,
                    value: item.unit
                }
            }))

            setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                return {
                    unit: item.unit
                }
            }))
    
            setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    label: item.emissionFactorSource,
                    value: item.emissionFactorSource
                }
            }))

            setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    emissionFactorSource: item.emissionFactorSource
                }
            }))

            setSubItems(newSubItems)

            window.alert("Pulled in " + result.length + " records")

        }

    };

    //below line of code coudl be useful if we want to dynamically handle different column names (keys)
    // const headerKeys = Object.keys(Object.assign({}, ...array));

    const viewCarbonFactors = () => {
        setShowModal(true)
        setMultiplierSelection(false)
    }

    const viewCarbonFactorsForMultiplier = () => {
        setShowModal(true)
        setMultiplierSelection(true)
    }

    const setSelectedFactor = (e, datasource) => {
        setEmissionFactorToAdd(e)
        setEmissionFactorSourceToAdd(datasource)
    }

    const setSelectedMultiplier = (e, datasource) => {
        setMultiplierToAdd(e)
        setMultiplierFactorSourceToAdd(datasource)
    }

    const setSubItemsToXeroData = (e) => {

        setSubItems(e)

        setSelectedComments(aggregateCommentsArray(e).map((item) => {
            return {
                label: item.comments,
                value: item.comments
            }
        }))

        setSelectedSuppliers(aggregateSuppliersArray(e).map((item) => {
            return {
                label: item.supplier,
                value: item.supplier
            }
        }))

        setSelectedFacilities(aggregateFacilitiesArray(e).map((item) => {
            return {
                label: item.facility,
                value: item.facility
            }
        }))

        setSelectedTransportModes(aggregateTransportModesArray(e).map((item) => {
            return {
                label: item.transportMode,
                value: item.transportMode
            }
        }))

        setSelectedUnits(aggregateUnitsArray(e).map((item) => {
            return {
                label: item.unit,
                value: item.unit
            }
        }))

        setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(e).map((item) => {
            return {
                label: item.emissionFactorSource,
                value: item.emissionFactorSource
            }
        }))

    }

    const resetFilters = () => {

        setSelectedComments(aggregateCommentsArray(subItems).map((item) => {
            return {
                label: item.comments,
                value: item.comments
            }
        }))

        setSelectedSuppliers(aggregateSuppliersArray(subItems).map((item) => {
            return {
                label: item.supplier,
                value: item.supplier
            }
        }))

        setSelectedFacilities(aggregateFacilitiesArray(subItems).map((item) => {
            return {
                label: item.facility,
                value: item.facility
            }
        }))

        setSelectedTransportModes(aggregateTransportModesArray(subItems).map((item) => {
            return {
                label: item.transportMode,
                value: item.transportMode
            }
        }))

        setSelectedUnits(aggregateUnitsArray(subItems).map((item) => {
            return {
                label: item.unit,
                value: item.unit
            }
        }))

        setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(subItems).map((item) => {
            return {
                label: item.emissionFactorSource,
                value: item.emissionFactorSource
            }
        }))

        setSearchText("")
        setSearchTextConsumption("")
        setSearchTextMultiplier("")
        setSearchTextEmissionFactor("")
        setSearchTextEmissions("")
        setSearchTextFromPostCode("")
        setSearchTextToPostCode("")


    }

    const editFootprintSubItem = (e) => {

        setEditToggle(true)
        setEditSelectedToggle(false)
        setDescriptionToAdd(e.description)
        descriptionInput.current.value = e.description
        setSupplierToAdd(e.supplier)
        supplierInput.current.value = e.supplier
        setFacilityToAdd(e.facility)
        facilityInput.current.value = e.facility
        setIndividualToAdd(e.individual)
        setFromPostCodeToAdd(e.fromPostCode)
        setToPostCodeToAdd(e.toPostCode)
        setFormattedConsumption(e.consumption)
        setMultiplierToAdd(e.multiplier)
        setUnitToAdd(e.unit)
        setSelectedTransportModeToAdd({value: e.transportMode, label: e.transportMode} )
        setEmissionFactorToAdd(e.emissionFactor)
        setFormattedEmissions(e.carbonEmissions)
        setCommentsToAdd(e.comments)
        setEmissionFactorSourceToAdd(e.emissionFactorSource)
        setMultiplierFactorSourceToAdd(e.multiplierFactorSource)
        setSubItemIdToAdd(e.id)
        descriptionInput.current.focus()

    }

    const editSelectedFootprintSubItems = () => {

        if (subItems.filter(item => item.individual === "SELECTED").length === 1) {
            setEditSelectedToggle(false)
            setEditToggle(true)
            setDescriptionToAdd(subItems.filter(item => item.individual === "SELECTED")[0].description)
            descriptionInput.current.value = subItems.filter(item => item.individual === "SELECTED")[0].description
            setSupplierToAdd(subItems.filter(item => item.individual === "SELECTED")[0].supplier)
            supplierInput.current.value = subItems.filter(item => item.individual === "SELECTED")[0].supplier
            setFacilityToAdd(subItems.filter(item => item.individual === "SELECTED")[0].facility)
            facilityInput.current.value = subItems.filter(item => item.individual === "SELECTED")[0].facility
            // setIndividualToAdd(subItems.filter(item => item.individual === "SELECTED")[0].individual)
            setFromPostCodeToAdd(subItems.filter(item => item.individual === "SELECTED")[0].fromPostCode)
            setToPostCodeToAdd(subItems.filter(item => item.individual === "SELECTED")[0].toPostCode)
            setFormattedConsumption(subItems.filter(item => item.individual === "SELECTED")[0].consumption)
            setMultiplierToAdd(subItems.filter(item => item.individual === "SELECTED")[0].multiplier)
            setUnitToAdd(subItems.filter(item => item.individual === "SELECTED")[0].unit)
            setSelectedTransportModeToAdd({value: subItems.filter(item => item.individual === "SELECTED")[0].transportMode, label: subItems.filter(item => item.individual === "SELECTED")[0].transportMode} )
            setEmissionFactorToAdd(subItems.filter(item => item.individual === "SELECTED")[0].emissionFactor)
            setFormattedEmissions(subItems.filter(item => item.individual === "SELECTED")[0].carbonEmissions)
            setCommentsToAdd(subItems.filter(item => item.individual === "SELECTED")[0].comments)
            setEmissionFactorSourceToAdd(subItems.filter(item => item.individual === "SELECTED")[0].emissionFactorSource)
            setMultiplierFactorSourceToAdd(subItems.filter(item => item.individual === "SELECTED")[0].multiplierFactorSource)
            setSubItemIdToAdd(subItems.filter(item => item.individual === "SELECTED")[0].id)
            commentsInput.current.focus()
        } else {
            setEditSelectedToggle(true)
            setEditToggle(false)
            setCommentsToAdd("<CURRENT VALUE>")
            setFacilityToAdd("<CURRENT VALUE>")
            facilityInput.current.value = "<CURRENT VALUE>"
            setEmissionFactorToAdd(-99999)
            setEmissionFactorSourceToAdd("<CURRENT VALUE>")
            setDescriptionToAdd("")
            descriptionInput.current.value = ""
            setSupplierToAdd("")
            supplierInput.current.value = ""
            setConsumptionToAdd(0)
            setMultiplierToAdd(-99999)
            setUnitToAdd("")
            setCarbonEmissionsToAdd(0)
            setFromPostCodeToAdd("")
            setToPostCodeToAdd("")
            setSelectedTransportModeToAdd({value: "", label: ""})
            setMultiplierFactorSourceToAdd("<CURRENT VALUE>")

            commentsInput.current.focus()
        }

    }

    const moveFootprintSubItem = (e) => {

        var subItemIndex

        var newSubItems = [...subItems]

        newSubItems.find(item => item.id === e.id).footprintItemId = footprint.organisationFootprintItems.find(item => item.itemDescription === e.comments).id
        newSubItems.find(item => item.id === e.id).subCategory = footprint.organisationFootprintItems.find(item => item.itemDescription === e.comments).itemSubCategory

        for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
            newSubItems[subItemIndex].individual = ""
        }

        setSubItems(newSubItems)

        setDescriptionsToSelect(aggregateDescriptionsArray(newSubItems).map((item) => {
            return {
                label: item.description,
                value: item.description
            }
        }))

        setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
            return {
                supplier: item.supplier
            }
        }))

        setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
            return {
                facility: item.facility
            }
        }))

        setTransportModesToSelect(aggregateTransportModesArray(newSubItems).map((item) => {
            return {
                transportMode: item.transportMode
            }
        }))

        setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
            return {
                unit: item.unit
            }
        }))

        setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
            return {
                emissionFactorSource: item.emissionFactorSource
            }
        }))

        setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
            return {
                comments: item.comments
            }
        }))

    }

    const moveSelectedFootprintSubItems = () => {

        if (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified === "Compliant") {

            window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

        } else {

            var subItemIndex

            var newSubItems = [...subItems]

            var selectedItems = newSubItems.filter(item => item.individual === "SELECTED")

            for (subItemIndex = 0; subItemIndex < selectedItems.length; subItemIndex++) {

                selectedItems[subItemIndex].footprintItemId = footprint.organisationFootprintItems.find(item => item.itemDescription === selectedItems[subItemIndex].comments).id
                selectedItems[subItemIndex].subCategory = footprint.organisationFootprintItems.find(item => item.itemDescription === selectedItems[subItemIndex].comments).itemSubCategory
            
            }

            for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
                newSubItems[subItemIndex].individual = ""
            }

            setSubItems(newSubItems)

        }

    }

    const cancelAddOrEditSubItem = (e) => {

        setDescriptionToAdd("")
        descriptionInput.current.value = ""
        setSupplierToAdd("")
        supplierInput.current.value = ""
        setFacilityToAdd("")
        facilityInput.current.value = ""
        setIndividualToAdd("")
        setFromPostCodeToAdd("")
        setToPostCodeToAdd("")
        setFormattedConsumption(0)
        setMultiplierToAdd(1)
        setUnitToAdd("")
        setSelectedTransportModeToAdd({value: "", label: ""})
        setEmissionFactorToAdd(0)
        setFormattedEmissions(0)
        setCommentsToAdd(footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription)
        setEmissionFactorSourceToAdd("")
        setMultiplierFactorSourceToAdd("")
        setEditToggle(false)
        setEditSelectedToggle(false)
    }

    // const calculateAllDistances = async () => {

    //     //loop through all records in subItems

    //     var fromPostcodeCoordinatesForSubItem
    //     var toPostcodeCoordinatesForSubItem
    //     var subItemIndex
    //     var newFootprint

    //     newFootprint = { ...footprint }

    //     for (subItemIndex = 0; subItemIndex < newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.length; subItemIndex++) {

    //         console.log("Entered loop")

    //         // console.log(newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].fromPostCode)

    //         await instance.get('https://nominatim.openstreetmap.org/search.php?q=' + newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].fromPostCode + '&format=jsonv2')
    //             .then((res) => {
    //                 if (res.data[0]) {
    //                     fromPostcodeCoordinatesForSubItem = res.data[0].lon + ',' + res.data[0].lat
    //                 }
    //             })

    //         await instance.get('https://nominatim.openstreetmap.org/search.php?q=' + newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].toPostCode + '&format=jsonv2')
    //             .then((res) => {
    //                 if (res.data[0]) {
    //                     toPostcodeCoordinatesForSubItem = res.data[0].lon + ',' + res.data[0].lat
    //                 }
    //             })

    //         await instance.get(`https://router.project-osrm.org/route/v1/driving/${fromPostcodeCoordinatesForSubItem};${toPostcodeCoordinatesForSubItem}?overview=false`)
    //             .then((res) => {
    //                 newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex] = { ...newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex], consumption: res.data.routes[0].distance / 1000 }
    //             })

    //     }

    //     console.log("exited loop")

    //     setFootprint(newFootprint)

    // }

    const calculateAllEmissionFactors = async () => {

        //loop through all records in subItems

        console.log("Entered Function")

        var emissionFactor
        var carbonEmissions
        var modeOfTransport
        var unit
        var subItemIndex
        var newSubItems
        var multiplier
        var consumption

        newSubItems = [ ...subItems ]

        for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {

            modeOfTransport = newSubItems[subItemIndex].transportMode
            unit = newSubItems[subItemIndex].unit
            multiplier = newSubItems[subItemIndex].multiplier
            consumption = newSubItems[subItemIndex].consumption

            emissionFactor = carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === modeOfTransport && item.UOMsimple === unit).GHGConversion
            carbonEmissions = emissionFactor * multiplier * consumption


            newSubItems[subItemIndex].emissionFactor = emissionFactor
            newSubItems[subItemIndex].carbonEmissions = carbonEmissions

        }

        console.log("exited loop")

        setSubItems(newSubItems)

    }

    const calculateAllEmissions = async () => {

        //loop through all records in subItems

        console.log("Entered Function")

        var emissionFactor
        var carbonEmissions
        var subItemIndex
        var newSubItems
        var multiplier
        var consumption

        newSubItems = [ ...subItems ]

        for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {

            multiplier = newSubItems[subItemIndex].multiplier
            consumption = newSubItems[subItemIndex].emissionFactor
            emissionFactor = newSubItems[subItemIndex].consumption

            carbonEmissions = emissionFactor * multiplier * consumption

            newSubItems[subItemIndex].carbonEmissions = carbonEmissions

        }

        console.log("exited loop")

        setSubItems(newSubItems)

    }

    const onSubmit = async () => {

        if (!isNaN(subItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0))) {

            setSaving(true)

            var newFootprint

            await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
            .then((res) => {

                newFootprint = res.data
                setFootprint(res.data)

            })
            .catch((err) => {
                console.log(err);
            })

            if (subItems.length > 0 || subItemsToDelete.length > 0) {

                var oneMonthsActual
                var numberOfMonths

                //Code here to add to emissions and consumption of footrpintIds that subItems are being moved to
                //**********************************************************************************************
                var footprintItemInstance
                var listOfFootprintItems = aggregateFootprintItemIdsArray(subItems.filter(item => item.footprintItemId !== match.params.footprintItemId))

                for (footprintItemInstance = 0; footprintItemInstance < listOfFootprintItems.length; footprintItemInstance++) {

                    if (!newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).itemDescription.includes("Market Based")) {
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions + subItems.filter(item => item.footprintItemId === listOfFootprintItems[footprintItemInstance].footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).unit = "various"
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).emissionFactor = 1
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).consumption = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions
        
                        oneMonthsActual = 0
                        numberOfMonths = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).numberOfMonths
        
                        if (numberOfMonths < 12) {
                            oneMonthsActual = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions / numberOfMonths
                            newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).additionalEstimatedEmissions = (oneMonthsActual * (12 - numberOfMonths))
                        } else {
                            newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).additionalEstimatedEmissions = 0
                        }

                    } else {
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions = 0
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).emissionFactor = 0
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).unit = "kgCO2e"
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).consumption = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).consumption + subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                    }

                }
                //**********************************************************************************************


                if (!newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription.includes("Market Based")) {
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit = "various"
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor = 1
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)

                    oneMonthsActual = 0
                    numberOfMonths = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).numberOfMonths

                    if (numberOfMonths < 12) {
                        oneMonthsActual = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions / numberOfMonths
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = (oneMonthsActual * (12 - numberOfMonths))
                    } else {
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = 0
                    }
                } else {
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions = 0
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor = 0
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit = "kgCO2e"
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                }

                updateForm(newFootprint).then(res => { if (window.history.state.idx > 1) {

                    navigate(-1)
        
                } else {
        
                    window.close()
                } })

            } else {

                updateForm(newFootprint).then(res => { if (window.history.state.idx > 1) {

                    navigate(-1)
        
                } else {
        
                    window.close()
                } })
            }

        } else {

            window.alert("Unable to import data.  Please review imported data for invalid data items - Tip: Search the data shown below for NaN data items and remove any carriage returns within data fields and address any non-numeric formatting in the source CSV.")

        }

    }

    const saveOnly = async () => {

        if (!isNaN(subItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0))) {

            setSaving(true)

            var newFootprint

            await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
            .then((res) => {

                newFootprint = res.data
                setFootprint(res.data)

            })
            .catch((err) => {
                console.log(err);
            })

            if (subItems.length > 0 || subItemsToDelete.length > 0) {

                var oneMonthsActual
                var numberOfMonths

                //Code here to add to emissions and consumption of footrpintIds that subItems are being moved to
                //**********************************************************************************************
                var footprintItemInstance
                var listOfFootprintItems = aggregateFootprintItemIdsArray(subItems.filter(item => item.footprintItemId !== match.params.footprintItemId))

                for (footprintItemInstance = 0; footprintItemInstance < listOfFootprintItems.length; footprintItemInstance++) {
                    if (!newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).itemDescription.includes("Market Based")) {
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions + subItems.filter(item => item.footprintItemId === listOfFootprintItems[footprintItemInstance].footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).unit = "various"
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).emissionFactor = 1
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).consumption = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions
        
                        oneMonthsActual = 0
                        numberOfMonths = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).numberOfMonths
        
                        if (numberOfMonths < 12) {
                            oneMonthsActual = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions / numberOfMonths
                            newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).additionalEstimatedEmissions = (oneMonthsActual * (12 - numberOfMonths))
                        } else {
                            newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).additionalEstimatedEmissions = 0
                        }
                    } else {
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).carbonEmissions = 0
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).emissionFactor = 0
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).unit = "kgCO2e"
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).consumption = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === listOfFootprintItems[footprintItemInstance].footprintItemId).consumption + subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                    }

                }
                //**********************************************************************************************

                if (!newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription.includes("Market Based")) {
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit = "various"
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor = 1
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)

                    oneMonthsActual = 0
                    numberOfMonths = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).numberOfMonths

                    if (numberOfMonths < 12) {
                        oneMonthsActual = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions / numberOfMonths
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = (oneMonthsActual * (12 - numberOfMonths))
                    } else {
                        newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = 0
                    }
                } else {
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions = 0
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor = 0
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit = "kgCO2e"
                    newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = subItems.filter(item => item.footprintItemId === match.params.footprintItemId).map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                }

                await updateForm(newFootprint)

                setSubItems(subItems.filter(item => item.footprintItemId === match.params.footprintItemId))

            } else {

                await updateForm(newFootprint)
            
            }

        } else {

            window.alert("Unable to import data.  Please review imported data for invalid data items - Tip: Search the data shown below for NaN data items and remove any carriage returns within data fields and address any non-numeric formatting in the source CSV.")

        }
    }

    const saveAndNavigate = async (direction) => {

        // if (!isNaN(subItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0))) {

            setSubItemsLoading(true)
            var newFootprint

            await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
            .then((res) => {

                newFootprint = res.data
                setFootprint(res.data)

            })
            .catch((err) => {
                console.log(err);
            })

            var footprintItemList = newFootprint.organisationFootprintItems.sort((item1, item2) => Number(item1.itemCategory) < Number(item2.itemCategory) ? -1 : Number(item1.itemCategory) > Number(item2.itemCategory) ? 1 : Number(item1.itemSubCategory.slice(2,4)) < Number(item2.itemSubCategory.slice(2,4)) ? -1 : Number(item1.itemSubCategory.slice(2,4)) > Number(item2.itemSubCategory.slice(2,4)) ? 1 : item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0)
            var currentFootprintItemIndex = footprintItemList.findIndex(item => item.id === match.params.footprintItemId)
            
                // await saveOnly()

                if (direction === "Prev" && currentFootprintItemIndex > 0) {

                    navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${footprintItemList[currentFootprintItemIndex -1].id}`, { replace: true })

                }

                if (direction === "Next" && currentFootprintItemIndex < footprintItemList.length -1) {

                    navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${footprintItemList[currentFootprintItemIndex + 1].id}`, { replace: true })

                }


        setFirstRecord(0)
        setLastRecord(100)
        setSubItemsLoading(false)
    }

    const updateForm = async (form) => {

        if (subItems.filter(item => item.id.length !== 24).length > 0) {
            await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems', subItems.filter(item => item.id.length !== 24))
        }
        if (subItemsToDelete.length > 0) {
            await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems_delete', {"subitems" : subItemsToDelete})
        }
        if (subItems.filter(item => item.id.length === 24).length > 0) {
            await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems', subItems.filter(item => item.id.length === 24))
        }

        //Need to do a final check here 
        //If the user hasn't refreshed and there are other subItems now in the DB that another user has added
        //Then this total can get updated incorrectly
        //Get all the subItems related to this footprint item and update the emissions
        
        await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems/' + match.params.footprintItemId)
        .then((res) => {
            setSubItems(res.data)
            if(!form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription.includes("Market Based")) {
                form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions = res.data.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = res.data.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }
            axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, form)
            setFootprint({...form})
            axios.post(process.env.REACT_APP_API_URL + "/log", {message: AuthService.getCurrentUserName() + " Saved Sub Items for Footprint: " + match.params.footprintId + ", Footprint Item: " + match.params.footprintItemId + " Sub Items total: " + res.data.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) + "; Item Level total: " + form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions})
        
        })
        .catch((err) => {
            console.log(err);
        })

        setReloadDataToggle(true)

        window.alert("Footprint Sub Items Saved")

        setSaving(false)

    }

    const onClose = () => {


        if (window.history.state.idx > 1) {

            navigate(-1)
            // navigate(`/ViewOrganisationFootprint/${match.params.organisationId}/${match.params.footprintId}`, { replace: true })

        } else {

            window.close()
        }

    }

    const addFootprintSubItem = () => {

        if (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified === "Compliant") {

            window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

        } else {

            if(descriptionToAdd !== "") {

                var uniqueId = uuidv4()

                var newSubItems = [...subItems ]

                if (carbonEmissionFactors.find(item => item.id === emissionFactorSourceToAdd.slice(0,24))) {

                    var tempCarbonFactorFromDatabase = carbonEmissionFactors.find(item => item.id === emissionFactorSourceToAdd.slice(0,24)).GHGConversion
    
                    if (emissionFactorSourceToAdd.includes("Inflation Adjusted")) {
                        var startOfRateNumber = emissionFactorSourceToAdd.indexOf("at rate:") + 8
                        var endOfRateNumber = emissionFactorSourceToAdd.indexOf("%)")
                        var inflationRateUsed = emissionFactorSourceToAdd.slice(startOfRateNumber, endOfRateNumber)
                        var factorDBYear = carbonEmissionFactors.find(item => item.id === emissionFactorSourceToAdd.slice(0,24)).Year
                        tempCarbonFactorFromDatabase = (tempCarbonFactorFromDatabase / FV(inflationRateUsed/100, Number(footprint.organisationFootprintYear)-Number(factorDBYear), 0, 1, 0)).toFixed(5) * -1
                    }
    
                    if (Number(emissionFactorToAdd).toFixed(5) !== Number(tempCarbonFactorFromDatabase).toFixed(5)) {
                        window.alert("The carbon factor value differs from the stated factor source.  Please re-select the factor to ensure it is correct.")
                        return
                    }
    
                }

                newSubItems.push({ id: uniqueId, description: descriptionToAdd, supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: Number(removeCommas(consumptionToAdd)), multiplier: multiplierToAdd, unit: unitToAdd, emissionFactor: emissionFactorToAdd, carbonEmissions: Number(removeCommas(carbonEmissionsToAdd)), comments: commentsToAdd, emissionFactorSource: emissionFactorSourceToAdd, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory, footprintItemId: match.params.footprintItemId, multiplierFactorSource: multiplierFactorSourceToAdd })

                setSubItems(newSubItems)

                window.alert("Footprint Item Added")

            }
        }

        setSelectedComments(aggregateCommentsArray(newSubItems).map((item) => {
            return {
                label: item.comments,
                value: item.comments
            }
        }))

        setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
            return {
                supplier: item.supplier
            }
        }))

        setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
            return {
                facility: item.facility
            }
        }))

        setTransportModesToSelect(aggregateTransportModesArray(newSubItems).map((item) => {
            return {
                transportMode: item.transportMode
            }
        }))

        setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
            return {
                unit: item.unit
            }
        }))

        setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
            return {
                emissionFactorSource: item.emissionFactorSource
            }
        }))

        setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
            return {
                comments: item.comments
            }
        }))

        setSelectedSuppliers(aggregateSuppliersArray(newSubItems).map((item) => {
            return {
                label: item.supplier,
                value: item.supplier
            }
        }))

        setSelectedFacilities(aggregateFacilitiesArray(newSubItems).map((item) => {
            return {
                label: item.facility,
                value: item.facility
            }
        }))

        setSelectedTransportModes(aggregateTransportModesArray(newSubItems).map((item) => {
            return {
                label: item.transportMode,
                value: item.transportMode
            }
        }))

        setSelectedUnits(aggregateUnitsArray(newSubItems).map((item) => {
            return {
                label: item.unit,
                value: item.unit
            }
        }))

        setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
            return {
                label: item.emissionFactorSource,
                value: item.emissionFactorSource
            }
        }))

    }

    const updateFootprintSubItem = (e) => {

        if (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified === "Compliant") {

            window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

        } else {

            var index

            var subItemIndex

            var newSubItems = [ ...subItems ]

            if (carbonEmissionFactors.find(item => item.id === emissionFactorSourceToAdd.slice(0,24))) {

                var tempCarbonFactorFromDatabase = carbonEmissionFactors.find(item => item.id === emissionFactorSourceToAdd.slice(0,24)).GHGConversion

                if (emissionFactorSourceToAdd.includes("Inflation Adjusted")) {
                    var startOfRateNumber = emissionFactorSourceToAdd.indexOf("at rate:") + 8
                    var endOfRateNumber = emissionFactorSourceToAdd.indexOf("%)")
                    var inflationRateUsed = emissionFactorSourceToAdd.slice(startOfRateNumber, endOfRateNumber)
                    var factorDBYear = carbonEmissionFactors.find(item => item.id === emissionFactorSourceToAdd.slice(0,24)).Year
                    tempCarbonFactorFromDatabase = (tempCarbonFactorFromDatabase / FV(inflationRateUsed/100, Number(footprint.organisationFootprintYear)-Number(factorDBYear), 0, 1, 0)).toFixed(5) * -1
                }

                if (Number(emissionFactorToAdd).toFixed(5) !== Number(tempCarbonFactorFromDatabase).toFixed(5)) {
                    window.alert("The carbon factor value differs from the stated factor source.  Please re-select the factor to ensure it is correct.")
                    return
                }

            }

            index = newSubItems.indexOf(newSubItems.find(item => item.id === subItemIdToAdd))

            newSubItems[index] = { id: subItemIdToAdd, description: descriptionToAdd, supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: Number(removeCommas(consumptionToAdd)), multiplier: multiplierToAdd, unit: unitToAdd, emissionFactor: emissionFactorToAdd, carbonEmissions: Number(removeCommas(carbonEmissionsToAdd)), comments: commentsToAdd, emissionFactorSource: emissionFactorSourceToAdd, footprintId: footprint.id, organisationId: footprint.organisationId, year: footprint.organisationFootprintYear, subCategory: footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory, footprintItemId: match.params.footprintItemId, multiplierFactorSource: multiplierFactorSourceToAdd }

            for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
                newSubItems[subItemIndex].individual = ""
            }
            setSubItems(newSubItems)

            setEditToggle(false)

            setDescriptionToAdd("")
            descriptionInput.current.value = ""
            setSupplierToAdd("")
            supplierInput.current.value = ""
            setFacilityToAdd("")
            facilityInput.current.value = ""
            setIndividualToAdd("")
            setFromPostCodeToAdd("")
            setToPostCodeToAdd("")
            setFormattedConsumption(0)
            setMultiplierToAdd(1)
            setUnitToAdd("")
            setSelectedTransportModeToAdd({value: "", label: ""})
            setEmissionFactorToAdd(0)
            setFormattedEmissions(0)
            setCommentsToAdd(footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription)
            setEmissionFactorSourceToAdd("")

            setDescriptionsToSelect(aggregateDescriptionsArray(newSubItems).map((item) => {
                return {
                    label: item.description,
                    value: item.description
                }
            }).sort((item1, item2) => (item1.description < item2.description) ? -1 : (item1.description > item2.description) ? 1 : 0))

            setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                return {
                    supplier: item.supplier
                }
            }))
    
            setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                return {
                    facility: item.facility
                }
            }))

            setTransportModesToSelect(aggregateTransportModesArray(newSubItems).map((item) => {
                return {
                    transportMode: item.transportMode
                }
            }))
    
            setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                return {
                    unit: item.unit
                }
            }))
    
            setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    emissionFactorSource: item.emissionFactorSource
                }
            }))
    
            setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                return {
                    comments: item.comments
                }
            }))

        }

    }

    const updateSelectedFootprintSubItems = () => {

        if (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified === "Compliant") {

            window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

        } else {

            var subItemIndex

            var newSubItems = [...subItems]

            var selectedItems = newSubItems.filter(item => item.individual === "SELECTED")

            if (emissionFactorToAdd !== -99999 && emissionFactorSourceToAdd === "<CURRENT VALUE>") {
            
                for (subItemIndex = 0; subItemIndex < selectedItems.length; subItemIndex++) {

                    if (carbonEmissionFactors.find(item => item.id === newSubItems[subItemIndex].emissionFactorSource.slice(0,24))) {

                        window.alert("At least one selected sub item has an emission factor assigned from the Vero emission factor database.  These cannot be overriden as they may result in an incorrect factor source being recorded.  Please set these individually or re-select a valid factor from the Vero emission factor database that will apply to all selected sub items")
                        return
                    
                    }
                }

            }

            if (carbonEmissionFactors.find(item => item.id === emissionFactorSourceToAdd.slice(0,24))) {

                var tempCarbonFactorFromDatabase = carbonEmissionFactors.find(item => item.id === emissionFactorSourceToAdd.slice(0,24)).GHGConversion

                if (emissionFactorSourceToAdd.includes("Inflation Adjusted")) {
                    var startOfRateNumber = emissionFactorSourceToAdd.indexOf("at rate:") + 8
                    var endOfRateNumber = emissionFactorSourceToAdd.indexOf("%)")
                    var inflationRateUsed = emissionFactorSourceToAdd.slice(startOfRateNumber, endOfRateNumber)
                    var factorDBYear = carbonEmissionFactors.find(item => item.id === emissionFactorSourceToAdd.slice(0,24)).Year
                    tempCarbonFactorFromDatabase = (tempCarbonFactorFromDatabase / FV(inflationRateUsed/100, Number(footprint.organisationFootprintYear)-Number(factorDBYear), 0, 1, 0)).toFixed(5) * -1
                }

                if (Number(emissionFactorToAdd).toFixed(5) !== Number(tempCarbonFactorFromDatabase).toFixed(5)) {
                    window.alert("The carbon factor value differs from the stated factor source.  Please re-select the factor to ensure it is correct.")
                    return
                }

            }

            for (subItemIndex = 0; subItemIndex < selectedItems.length; subItemIndex++) {

                if (facilityToAdd !== "<CURRENT VALUE>") {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].facility = facilityToAdd

                }

                if (emissionFactorToAdd !== -99999) {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].emissionFactor = emissionFactorToAdd

                }

                if (multiplierToAdd !== -99999) {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].multiplier = multiplierToAdd

                }

                if (emissionFactorSourceToAdd !== "<CURRENT VALUE>") {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].emissionFactorSource = emissionFactorSourceToAdd

                }

                if (multiplierFactorSourceToAdd !== "<CURRENT VALUE>") {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].multiplierFactorSource = multiplierFactorSourceToAdd

                }

                if (commentsToAdd !== "<CURRENT VALUE>") {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].comments = commentsToAdd

                }

                if (emissionFactorToAdd !== -99999) {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].carbonEmissions = emissionFactorToAdd * newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].multiplier * newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].consumption
                
                }

                if (multiplierToAdd !== -99999) {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].carbonEmissions = newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].emissionFactor * multiplierToAdd * newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].consumption
                
                }

                if (commentsToAdd === "RECYCLE BIN") {

                    newSubItems.filter(item => item.individual === "SELECTED")[subItemIndex].carbonEmissions = 0

                }

            }

            for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
                newSubItems[subItemIndex].individual = ""
            }

            setSubItems(newSubItems)

            setDescriptionsToSelect(aggregateDescriptionsArray(newSubItems).map((item) => {
                return {
                    label: item.description,
                    value: item.description
                }
            }).sort((item1, item2) => (item1.description < item2.description) ? -1 : (item1.description > item2.description) ? 1 : 0))

            setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                return {
                    supplier: item.supplier
                }
            }))
    
            setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                return {
                    facility: item.facility
                }
            }))

            setTransportModesToSelect(aggregateTransportModesArray(newSubItems).map((item) => {
                return {
                    transportMode: item.transportMode
                }
            }))
    
            setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                return {
                    unit: item.unit
                }
            }))
    
            setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    emissionFactorSource: item.emissionFactorSource
                }
            }))
    
            setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                return {
                    comments: item.comments
                }
            }))

            setEditSelectedToggle(false)

            setDescriptionToAdd("")
            descriptionInput.current.value = ""
            setSupplierToAdd("")
            supplierInput.current.value = ""
            setFacilityToAdd("")
            facilityInput.current.value = ""
            setIndividualToAdd("")
            setFromPostCodeToAdd("")
            setToPostCodeToAdd("")
            setFormattedConsumption(0)
            setMultiplierToAdd(1)
            setUnitToAdd("")
            setSelectedTransportModeToAdd({value: "", label: ""})
            setEmissionFactorToAdd(0)
            setFormattedEmissions(0)
            setCommentsToAdd(footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription)
            //setCommentsToAdd("")
            setEmissionFactorSourceToAdd("")
            setMultiplierFactorSourceToAdd("")

        }

    }

    const cloneFootprintSubItem = async (organisationFootprintSubItem) => {

        //Code here to clone the footprintSubItem

        var newSubItems = [ ...subItems ]

        newSubItems.push({...organisationFootprintSubItem, footprintSubItemId: uuidv4(), id: uuidv4()})

        setSubItems(newSubItems)
    
    }

    const cloneSelectedFootprintSubItems = async () => {

        //Code here to clone the footprintSubItems

        var subItemIndex

        var newSubItems = [...subItems]

        var selectedItems = newSubItems.filter(item => item.individual === "SELECTED")

        for (subItemIndex = 0; subItemIndex < selectedItems.length; subItemIndex++) {

            newSubItems.push({...selectedItems[subItemIndex], footprintSubItemId: uuidv4(), id: uuidv4()})

        }

        setSubItems(newSubItems)
    
    }

    const deleteFootprintSubItem = (id) => {

        if (window.confirm('Are you sure you want to delete this sub item?')) {

            if (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified === "Compliant") {

                window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

            } else {

                var newSubItems = [ ...subItems ]

                if (id.length === 24) {
                    setSubItemsToDelete([ ...subItemsToDelete, id ])
                }

                setSubItems(newSubItems.filter((item) => item.id !== id))

                setDescriptionsToSelect(aggregateDescriptionsArray(newSubItems.filter((item) => item.id !== id)).map((item) => {
                    return {
                        label: item.description,
                        value: item.description
                    }
                }))

                setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                    return {
                        supplier: item.supplier
                    }
                }))
        
                setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                    return {
                        facility: item.facility
                    }
                }))

                setTransportModesToSelect(aggregateTransportModesArray(newSubItems).map((item) => {
                    return {
                        transportMode: item.transportMode
                    }
                }))
        
                setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                    return {
                        unit: item.unit
                    }
                }))
        
                setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                    return {
                        emissionFactorSource: item.emissionFactorSource
                    }
                }))
        
                setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                    return {
                        comments: item.comments
                    }
                }))

            }

        }

    }

    const deleteAllFootprintSubItems = () => {

        if (window.confirm('Are you sure you want to delete all sub items?')) {

            if (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified === "Compliant") {

                window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

            } else {

                var subItemIndex
                var newSubItems = [ ...subItems ]
                var selectedSubItemsToDelete = []

                for (subItemIndex = 0; subItemIndex < newSubItems.filter((item) => item.id.length === 24).length; subItemIndex++) {

                    selectedSubItemsToDelete.push(newSubItems.filter((item) => item.id.length === 24)[subItemIndex].id)

                }

                setSubItemsToDelete(subItemsToDelete.concat(selectedSubItemsToDelete))

                newSubItems = [];

                setSubItems(newSubItems)

                setDescriptionsToSelect(aggregateDescriptionsArray(newSubItems).map((item) => {
                    return {
                        label: item.description,
                        value: item.description
                    }
                }))

                setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                    return {
                        supplier: item.supplier
                    }
                }))
        
                setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                    return {
                        facility: item.facility
                    }
                }))

                setTransportModesToSelect(aggregateTransportModesArray(newSubItems).map((item) => {
                    return {
                        transportMode: item.transportMode
                    }
                }))
        
                setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                    return {
                        unit: item.unit
                    }
                }))
        
                setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                    return {
                        emissionFactorSource: item.emissionFactorSource
                    }
                }))
        
                setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                    return {
                        comments: item.comments
                    }
                }))

            }

        }

    }

    const deleteSelectedFootprintSubItems = () => {

        if (window.confirm('Are you sure you want to delete these sub items?')) {


            if (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified === "Compliant") {

                window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

            } else {

                var subItemIndex
                var newSubItems = [ ...subItems ]
                var selectedSubItemsToDelete = []

                for (subItemIndex = 0; subItemIndex < newSubItems.filter((item) => item.id.length === 24 && item.individual === "SELECTED").length; subItemIndex++) {

                    selectedSubItemsToDelete.push(newSubItems.filter((item) => item.id.length === 24 && item.individual === "SELECTED")[subItemIndex].id)

                }

                setSubItemsToDelete(subItemsToDelete.concat(selectedSubItemsToDelete))
                console.log(subItemsToDelete.concat(selectedSubItemsToDelete))

                newSubItems = newSubItems.filter(item => item.individual !== "SELECTED")

                setSubItems(newSubItems)

                setDescriptionsToSelect(aggregateDescriptionsArray(newSubItems).map((item) => {
                    return {
                        label: item.description,
                        value: item.description
                    }
                }))

                setSuppliersToSelect(aggregateSuppliersArray(newSubItems).map((item) => {
                    return {
                        supplier: item.supplier
                    }
                }))
        
                setFacilitiesToSelect(aggregateFacilitiesArray(newSubItems).map((item) => {
                    return {
                        facility: item.facility
                    }
                }))

                setTransportModesToSelect(aggregateTransportModesArray(newSubItems).map((item) => {
                    return {
                        transportMode: item.transportMode
                    }
                }))
        
                setUnitsToSelect(aggregateUnitsArray(newSubItems).map((item) => {
                    return {
                        unit: item.unit
                    }
                }))
        
                setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                    return {
                        emissionFactorSource: item.emissionFactorSource
                    }
                }))
        
                setCommentsToSelect(aggregateCommentsArray(newSubItems).map((item) => {
                    return {
                        comments: item.comments
                    }
                }))

            }

        }

    }

    const switchEmissionFactors = async () => {

        var subItemIndex
        var newSubItems = [ ...subItems ]

        var dataSource, level1, region, year
        var level1Alternative
        var alternativeFactor

        if (selectedDatabases.length === 1) {

            setAnimationSwitch(true)

            await axios.get(process.env.REACT_APP_API_URL + '/carbon_factors_database_mapping')
            
            for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {

                if (carbonEmissionFactors.find(item => item.id === newSubItems[subItemIndex].emissionFactorSource.slice(0,24))) {
                    //check the subItem[i].factorSource id (first 24 chars) and get the Level1 description, year and region
                    dataSource = carbonEmissionFactors.find(item => item.id === newSubItems[subItemIndex].emissionFactorSource.slice(0,24)).DataSource
                    year = selectedDatabases[0].label.slice(-4)
                    level1 = carbonEmissionFactors.find(item => item.id === newSubItems[subItemIndex].emissionFactorSource.slice(0,24)).Level1
                    region = carbonEmissionFactors.find(item => item.id === newSubItems[subItemIndex].emissionFactorSource.slice(0,24)).Region

                    //check if it's already set to a factor from the selected factor database
                    if (dataSource !== selectedDatabases[0].label.slice(0,selectedDatabases[0].label.length-5)) {

                        //Get the corresponding databaseB factor data
                        if (databaseMapping.find(item => item.descriptionA === level1 && item.databaseA === dataSource)) {
                            // console.log("changing TO DB B")
                            level1Alternative = databaseMapping.find(item => item.descriptionA === level1 && item.databaseA === dataSource && item.databaseB === selectedDatabases[0].label.slice(0,selectedDatabases[0].label.length-5)).descriptionB
                            alternativeFactor = carbonEmissionFactors.find(item => item.Level1 === level1Alternative && item.Year === year && item.DataSource === selectedDatabases[0].label.slice(0,selectedDatabases[0].label.length-5))
                            newSubItems[subItemIndex].emissionFactor = (alternativeFactor.GHGConversion / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(year), 0, 1, 0)).toFixed(5) * -1
                            newSubItems[subItemIndex].carbonEmissions = ((alternativeFactor.GHGConversion / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(year), 0, 1, 0)).toFixed(5)) * -1 * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption
                            newSubItems[subItemIndex].emissionFactorSource = alternativeFactor.id + "; " + alternativeFactor.DataSource + "; " + alternativeFactor.Scope + "; " + alternativeFactor.Level1 + "; " + alternativeFactor.Level2 + "; " + alternativeFactor.Level3 + "; " + alternativeFactor.UOMsimple + "; " + alternativeFactor.Year + "; " + alternativeFactor.Region + "; " + alternativeFactor.Comments + "; " + alternativeFactor.ColumnText
                        
                        }

                        if (databaseMapping.find(item => item.descriptionB === level1 && item.databaseB === dataSource)) {
                            // console.log("changing TO DB A")
                            level1Alternative = databaseMapping.find(item => item.descriptionB === level1 && item.databaseB === dataSource && item.databaseA === selectedDatabases[0].label.slice(0,selectedDatabases[0].label.length-5)).descriptionA
                            alternativeFactor = carbonEmissionFactors.find(item => item.Level1 === level1Alternative && item.DataSource === selectedDatabases[0].label.slice(0,selectedDatabases[0].label.length-5))
                            newSubItems[subItemIndex].emissionFactor = (alternativeFactor.GHGConversion / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(year), 0, 1, 0)).toFixed(5) * -1
                            newSubItems[subItemIndex].carbonEmissions = ((alternativeFactor.GHGConversion / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(year), 0, 1, 0)).toFixed(5)) * -1 * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption
                            newSubItems[subItemIndex].emissionFactorSource = alternativeFactor.id + "; " + alternativeFactor.DataSource + "; " + alternativeFactor.Scope + "; " + alternativeFactor.Level1 + "; " + alternativeFactor.Level2 + "; " + alternativeFactor.Level3 + "; " + alternativeFactor.UOMsimple + "; " + alternativeFactor.Year + "; " + alternativeFactor.Region + "; " + alternativeFactor.Comments + "; " + alternativeFactor.ColumnText
                        
                        }
                    }
                
                }

            }

            setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
                return {
                    label: item.emissionFactorSource,
                    value: item.emissionFactorSource
                }
            }))

            setSubItems(newSubItems)
        
        } else if (selectedDatabases.length === 0) {

            window.alert("Please select a carbon factor database")

        } else if (selectedDatabases.length > 1) {

            window.alert("Please select only one carbon factor database")

        }

        setAnimationSwitch(false)
    }

    const automaticallySelectEmissionFactors = async () => {

        if (selectedDatabases.length === 1) {

        //create a full array of keyWordsAndPhrases matched to emissions factor
        var tempDataSource
        var factorIndex, subItemIndex, keyWordOrPhraseIndex
        var allKeyWordsAndPhrasesWithMatchingFactor = []
        var previousYearsSubItems = []
        var newSubItems = [ ...subItems ]
        var subItem
        var subItemList
        var subCategory = footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemSubCategory
        var unit
        var adjustedEmissionFactorSourceText
        var positionOfInflationText
        var originalEmissionFactorFromDB
        var numberOfYears
        var dataSourceToUse, level1ToUse, level2ToUse, level3ToUse, scopeToUse, columnTextToUse, UOMSimpleToUse, regionToUse, idToUse, commentsToUse
        var yearToUse
        var carbonEmissionFactorsFilteredToSelected = carbonEmissionFactors.filter(item => item.DataSource === selectedDatabases[0].value.slice(0,selectedDatabases[0].value.length-5) && item.Year === selectedDatabases[0].value.slice(-4))

        if (window.confirm('Would you like to check data from prior year?')) {
            await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems_dashboard', {"organisations": [match.params.organisationId]})
            .then((res) => {

                if (res.data.filter(item => item.year === (Number(footprint.organisationFootprintYear) - 1).toString())) {

                    setPreviousYearsData(res.data.filter(item => item.year === (Number(footprint.organisationFootprintYear) - 1).toString()))

                    // if there is a previous year create an array of previous year subitems
                    if (res.data.filter(item => item.year === (Number(footprint.organisationFootprintYear) - 1).toString()).length > 0) {
                        previousYearsSubItems = res.data.filter(item => item.year === (Number(footprint.organisationFootprintYear) - 1).toString()).filter(item => item.subCategory === subCategory)
                    }

                }
            
            })
            .catch((err) => {
                console.log(err);
            })
        }

        // if there is a previous year create an array of previous year subitems
        // if (previousYearsData.length > 0) {
        //     previousYearsSubItems = previousYearsData.filter(item => item.subCategory === subCategory)
        // }

        // Key words and phrases from Rob
        // go though factor database and make a list of all keywords or phrases along with UOM, emission factor, year and db source
        for (factorIndex = 0; factorIndex < carbonEmissionFactorsFilteredToSelected.length; factorIndex++) {
            for (keyWordOrPhraseIndex = 0; keyWordOrPhraseIndex < carbonEmissionFactorsFilteredToSelected[factorIndex].keyWordsAndPhrases.length; keyWordOrPhraseIndex++) {
                allKeyWordsAndPhrasesWithMatchingFactor.push({keyWordOrPhrase: carbonEmissionFactorsFilteredToSelected[factorIndex].keyWordsAndPhrases[keyWordOrPhraseIndex], unit: carbonEmissionFactorsFilteredToSelected[factorIndex].UOMsimple, emissionFactor: carbonEmissionFactorsFilteredToSelected[factorIndex].GHGConversion,  emissionFactorSource: carbonEmissionFactorsFilteredToSelected[factorIndex].id + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].DataSource + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Scope + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Level1 + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Level2 + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Level3 + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].UOMsimple + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Year + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Region + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].Comments + "; " + carbonEmissionFactorsFilteredToSelected[factorIndex].ColumnText, year: carbonEmissionFactorsFilteredToSelected[factorIndex].Year })
            }
        }

        // go through every single item in subitems list, for example: each item in 3.1. Purchased goods and services
        for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {

            // Code here to check for key words / phrases match along with its factor 
            for (keyWordOrPhraseIndex = 0; keyWordOrPhraseIndex < allKeyWordsAndPhrasesWithMatchingFactor.length; keyWordOrPhraseIndex++) {

                unit = newSubItems[subItemIndex].unit

                if (newSubItems[subItemIndex].description.toLowerCase().includes(allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].keyWordOrPhrase.toLowerCase()) && newSubItems[subItemIndex].unit === allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].unit) {
                    // // Get emission factor related to that keyword and phrase and pair it with subitem
                    // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].emissionFactor = ((allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].year), 0, 1, 0)).toFixed(5))*-1
                    // // multiply the factor by consumption and get total emission per subitem
                    // newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].carbonEmissions = (((allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].year), 0, 1, 0)).toFixed(5))*-1) * newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].multiplier * newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].consumption 
                    // // Update the data source, add #MAP - means that this carbon factor comes from keyword and phrases mapping
                    if (unit === "GBP" || unit === "EUR" || unit === "USD") {

                        // !!!!!!!!!! Uncomment the below to add alterative factors to the comments field for testing !!!!!!!!!!!!!!!!!!
                        // if (newSubItems[subItemIndex].emissionFactorSource !== "" && newSubItems[subItemIndex].emissionFactorSource !== allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactorSource + "; Inflation Adjusted (at rate: " + inflationFactor * 100 + "%); #MAP") {
                        //     newSubItems[subItemIndex].comments = newSubItems[subItemIndex].comments + "\n" + newSubItems[subItemIndex].emissionFactorSource
                        // }
                        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

                        newSubItems[subItemIndex].emissionFactor = ((allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].year), 0, 1, 0)).toFixed(5))*-1
                        newSubItems[subItemIndex].carbonEmissions = (((allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor / FV(inflationFactor, Number(footprint.organisationFootprintYear)-Number(allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].year), 0, 1, 0)).toFixed(5))*-1) * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption 
                        newSubItems[subItemIndex].emissionFactorSource = allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactorSource + "; Inflation Adjusted (at rate: " + inflationFactor * 100 + "%); #MAP"
                    } else {
                        newSubItems[subItemIndex].emissionFactor = allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor
                        newSubItems[subItemIndex].carbonEmissions = allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactor * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption 
                        newSubItems[subItemIndex].emissionFactorSource = allKeyWordsAndPhrasesWithMatchingFactor[keyWordOrPhraseIndex].emissionFactorSource + "; #MAP"
                    }
                }
            }

            //Code here to check previous years' carbon factors where there is a subItem description match
            //#PRE means factor came from matching description from previous year subItem

            if (previousYearsSubItems.length > 0) {
                // for (subItemList = 0; subItemList < previousYearsSubItems.length; subItemList++) {
                    for (subItem = 0; subItem < previousYearsSubItems.length; subItem++) {
                        // console.log(newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].description.replace(/,/g, ""))
                        if ((newSubItems[subItemIndex].description.toLowerCase().includes(previousYearsSubItems[subItem].description.toLowerCase()) || newSubItems[subItemIndex].description.replace(/,/g, "").toLowerCase().includes(previousYearsSubItems[subItem].description.toLowerCase()) || newSubItems[subItemIndex].supplier.toLowerCase().includes(previousYearsSubItems[subItem].supplier.toLowerCase())) && newSubItems[subItemIndex].unit === previousYearsSubItems[subItem].unit) {
                            if (unit === "GBP" || unit === "EUR" || unit === "USD") {

                                //code here to check previous emissionFactorSource, reselect it from the database and apply the new inflation figure
                                
                                if (carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24))) {

                                    if (selectedDatabases.find(item => item.label === carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).DataSource + " " + carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Year)) {

                                        //IF USER HAS SELECTED THE SAME DATABASE THAT THE PREVIOUSLY USED FACTOR WAS IN THEN
                                        originalEmissionFactorFromDB = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).GHGConversion
                                        numberOfYears = footprint.organisationFootprintYear - carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Year
                                        adjustedEmissionFactorSourceText = previousYearsSubItems[subItem].emissionFactorSource

                                    } else {

                                        //IS THE SAME DATASOURCE (BUT DIFFERENT YEAR) SELECTED?  IF SO USE THAT
                                        if (selectedDatabases.find(item => item.label.slice(0,item.label.length-5) === carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).DataSource)) {
                                        
                                            dataSourceToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).DataSource
                                            yearToUse = selectedDatabases.find(item => item.label.slice(0,item.label.length-5) === carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).DataSource).label.slice(-4)
                                            level1ToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Level1
                                            level2ToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Level2
                                            level3ToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Level3
                                            scopeToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Scope
                                            columnTextToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).ColumnText
                                            UOMSimpleToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).UOMsimple
                                            regionToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Region
                                            idToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).id
                                            commentsToUse = carbonEmissionFactors.find(item => item.id === previousYearsSubItems[subItem].emissionFactorSource.slice(0,24)).Comments


                                            //NEED TO UPDATE THIS TO FILTER TO ALL FIELDS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                            if (carbonEmissionFactors.find(item => item.DataSource === dataSourceToUse && item.Year === yearToUse && item.Level1 === level1ToUse && item.Level2 === level2ToUse && item.Level3 === level3ToUse && item.Scope === scopeToUse && item.UOMsimple === UOMSimpleToUse && item.Region === regionToUse && item.Comments === commentsToUse && item.ColumnText === columnTextToUse)) {
                                                originalEmissionFactorFromDB = carbonEmissionFactors.find(item => item.DataSource === dataSourceToUse && item.Year === yearToUse && item.Level1 === level1ToUse && item.Level2 === level2ToUse && item.Level3 === level3ToUse && item.Scope === scopeToUse && item.UOMsimple === UOMSimpleToUse && item.Region === regionToUse && item.Comments === commentsToUse && item.ColumnText === columnTextToUse).GHGConversion
                                                numberOfYears = footprint.organisationFootprintYear - yearToUse
                                                idToUse = carbonEmissionFactors.find(item => item.DataSource === dataSourceToUse && item.Year === yearToUse && item.Level1 === level1ToUse && item.Level2 === level2ToUse && item.Level3 === level3ToUse && item.Scope === scopeToUse && item.UOMsimple === UOMSimpleToUse && item.Region === regionToUse && item.Comments === commentsToUse && item.ColumnText === columnTextToUse).id
                                                
                                                adjustedEmissionFactorSourceText = idToUse + "; " + dataSourceToUse + "; " + scopeToUse + "; " + level1ToUse + "; " + level2ToUse + "; " + level3ToUse + "; " + UOMSimpleToUse + "; " + yearToUse + "; " + regionToUse + "; " + commentsToUse + "; " + columnTextToUse
                                            } else {

                                                originalEmissionFactorFromDB = 0
                                                numberOfYears = 0
                                                adjustedEmissionFactorSourceText = previousYearsSubItems[subItem].emissionFactorSource
                                            }
                            
                                        } else {

                                            originalEmissionFactorFromDB = 0
                                            numberOfYears = 0
                                            adjustedEmissionFactorSourceText = previousYearsSubItems[subItem].emissionFactorSource
                                        }

                                    }

                                } else {

                                    originalEmissionFactorFromDB = 0
                                    numberOfYears = 0
                                    adjustedEmissionFactorSourceText = previousYearsSubItems[subItem].emissionFactorSource

                                }

                                //Also need to replace any mention of "Inflation Adjusted (at rate: x.xxx%) ;" with " ;"
                                positionOfInflationText = adjustedEmissionFactorSourceText.indexOf("Inflation Adjusted (at rate:")

                                if (positionOfInflationText !== -1) {
                                    adjustedEmissionFactorSourceText = adjustedEmissionFactorSourceText.slice(0, positionOfInflationText -2)
                                } else {
                                    adjustedEmissionFactorSourceText = adjustedEmissionFactorSourceText.slice(0, positionOfInflationText)
                                }

                                if (originalEmissionFactorFromDB !==0) {
                                    newSubItems[subItemIndex].emissionFactor = originalEmissionFactorFromDB / FV(inflationFactor, numberOfYears, 0, 1, 0).toFixed(5)*-1
                                    newSubItems[subItemIndex].carbonEmissions = (originalEmissionFactorFromDB / FV(inflationFactor, numberOfYears, 0, 1, 0).toFixed(5)*-1) * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption 
                                    newSubItems[subItemIndex].emissionFactorSource = adjustedEmissionFactorSourceText + "; Inflation Adjusted (at rate: " + inflationFactor * 100 + "%); #PRE"      
                                }
                            } else {
                                newSubItems[subItemIndex].emissionFactor = previousYearsSubItems[subItem].emissionFactor
                                newSubItems[subItemIndex].carbonEmissions = previousYearsSubItems[subItem].emissionFactor * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption 
                                newSubItems[subItemIndex].emissionFactorSource = previousYearsSubItems[subItem].emissionFactorSource + "; #PRE"
                            }
                        }
                    }
                // }
            }

            // if (newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].emissionFactor === 0 && newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].comments !== "#CHECK" && newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].comments !== "#CHECK EXCLUDE") {
            if ((newSubItems[subItemIndex].emissionFactor === "0" || newSubItems[subItemIndex].emissionFactor === 0) && newSubItems[subItemIndex].comments === footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemDescription) {
                
            
                if ((newSubItems[subItemIndex].unit === "GBP" || newSubItems[subItemIndex].unit === "EUR" || newSubItems[subItemIndex].unit === "USD") && newSubItems[subItemIndex].consumption <= 100 && newSubItems[subItemIndex].consumption > 0 && footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemSubCategory.substring(0,3)==="3.1") {

                    console.log(selectedDatabases[0].value.slice(0,selectedDatabases[0].value.length-5))

                    if (carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === selectedDatabases[0].value.slice(0,selectedDatabases[0].value.length-5))) {

                        tempDataSource = selectedDatabases[0].value.slice(0,selectedDatabases[0].value.length-5)

                    } else {

                        tempDataSource = "SWC MRIO"

                    }

                    dataSourceToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).DataSource
                    yearToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).Year
                    level1ToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).Level1
                    level2ToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).Level2
                    level3ToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).Level3
                    scopeToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).Scope
                    columnTextToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).ColumnText
                    UOMSimpleToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).UOMsimple
                    regionToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).Region
                    idToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).id
                    commentsToUse = carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === tempDataSource).Comments

                    numberOfYears = Number(footprint.organisationFootprintYear) - Number(yearToUse)
        
                    adjustedEmissionFactorSourceText = idToUse + "; " + dataSourceToUse + "; " + scopeToUse + "; " + level1ToUse + "; " + level2ToUse + "; " + level3ToUse + "; " + UOMSimpleToUse + "; " + yearToUse + "; " + regionToUse + "; " + commentsToUse + "; " + columnTextToUse

                    newSubItems[subItemIndex].emissionFactor = (carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").GHGConversion / FV(inflationFactor, numberOfYears, 0, 1, 0)).toFixed(5)*-1
                    newSubItems[subItemIndex].carbonEmissions = ((carbonEmissionFactors.find(item => item.Level1 === "Other Manufacturing" && item.DataSource === "SWC MRIO").GHGConversion / FV(inflationFactor, numberOfYears, 0, 1, 0).toFixed(5))*-1) * newSubItems[subItemIndex].multiplier * newSubItems[subItemIndex].consumption 
                    newSubItems[subItemIndex].emissionFactorSource = adjustedEmissionFactorSourceText + "; Inflation Adjusted (at rate: " + inflationFactor * 100 + "%); #MAP"

                } else {

                    newSubItems[subItemIndex].comments = ""
                }
            
            }

            //Code here to carry out factor selection using AI
            //
            // if((newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].emissionFactorSource.slice(-4))!==("#MAP"||"#PRE"))
                // if last 4 chars are not #MAP or #PRE
                // var desc = newFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].description
                // api call and response await
                
                // assing emissionFactor

        }

        setSelectedComments(aggregateCommentsArray(newSubItems).map((item) => {
            return {
                label: item.comments,
                value: item.comments
            }
        }))

        setSelectedSuppliers(aggregateSuppliersArray(newSubItems).map((item) => {
            return {
                label: item.supplier,
                value: item.supplier
            }
        }))

        setSelectedFacilities(aggregateFacilitiesArray(newSubItems).map((item) => {
            return {
                label: item.facility,
                value: item.facility
            }
        }))

        setSelectedTransportModes(aggregateTransportModesArray(newSubItems).map((item) => {
            return {
                label: item.transportMode,
                value: item.transportMode
            }
        }))

        setSelectedUnits(aggregateUnitsArray(newSubItems).map((item) => {
            return {
                label: item.unit,
                value: item.unit
            }
        }))

        setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
            return {
                label: item.emissionFactorSource,
                value: item.emissionFactorSource
            }
        }))

        setSubItems(newSubItems)

    } else if (selectedDatabases.length === 0) {

        window.alert("Please select a carbon factor database")

    } else if (selectedDatabases.length > 1) {

        window.alert("Please select only one carbon factor database")

    }

    }

    ///////////////////////////////////////////////////////



    ///////////////////////////////////////////////////////

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    // get emission factor and confidence in a form of [factor, conficence_percentage]
    // const getEmissionFactor = async () => {

    //     await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
    //         .then((response) => {
    //             setCompany(response.data.company)
    //             setRole(response.data.role)
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         }).finally(() => {
    //             setUserPropertiesLoading(false);
    //         });

    // }

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const markSubItemAsSelected = (id) => {

        var index

        var newSubItems = [ ...subItems ]

        index = newSubItems.indexOf(newSubItems.find(item => item.id === id))

        if (newSubItems[index].individual !== "SELECTED") {
            newSubItems[index] = { ...newSubItems[index], individual: "SELECTED" }
        } else {
            newSubItems[index] = { ...newSubItems[index], individual: "" }
        }
        setSubItems(newSubItems)

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Xero API Link Code Here

    const [authCode, setAuthCode] = useState('')
    const XeroUserExists = () => {

        //console.log("XeroUserExists")
        axios.get(process.env.REACT_APP_API_URL + '/XeroUserExists')
            .then((res) => {
                if (res.data==="Yes"){ //User exists in tokens collection
                    setShowModalUpdate(true)
                    setValueToSendToModal(match.params.footprintId)
                    setFieldToSendToModal("footprintItemId")
                    setObjectIdToSendToModal(match.params.footprintItemId)
                    setCollectionToSendToModal(footprint)
                } else { //User doesn't exist in the tokens collection
                    XeroFirstAuth()
                }

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                //setLoading(false);
            });

        }

    const XeroFirstAuth = async () => {

        const auth_url = await axios.get(process.env.REACT_APP_API_URL + '/XeroFirstAuth')
        window.alert('Please login to your Xero account on the next screen and click on "Allow Access"')
        let params = `popup=true,left=100,top=100,width=500,height=700`;
        window.open (auth_url.data,"XeroWindow",params);

    }

    // const handleModalClose = async () => {
        
    //     setShowModalUpdate(false)

    //     var newSubItems = [ ...subItems ]
        
    //     setSelectedComments(aggregateCommentsArray(newSubItems).map((item) => {
    //         return {
    //             label: item.comments,
    //             value: item.comments
    //         }
    //     }))

    //     setSelectedDescriptions(newSubItems.map((item) => {
    //         return {
    //             label: item.description,
    //             value: item.description
    //         }
    //     }))

    //     setSelectedSuppliers(aggregateSuppliersArray(newSubItems).map((item) => {
    //         return {
    //             label: item.supplier,
    //             value: item.supplier
    //         }
    //     }))

    //     setSelectedFacilities(aggregateFacilitiesArray(newSubItems).map((item) => {
    //         return {
    //             label: item.facility,
    //             value: item.facility
    //         }
    //     }))

    //     setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(newSubItems).map((item) => {
    //         return {
    //             label: item.emissionFactorSource,
    //             value: item.emissionFactorSource
    //         }
    //     }))

    // }

    useEffect(() => {

        if (showInfoModal===false){

            if (processWhat!==""){
                handlePostcodes()
            }

        }

    }, [showInfoModal])

    useEffect(() => {

        // console.log("useEffectAuthCode")
        // console.log(window.location.href)

        let varURL = window.location.href
        let start_number = varURL.search('code=') + 5
        let end_number = varURL.search('&scope')
        let auth_code = varURL.substring(start_number, end_number)

        setAuthCode(auth_code)

        if (start_number > 5) {
            console.log("Auth Code is: " + auth_code)
        }

    }, [])

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setFirstRecord(0)
            setLastRecord(100)

        setSuppliersToSelect(aggregateSuppliersArray(subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedTransportModes.find((itemB) => {
                return itemA.transportMode === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase())).filter(item => item.consumption.toString().toLowerCase().includes(searchTextConsumption.toLowerCase())).filter(item => item.multiplier.toString().toLowerCase().includes(searchTextMultiplier.toLowerCase())).filter(item => item.emissionFactor.toString().toLowerCase().includes(searchTextEmissionFactor.toLowerCase())).filter(item => item.carbonEmissions.toString().toLowerCase().includes(searchTextEmissions.toLowerCase())).filter(item => item.fromPostCode.toString().toLowerCase().includes(searchTextFromPostCode.toLowerCase())).filter(item => item.toPostCode.toString().toLowerCase().includes(searchTextToPostCode.toLowerCase()))
        ).map((item) => {
            return {
                supplier: item.supplier
            }
        }))

        setFacilitiesToSelect(aggregateFacilitiesArray(subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        })
        .filter((itemA) => {
            return selectedTransportModes.find((itemB) => {
                return itemA.transportMode === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase())).filter(item => item.consumption.toString().toLowerCase().includes(searchTextConsumption.toLowerCase())).filter(item => item.multiplier.toString().toLowerCase().includes(searchTextMultiplier.toLowerCase())).filter(item => item.emissionFactor.toString().toLowerCase().includes(searchTextEmissionFactor.toLowerCase())).filter(item => item.carbonEmissions.toString().toLowerCase().includes(searchTextEmissions.toLowerCase())).filter(item => item.fromPostCode.toString().toLowerCase().includes(searchTextFromPostCode.toLowerCase())).filter(item => item.toPostCode.toString().toLowerCase().includes(searchTextToPostCode.toLowerCase()))
        ).map((item) => {
            return {
                facility: item.facility
            }
        }))

        setTransportModesToSelect(aggregateTransportModesArray(subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase())).filter(item => item.consumption.toString().toLowerCase().includes(searchTextConsumption.toLowerCase())).filter(item => item.multiplier.toString().toLowerCase().includes(searchTextMultiplier.toLowerCase())).filter(item => item.emissionFactor.toString().toLowerCase().includes(searchTextEmissionFactor.toLowerCase())).filter(item => item.carbonEmissions.toString().toLowerCase().includes(searchTextEmissions.toLowerCase())).filter(item => item.fromPostCode.toString().toLowerCase().includes(searchTextFromPostCode.toLowerCase())).filter(item => item.toPostCode.toString().toLowerCase().includes(searchTextToPostCode.toLowerCase()))
        ).map((item) => {
            return {
                transportMode: item.transportMode
            }
        }))

        setUnitsToSelect(aggregateUnitsArray(subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedTransportModes.find((itemB) => {
                return itemA.transportMode === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase())).filter(item => item.consumption.toString().toLowerCase().includes(searchTextConsumption.toLowerCase())).filter(item => item.multiplier.toString().toLowerCase().includes(searchTextMultiplier.toLowerCase())).filter(item => item.emissionFactor.toString().toLowerCase().includes(searchTextEmissionFactor.toLowerCase())).filter(item => item.carbonEmissions.toString().toLowerCase().includes(searchTextEmissions.toLowerCase())).filter(item => item.fromPostCode.toString().toLowerCase().includes(searchTextFromPostCode.toLowerCase())).filter(item => item.toPostCode.toString().toLowerCase().includes(searchTextToPostCode.toLowerCase()))
        ).map((item) => {
            return {
                unit: item.unit
            }
        }))

        setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedTransportModes.find((itemB) => {
                return itemA.transportMode === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase())).filter(item => item.consumption.toString().toLowerCase().includes(searchTextConsumption.toLowerCase())).filter(item => item.multiplier.toString().toLowerCase().includes(searchTextMultiplier.toLowerCase())).filter(item => item.emissionFactor.toString().toLowerCase().includes(searchTextEmissionFactor.toLowerCase())).filter(item => item.carbonEmissions.toString().toLowerCase().includes(searchTextEmissions.toLowerCase())).filter(item => item.fromPostCode.toString().toLowerCase().includes(searchTextFromPostCode.toLowerCase())).filter(item => item.toPostCode.toString().toLowerCase().includes(searchTextToPostCode.toLowerCase()))
        ).map((item) => {
            return {
                emissionFactorSource: item.emissionFactorSource
            }
        }))

        setCommentsToSelect(aggregateCommentsArray(subItems.filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedTransportModes.find((itemB) => {
                return itemA.transportMode === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase())).filter(item => item.consumption.toString().toLowerCase().includes(searchTextConsumption.toLowerCase())).filter(item => item.multiplier.toString().toLowerCase().includes(searchTextMultiplier.toLowerCase())).filter(item => item.emissionFactor.toString().toLowerCase().includes(searchTextEmissionFactor.toLowerCase())).filter(item => item.carbonEmissions.toString().toLowerCase().includes(searchTextEmissions.toLowerCase())).filter(item => item.fromPostCode.toString().toLowerCase().includes(searchTextFromPostCode.toLowerCase())).filter(item => item.toPostCode.toString().toLowerCase().includes(searchTextToPostCode.toLowerCase()))
        ).map((item) => {
            return {
                comments: item.comments
            }
        }))

    }

    }, [selectedComments, selectedEmissionFactorSources, selectedSuppliers, selectedFacilities, selectedUnits, company, role, userPropertiesLoading, searchText, searchTextConsumption,searchTextMultiplier, searchTextEmissionFactor, searchTextEmissions, searchTextFromPostCode, searchTextToPostCode, selectedTransportModes])

    useEffect(() => {

        var tempSubItemsFiltered = subItems.filter((itemA) => {
            return selectedComments.find((itemB) => {
                return itemA.comments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedSuppliers.find((itemB) => {
                return itemA.supplier === itemB.value;
            })
        }).filter((itemA) => {
            return selectedFacilities.find((itemB) => {
                return itemA.facility === itemB.value;
            })
        }).filter((itemA) => {
            return selectedTransportModes.find((itemB) => {
                return itemA.transportMode === itemB.value;
            })
        }).filter((itemA) => {
            return selectedUnits.find((itemB) => {
                return itemA.unit === itemB.value;
            })
        }).filter((itemA) => {
            return selectedEmissionFactorSources.find((itemB) => {
                return itemA.emissionFactorSource === itemB.value;
            })
        }).filter(item => item.footprintItemId === match.params.footprintItemId).filter(item => item.description.toLowerCase().includes(searchText.toLowerCase())).filter(item => item.consumption.toString().toLowerCase().includes(searchTextConsumption.toLowerCase())).filter(item => item.multiplier.toString().toLowerCase().includes(searchTextMultiplier.toLowerCase())).filter(item => item.multiplier.toString().toLowerCase().includes(searchTextMultiplier.toLowerCase())).filter(item => item.emissionFactor.toString().toLowerCase().includes(searchTextEmissionFactor.toLowerCase())).filter(item => item.carbonEmissions.toString().toLowerCase().includes(searchTextEmissions.toLowerCase())).filter(item => item.fromPostCode.toString().toLowerCase().includes(searchTextFromPostCode.toLowerCase())).filter(item => item.toPostCode.toString().toLowerCase().includes(searchTextToPostCode.toLowerCase()))

        setSubItemsFiltered(tempSubItemsFiltered)

        setSubItemsFilteredNumberOfItems(tempSubItemsFiltered.length)

        setSubItemsFilteredTotaltCO2e((tempSubItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000).toFixed(2))

        setSubItemsFilteredTotalConsumption((tempSubItemsFiltered.map(item => item.consumption).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))

    }, [selectedComments, selectedEmissionFactorSources, selectedSuppliers, selectedFacilities, selectedUnits, searchText, subItems, match.params.footprintItemId, searchTextConsumption, searchTextMultiplier, searchTextEmissionFactor, searchTextEmissions, searchTextFromPostCode, searchTextToPostCode, selectedTransportModes])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            axios.get(process.env.REACT_APP_API_URL + '/organisations/' + match.params.organisationId)
                .then((res) => {
                    if ((role === 3001 || role === 6001) && res.data.clientId !== company) {
                        navigate(`/errorPage`)
                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setOrganisationsLoading(false);
                });

            axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems/' + match.params.footprintItemId)
                .then((res) => {
                    // console.log(res.data.filter(item => item.footprintItemId === match.params.footprintItemId))
                    setSubItems(res.data)

                    setSelectedComments(aggregateCommentsArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            label: item.comments,
                            value: item.comments
                        }
                    }))

                    setSuppliersToSelect(aggregateSuppliersArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            supplier: item.supplier
                        }
                    }))
            
                    setFacilitiesToSelect(aggregateFacilitiesArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            facility: item.facility
                        }
                    }))

                    setTransportModesToSelect(aggregateTransportModesArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            transportMode: item.transportMode
                        }
                    }))
            
                    setUnitsToSelect(aggregateUnitsArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            unit: item.unit
                        }
                    }))
            
                    setEmissionFactorsToSelect(aggregateEmissionFactorSourcesArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            emissionFactorSource: item.emissionFactorSource
                        }
                    }))
            
                    setCommentsToSelect(aggregateCommentsArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            comments: item.comments
                        }
                    }))

                    setSelectedSuppliers(aggregateSuppliersArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            label: item.supplier,
                            value: item.supplier
                        }
                    }))
            
                    setSelectedFacilities(aggregateFacilitiesArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            label: item.facility,
                            value: item.facility
                        }
                    }))

                    setSelectedTransportModes(aggregateTransportModesArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            label: item.transportMode,
                            value: item.transportMode
                        }
                    }))

                    setSelectedUnits(aggregateUnitsArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            label: item.unit,
                            value: item.unit
                        }
                    }))
            
                    setSelectedEmissionFactorSources(aggregateEmissionFactorSourcesArray(res.data.filter(item => item.footprintItemId === match.params.footprintItemId)).map((item) => {
                        return {
                            label: item.emissionFactorSource,
                            value: item.emissionFactorSource
                        }
                    }))

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setSubItemsLoading(false);
                });

            axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
                .then((res) => {
                    if (res.data.organisationId !== match.params.organisationId) {
                        navigate(`/errorPage`)
                    }

                    setFootprint(res.data)

                    setCommentsToAdd(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription)

                    // axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems_dashboard', {"organisations": [match.params.organisationId]})
                    // .then((res2) => {

                    //     if (res2.data.filter(item => item.year === (Number(res.data.organisationFootprintYear) - 1).toString())) {

                    //         setPreviousYearsData(res2.data.filter(item => item.year === (Number(res.data.organisationFootprintYear) - 1).toString()))
                    
                    //     }
                    
                    // })
                    // .catch((err) => {
                    //     console.log(err);
                    // })
    
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

                if (role !== 3001 && role !== 6001 && role !== 6002) {

                    axios.get(process.env.REACT_APP_API_URL + '/carbon_factors')
                        .then((res) => {
                            setCarbonEmissionFactors(res.data)
                            setTransportModeOptions(res.data.filter(item => item.Level1.slice(0, 15) === "Business travel"))
                        })
                        .catch((err) => {
                            console.log(err);
                        }).finally(() => {
                            setCarbonFactorsLoading(false);
                        });

                    axios.get(process.env.REACT_APP_API_URL + '/carbon_factors_database_mapping')
                        .then((res) => {
                            setDatabaseMapping(res.data)
                        })
                        .catch((err) => {
                            console.log(err);
                        }).finally(() => {
                            setDatabaseMappingLoading(false);
                        });

                    axios.get(process.env.REACT_APP_API_URL + '/users')
                        .then((res) => {
                            setUsers(res.data);
                            setUserId(res.data.find(user => user.emailId === AuthService.getCurrentUserName()).id)
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => {
                            setUsersLoading(false);
                        });

                } else {

                    setCarbonFactorsLoading(false)
                    setDatabaseMappingLoading(false);
                    setCarbonEmissionFactors(["EMPTY"])
                    setUsersLoading(false);
                }

        }

        setReloadDataToggle(false)

    }, [match.params.footprintItemId, match.params.footprintId, company, role, userPropertiesLoading, match.params.organisationId, reloadDataToggle])

    useEffect(() => {

        //Code here to call get carbon factor
        if (carbonEmissionFactors.length > 1) {

            setTransportModeOptions(carbonEmissionFactors.filter(item => item.Level1.slice(0, 15) === "Business travel" && item.UOMsimple === unitToAdd))

        }

    }, [unitToAdd, carbonEmissionFactors, company, role, userPropertiesLoading])

    // useEffect(() => {

    //     if (company && role && !userPropertiesLoading && footprint.organisationFootprintItems) {

    //         setSelectedComments(aggregateCommentsArray(subItems).map((item) => {
    //             return {
    //                 label: item.comments,
    //                 value: item.comments
    //             }
    //         }))

    //     }

    // }, [footprint, match.params.footprintItemId, company, role, userPropertiesLoading])

    // useEffect(() => {

    //     if (fromPostCodeToAdd !== "" && fromPostCodeToAdd.length > 4) {

    //         instance.get('https://nominatim.openstreetmap.org/search.php?q=' + fromPostCodeToAdd + '&format=jsonv2')
    //             .then((res) => {
    //                 if (res.data[0]) {
    //                     setFromPostcodeCoordinates(res.data[0].lon + ',' + res.data[0].lat)
    //                 }
    //             })

    //     }

    // }, [fromPostCodeToAdd, company, role, userPropertiesLoading])

    // useEffect(() => {

    //     if (toPostCodeToAdd !== "" && toPostCodeToAdd.length > 4 && fromPostcodeCoordinates) {

    //         instance.get('https://nominatim.openstreetmap.org/search.php?q=' + toPostCodeToAdd + '&format=jsonv2')
    //             .then((res) => {
    //                 if (res.data[0]) {
    //                     setToPostcodeCoordinates(res.data[0].lon + ',' + res.data[0].lat)
    //                 }

    //                 instance.get(`https://router.project-osrm.org/route/v1/driving/${fromPostcodeCoordinates};${res.data[0].lon + ',' + res.data[0].lat}?overview=false`)
    //                     .then((res) => {
    //                         setFormattedConsumption(res.data.routes[0].distance / 1000)
    //                         setUnitToAdd("km")
    //                     })
    //             })

    //     }

    // }, [toPostCodeToAdd, company, role, userPropertiesLoading, fromPostcodeCoordinates, fromPostCodeToAdd])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            if (selectedTransportModeToAdd.value && carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === selectedTransportModeToAdd.value)) {

                setEmissionFactorToAdd(carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === selectedTransportModeToAdd.value).GHGConversion)
                setFormattedEmissions(carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === selectedTransportModeToAdd.value).GHGConversion * removeCommas(consumptionToAdd) * multiplierToAdd)
            } else {

                setFormattedEmissions(emissionFactorToAdd * removeCommas(consumptionToAdd) * multiplierToAdd)

            }

        }

    }, [selectedTransportModeToAdd, company, role, userPropertiesLoading, carbonEmissionFactors, consumptionToAdd, multiplierToAdd, emissionFactorToAdd])

    useEffect(() => {

        if (company && role && !userPropertiesLoading && commentsToAdd === "RECYCLE BIN") {

            setFormattedEmissions(0)

        } else if (company && role && !userPropertiesLoading && commentsToAdd !== "RECYCLE BIN") {

            setFormattedEmissions(emissionFactorToAdd * removeCommas(consumptionToAdd) * multiplierToAdd)
        }

    }, [commentsToAdd, company, role, userPropertiesLoading, consumptionToAdd, multiplierToAdd, emissionFactorToAdd])

    if (carbonEmissionFactors.length > 0 && !carbonFactorsLoading && !loading && !subItemsLoading && !organisationsLoading && !databaseMappingLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        
                        <Header title="Organisation Footprint Item Helper Tool" section="Organisation Footprint Item" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <br></br>

                        <div>
                            <div className="flex items-center">
                                <div className="w-full mx-auto mb-5 px-3">
                                    <div className="grid gap-2 sm:gap-7 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Footprint Item Sub Categoy: </div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemSubCategory}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Footprint Item Description: </div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemDescription}</p></div>
                                                </div>
                                            </div>
                                            <div className="float-right">
                                                <Button color="bg-bondi-blue" text="<< Prev" onClick={() => saveAndNavigate("Prev")} />
                                                <Button color="bg-bondi-blue" text="Next >>" onClick={() => saveAndNavigate("Next")} />
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                                <div className="text-sm text-gray-500 ">Footprint Item Carbon Emissions (tCO<sub>2</sub>e): </div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{internationalNumberFormat.format((footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).carbonEmissions)/ 1000)}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                                                </svg>


                                            </div>
                                        </div>

                                        {/* <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Consultant Comments: </div>
                                                <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue hover:cursor-pointer">
                                                    <svg onClick={() => { setShowModalUpdate(true); setValueToSendToModal(footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemConsultantComment); setFieldToSendToModal("itemConsultantComment"); setObjectIdToSendToModal(footprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).id) }} xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5 mr-1" viewBox="0 0 20 20" fill="gray">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                                    <TextWithLinks content = {footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemConsultantComment}></TextWithLinks>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
                                                </svg>

                                            </div>
                                        </div> */}
                                        {role !==3001 &&
                                        <div className="justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300 text-xs row-span-2 max-h-[600px] overflow-y-scroll">
                                            <div>
                                                <div className="pb-4">
                                                <MultiSelect
                                                    options={[{label: "Consultant" , value: "Consultant"}, {label: "Client" , value: "Client"}, {label: "Submission" , value: "Submission"}, {label: "QA" , value: "QA"}, {label: "Verification" , value: "Verification"}]}
                                                    value={selectedCommentTypes}
                                                    onChange={setSelectedCommentTypes}
                                                    labelledBy="Select"
                                                />
                                                </div>
                                                {commentThread(footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).commentThread, footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).id, footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).itemTag)}
                                                
                                                <div onClick={() => { setShowModalUpdate(true); setValueToSendToModal(""); setFieldToSendToModal("commentThread"); setObjectIdToSendToModal(footprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).id) }} >
                                                    <svg data-tip data-for="addCommentToolTip" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 hover:cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            {/* <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
                                                </svg>

                                            </div> */}
                                        </div>
                                        }

                                        {/* <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Footprint Item Carbon Emissions (kgCO<sub>2</sub>e): </div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{internationalNumberFormat.format(footprint.organisationFootprintItems.find(item => item.id === match.params.footprintItemId).carbonEmissions)}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                                                </svg>


                                            </div>
                                        </div> */}

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Data Import: </div>
                                                {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                <div>

                                                    <div className="flex items-center pt-1">
                                                        <form>

                                                            <input id="csvFileInput" accept={".csv"} type={"file"} onChange={handleOnChange} onClick={handleOnChange}/>
                                                            <label className="p-2" htmlFor="csvFileInput">Choose a File</label>
                                                            {file &&
                                                                <Button color="bondi-blue" text="Import Data" onClick={(e) => { handleOnSubmit(e) }} />
                                                            }
                                                        </form>

                                                    </div>

                                                    <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                            <div className="form-control-8A">
                                                                <label className="pl-3 text-xs">Import from your Management System</label>

                                                                <select value={managementSystem} onChange={(e) => setManagementSystem(e.target.value)}>
                                                                    <option value="Xero">Xero</option>
                                                                    <option value="Oracle">Oracle</option>
                                                                    <option value="Sage">Sage</option>
                                                                    <option value="QuickBooks">QuickBooks</option>
                                                                    <option value="SAP">SAP</option>
                                                                    <option value="Certinia formerly FinancialForce">Certinia formerly FinancialForce</option>

                                                                </select>
                                                            </div></td>
                                                            
                                                            <td className='pl-10 pr-5 hover:cursor-pointer'><img className="pt-4" onClick={() => XeroUserExists()} src={managementSystem === "Xero" ? '/images/xero_software_logo.svg' : managementSystem === "SAP" ? '/images/sap.svg' : managementSystem === "Oracle" ? '/images/Oracle.png' : managementSystem === "Certinia formerly FinancialForce" ? '/images/Certinia.svg' : managementSystem === "QuickBooks" ? '/images/quickbooks.svg' : '/images/Sage.png'} width="80px"></img></td>
                                                        </tr>
                                                        <tr>
                                                            <td >
                                                                <Button color="bondi-blue" text="Flight Calc" onClick={ () => handleFlights() } />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                </div>

                                                }
    
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                                                </svg>


                                            </div>

                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Actions: </div>
                                                {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                <div>

                                                <div className="flex items-center">
                                                        <div className="w-full mx-auto m-5 px-3">
                                                            <div className="grid grid-cols-2">
                                                            {/* <label className = "text-xs">Carbon Factor Databases</label> */}
                                                                <div className = "text-xs">
                                                                <label className = "text-xs">Carbon Factor Databases</label>
                                                                <MultiSelect
                                                                    options={aggregateCarbonFactorDatabasesArray(carbonEmissionFactors).map((item) => {
                                                                        return {
                                                                            label: item.DataSource,
                                                                            value: item.DataSource
                                                                        }
                                                                    })}
                                                                    value={selectedDatabases}
                                                                    onChange={setSelectedDatabases}
                                                                    labelledBy="Select"
                                                                />
                                                                </div>
                                                                <div className="pl-5 pt-4">
                                                                <button className="rounded-full m-1 p-1 pr-1 text-white t-10 bg-pacific-blue-500 text-center" onClick={() => { switchEmissionFactors() }} data-tip data-for="switchFactors">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`float-left h-6 w-6 text-white ${animationSwitch === true ? "animate-spin" : ""}`} >
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                                </svg>
                                                                Switch Factors
                                                                </button>

                                                                    {/* <Button color="bondi-blue" text="Switch Factors" onClick={() => {switchEmissionFactors()}}></Button> */}
                                                                </div>
                                                            </div>
                                                            </div>
                                                            </div>

                                                    <div className="flex items-center pt-1">

                                                            <div>
                                                                <Button color="bondi-blue" text="Auto Select Factors" onClick={() => {automaticallySelectEmissionFactors()}} />
                                                                <Button color="bondi-blue" text="Delete All Items" onClick={() => { deleteAllFootprintSubItems() }} />
                                                            </div>

                                                            <div className="form-control-8A p-3">
                                                                <label className = "text-xs">Inflation Factor</label>
                                                                <input className = "text-xs" type="number" placeholder="Enter Inflation Factor Here" value={inflationFactor} onChange={(e) => setInflationFactor(Number(e.target.value))} />
                                                            </div>

                                                    </div>

                                                </div>

                                                }
    
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                                                </svg>


                                            </div>

                                        </div>

                                        {role !== 3001 &&

                                        <>

                                        <div className="flex items-center justify-between p-5 bg-white rounded-2xl shadow-md shadow-slate-200 border border-slate-200">
                                        <div className = "w-full">
                                            <div className="text-sm text-gray-500 ">Uploaded Documentary Evidence: </div>
                                            <div className="p-4 bg-white rounded shadow-sm max-h-52 overflow-y-scroll w-full">

                                            <div className="text-md font-small text-pacific-blue w-full h-auto"> <FileUploadAndDownload id={match.params.footprintItemId} allowDeletes={role === 6002 ? false : true} allowUploads={role === 4001 || role === 2004 || role === 6001 || role === 2001 ? true : false} onChange={setUpdatedNumberOfFilesUploaded}/></div>
                                            {/* <div className="text-md font-small text-pacific-blue w-full h-auto"> <FileUploadAndDownload id={match.params.footprintItemId} allowDeletes={true} allowUploads={true}/></div> */}
                                            </div>
                                        </div>
                                        

                                        </div>
                                        
                                        <div className="flex items-center justify-between p-5 bg-white rounded-2xl shadow-md shadow-slate-200 border border-slate-200">
                                            <div className = "w-full">
                                            <div className="text-sm text-gray-500 ">Internal Files: </div>
                                            <div className="p-4 bg-white rounded shadow-sm max-h-52 overflow-y-scroll w-full">

                                        
                                            
                                            <div className="text-md font-small text-pacific-blue w-full h-auto"> <FileUploadAndDownload id={match.params.footprintItemId} allowUploads={role === 4001 || role === 2004 || role === 6001 || role === 2001 ? true : false} subFolder="internalDocs" allowDeletes={role === 6002 ? false : true}/></div>

                                            </div>
                                            </div>

                                        </div>
                                        </>
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                        {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                        <div>
                        {!editToggle && !editSelectedToggle &&
                        <h1 className='ml-5 header-8A mt-10'>Add new footprint item</h1>
                        }
                        {editToggle &&
                        <h1 className='ml-5 header-8A mt-10'>Edit footprint item</h1>
                        }
                        {editSelectedToggle &&
                        <h1 className='ml-5 header-8A mt-10'>Edit selected footprint items</h1>
                        }

                        <div className="flex items-center">
                            <div className="w-full mx-auto mb-5 px-3">
                                <div className="grid grid-cols-2 gap-2 grid-col-1 sm:grid-cols-3 lg:grid-cols-7">
                                    <div className="form-control-8A">
                                        <label className="text-center">Description</label>
                                        {/* <input className={editToggle ? "bg-pacific-blue-50" : ""} ref={descriptionInput} type="text" value={descriptionToAdd} onChange={(e) => setDescriptionToAdd(e.target.value)} /> */}
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} ref={descriptionInput} type="text" onBlur={() => setDescriptionToAdd(descriptionInput.current.value)} />
                                    </div>
                                   
                                    <div className="form-control-8A">
                                        <label className="text-center">Supplier</label>
                                        {/* <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={supplierToAdd} onChange={(e) => setSupplierToAdd(e.target.value)} /> */}
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} ref={supplierInput} type="text" onBlur={() => setSupplierToAdd(supplierInput.current.value)} />
                                    </div>

                                    <div className="form-control-8A">
                                        <label className="text-center">Facility</label>
                                        {/* <input className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} type="text" value={facilityToAdd} onChange={(e) => setFacilityToAdd(e.target.value)} /> */}
                                        <input className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} ref={facilityInput} type="text" onBlur={() => setFacilityToAdd(facilityInput.current.value)} />
                                    </div>
                                    {/* <div className="form-control-8A">
                                        <label className="text-center">Individual(s)</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={individualToAdd} onChange={(e) => setIndividualToAdd(e.target.value)} />
                                    </div> */}
                                    {
                                    (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.7 Employee Commuting" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.6 Business Travel" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.4 Upstream Transportation & Distribution" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.9 Downstream Transportation & Distribution") &&
                                    <>
                                        <div className="form-control-8A">
                                            <label className="text-center">From</label>
                                            <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={fromPostCodeToAdd} onChange={(e) => setFromPostCodeToAdd(e.target.value)} />
                                        </div>
                                        <div className="form-control-8A">
                                            <label className="text-center">To</label>
                                            <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={toPostCodeToAdd} onChange={(e) => setToPostCodeToAdd(e.target.value)} />
                                        </div>
                                    </>
                                    }
                                    <div className="form-control-8A">
                                        <label className="text-center">Consumption</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={consumptionToAdd} onChange={handleChangeForConsumption} />
                                    </div>

                                    <div className={role === 6001 || role === 6002 ? "grid grid-cols-1" : "grid grid-cols-2"}>
                                        <div className="form-control-8A">
                                        <label className="text-center">Multiplier</label>
                                        <input className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} type="number" value={multiplierToAdd} onChange={(e) => setMultiplierToAdd(e.target.value)} />
                                        </div>
                                        {role !== 6001 && role !== 3001 && role !== 6002 &&
                                        <div className="form-control-8A p-1">
                                            <label className="text-center text-slate-100">Select</label>
                                            <Button color="bondi-blue" text={"Select"} onClick={() => viewCarbonFactorsForMultiplier()} />
                                        </div>
                                        }
                                    </div>
                                    
                                    <div className="form-control-8A">
                                        <label className="text-center">Unit</label>
                                        {/* <input type="text" value={unitToAdd} onChange={(e) => setUnitToAdd(e.target.value)} /> */}
                                        <select className={editToggle ? "bg-pacific-blue-50" : ""} value={unitToAdd} onChange={(e) => setUnitToAdd(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="kg">kg</option>
                                        <option value="tonnes">tonnes</option>
                                        <option value="kWh">kWh</option>
                                        <option value="miles">miles</option>
                                        <option value="km">km</option>
                                        <option value="passenger.km">passenger.km</option>
                                        <option value="tonne.km">tonne.km</option>
                                        <option value="litres">litres</option>
                                        <option value="cubic metres">cubic metres</option>
                                        <option value="square metres">square metres</option>
                                        <option value="GJ">GJ</option>
                                        <option value="GBP">GBP</option>
                                        <option value="EUR">EUR</option>
                                        <option value="USD">USD</option>
                                        <option value="unit">unit</option>
                                        <option value="other">other</option>
                                        <option value="various">various</option>
					                    <option value="kgCO2e">kgCO2e</option>
                                        </select>
                                    </div>
                                    {
                                    (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.7 Employee Commuting" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.6 Business Travel" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.4 Upstream Transportation & Distribution" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.9 Downstream Transportation & Distribution") &&
                                    <div className="pl-1 pt-5 z-20">
                                        <div className="text-center pb-1">
                                            <label className="text-center">Transport Mode</label>
                                        </div>
                                        <ReactSelect
                                            styles={!editToggle ? styles : stylesWhenEditing}
                                            value={selectedTransportModeToAdd}
                                            onChange={setSelectedTransportModeToAdd}
                                            onClick={setSelectedTransportModeToAdd}
                                            options={transportModeOptions.sort((item1, item2) => (item1.Level2 < item2.Level2) ? -1 : (item1.Level2 > item2.Level2) ? 1 : 0).map((item) => {
                                                return {
                                                    label: item.Level2 + " " + item.Lookup + " " + item.ColumnText,
                                                    value: item.Level2 + " " + item.Lookup + " " + item.ColumnText
                                                }
                                            })} />
                                    </div>
                                    }
                                    {role !== 3001 &&
                                    <div className={role === 6001 || role === 6002 ? "grid grid-cols-1" : "grid grid-cols-2"}>
                                        <div className="form-control-8A">
                                            <label className="text-center">Factor</label>
                                            <input className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} type="number" value={emissionFactorToAdd} onChange={(e) => setEmissionFactorToAdd(e.target.value)} />
                                        </div>
                                        {role !== 6001 && role !== 6002 &&
                                        <div className="form-control-8A p-1">
                                            <label className="text-center text-slate-100">Select</label>
                                            <Button color="bondi-blue" text={"Select"} onClick={() => viewCarbonFactors()} />
                                        </div>
                                        }
                                    </div>
                                    }
                                    <div className="form-control-8A">
                                        <label className="text-center">Emissions (kgCO2e)</label>
                                        <input disabled={commentsToAdd === "RECYCLE BIN" ? true : false} className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={carbonEmissionsToAdd} onChange={handleChangeForEmissions} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Comments</label>
                                        <select ref={commentsInput} className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} value={commentsToAdd} onChange={(e) => setCommentsToAdd(e.target.value)}>
                                        <option value={commentsToAdd}>{commentsToAdd}</option>
                                        <option className="bg-slate-300" value="">BLANK</option>
                                        <option className="bg-red-300" value="RECYCLE BIN">RECYCLE BIN</option>
                                        {footprint.organisationFootprintItems.sort((item1, item2) => Number(item1.itemCategory) < Number(item2.itemCategory) ? -1 : Number(item1.itemCategory) > Number(item2.itemCategory) ? 1 : Number(item1.itemSubCategory.slice(2,4)) < Number(item2.itemSubCategory.slice(2,4)) ? -1 : Number(item1.itemSubCategory.slice(2,4)) > Number(item2.itemSubCategory.slice(2,4)) ? 1 : item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0).map((footprintItem) => (                                    
                                            <option key={footprintItem.id} value={footprintItem.itemDescription} > {footprintItem.itemDescription}</option>
                                        ))}
                                        </select>
                                        {/* <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={commentsToAdd} onChange={(e) => setCommentsToAdd(e.target.value)} /> */}
                                    </div>
                                    {multiplierToAdd !== 1 && multiplierToAdd !== "1" &&
                                        <div className="form-control-8A whitespace-nowrap col-span-2">
                                            <label className="text-center">Multiplier Factor Source</label>
                                            <input className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} type="text" value={multiplierFactorSourceToAdd} onChange={(e) => setMultiplierFactorSourceToAdd(e.target.value)} />
                                        </div>
                                    }
                                    <div className="form-control-8A whitespace-nowrap col-span-2">
                                        <label className="text-center">Emission Factor Source</label>
                                        <input className={editToggle || editSelectedToggle ? "bg-pacific-blue-50" : ""} type="text" value={emissionFactorSourceToAdd} onChange={(e) => setEmissionFactorSourceToAdd(e.target.value)} />
                                    </div>


                                </div>
                                <div className="flex">
                                    {!editToggle && !editSelectedToggle && (
                                    <Button color="bg-bondi-blue" text="Add Item" onClick={() => addFootprintSubItem()} />
                                    )}
                                    {editToggle && (
                                    <Button color="bg-bondi-blue" text="Update Item" onClick={() => updateFootprintSubItem()} />
                                    )}
                                    {editSelectedToggle && (
                                    <Button color="bg-bondi-blue" text="Update Items" onClick={() => updateSelectedFootprintSubItems()} />
                                    )}
                                    <Button color="bg-bondi-blue" text="Cancel" onClick={() => cancelAddOrEditSubItem()} />

                                </div>
                                {role !== 3001 &&
                                    <div className="float-right">
                                        <Button color="bondi-blue" text="Reset Filters" onClick={() => resetFilters()}/>
                                        <Button color="bondi-blue" text="Select All Filtered Items" onClick={() => selectAllFilteredItems()}/>
                                        <Button color="bondi-blue" text={"Clear Selected Items (" + subItems.filter(item => item.individual === "SELECTED").length + ")"} onClick={() => clearSelectedItems()}/>
                                        <Button color="bondi-blue" text="Edit Selected Items" onClick={() => editSelectedFootprintSubItems()}/>
                                        <Button color="bondi-blue" text="Delete Selected Items" onClick={() => deleteSelectedFootprintSubItems()}/>
                                        <Button color="bondi-blue" text="Move Selected Items" onClick={() => moveSelectedFootprintSubItems()}/>
                                        <Button color="bondi-blue" text="Clone Selected Items" onClick={() => cloneSelectedFootprintSubItems()}/>

                                    </div>
                                }
                            </div>
                        </div>
                        </div>
                        }

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div className="shadow-md shadow-slate-300 rounded-2xl">
                                        <table id = "subItemsTable" className="objects-8A min-w-full divide-y divide-gray-200">
                                            <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                                                <tr>
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Select</th>
                                                    }
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Description<button className="uppercase" type="button" onClick={() => sortByDescription()}>{sortedField ==='description' && sortedOrder==='asc' ? '↑' : sortedField==='description' && sortedOrder==='desc' ? '↓' : '⇅'}</button>
                                                        <div className="w-52">
                                                            <div className="grid gap-1">
                                                                <div className="form-control-no-margin-8A whitespace-nowrap">
                                                                    <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    {
                                                    (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory !== "3.7 Employee Commuting" && footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory !== "3.6 Business Travel") && 
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Supplier<div className="w-52">
                                                        <MultiSelect
                                                            options={suppliersToSelect.map((item) => {
                                                                return {
                                                                    label: item.supplier,
                                                                    value: item.supplier
                                                                }
                                                            })}
                                                            value={selectedSuppliers}
                                                            onChange={setSelectedSuppliers}
                                                            labelledBy="Select"
                                                        />
                                                        </div></th>  
                                                    }
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Facility<div className="w-32">
                                                        <MultiSelect
                                                            options={facilitiesToSelect.map((item) => {
                                                                return {
                                                                    label: item.facility,
                                                                    value: item.facility
                                                                }
                                                            })}
                                                            value={selectedFacilities}
                                                            onChange={setSelectedFacilities}
                                                            labelledBy="Select"
                                                        />
                                                        </div></th>
                                                    {
                                                    (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.7 Employee Commuting" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.6 Business Travel" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.4 Upstream Transportation & Distribution" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.9 Downstream Transportation & Distribution") &&
                                                    <>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> From
                                                            <div className="w-24">
                                                                    <div className="grid gap-1">
                                                                        <div className="form-control-no-margin-8A whitespace-nowrap">
                                                                            <input type="text" value={searchTextFromPostCode} onChange={(e) => setSearchTextFromPostCode(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> To
                                                            <div className="w-24">
                                                                    <div className="grid gap-1">
                                                                        <div className="form-control-no-margin-8A whitespace-nowrap">
                                                                            <input type="text" value={searchTextToPostCode} onChange={(e) => setSearchTextToPostCode(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Transport Mode<div className="w-32">
                                                        <MultiSelect
                                                            options={transportModesToSelect.map((item) => {
                                                                return {
                                                                    label: item.transportMode,
                                                                    value: item.transportMode
                                                                }
                                                            })}
                                                            value={selectedTransportModes}
                                                            onChange={setSelectedTransportModes}
                                                            labelledBy="Select"
                                                        />
                                                        </div></th>
                                                    </>    
                                                    }

                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <Button color="bg-bondi-blue" text="Consumption" noMargin={true} onClick={() => handlePostcodesPreCheck()}/><button className="uppercase" type="button" onClick={() => sortByConsumption()}>{sortedField ==='consumption' && sortedOrder==='asc' ? '↑' : sortedField==='consumption' && sortedOrder==='desc' ? '↓' : '⇅'}</button>
                                                        <div className="w-28">
                                                            <div className="grid gap-1">
                                                                <div className="form-control-no-margin-8A whitespace-nowrap">
                                                                    <input type="text" value={searchTextConsumption} onChange={(e) => setSearchTextConsumption(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    // <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <Button color="bg-bondi-blue" text="Consumption" onClick={() => handlePostcodes()}/><button className="uppercase" type="button" onClick={() => sortByConsumption()}>{sortedField ==='consumption' && sortedOrder==='asc' ? '↑' : sortedField==='consumption' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                    // <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <Button color="bg-bondi-blue" text="Consumption" /><button className="uppercase" type="button" onClick={() => sortByConsumption()}>{sortedField ==='consumption' && sortedOrder==='asc' ? '↑' : sortedField==='consumption' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                     }
                                                    {(role === 3001 || role === 5001 || role === 6002 || role === 2003) &&
                                                     <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Consumption<button className="uppercase" type="button" onClick={() => sortByConsumption()}>{sortedField ==='consumption' && sortedOrder==='asc' ? '↑' : sortedField==='consumption' && sortedOrder==='desc' ? '↓' : '⇅'}</button>
                                                        <div className="w-28">
                                                            <div className="grid gap-1">
                                                                <div className="form-control-no-margin-8A whitespace-nowrap">
                                                                    <input type="text" value={searchTextConsumption} onChange={(e) => setSearchTextConsumption(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    }

                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Multiplier
                                                        <div className="w-28">
                                                            <div className="grid gap-1">
                                                                <div className="form-control-no-margin-8A whitespace-nowrap">
                                                                    <input type="text" value={searchTextMultiplier} onChange={(e) => setSearchTextMultiplier(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>

                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit<div className="w-24">
                                                        <MultiSelect
                                                            options={unitsToSelect.map((item) => {
                                                                return {
                                                                    label: item.unit,
                                                                    value: item.unit
                                                                }
                                                            })}
                                                            value={selectedUnits}
                                                            onChange={setSelectedUnits}
                                                            labelledBy="Select"
                                                        />
                                                        </div></th>
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <Button color="bg-bondi-blue" text="Emissions Factor" noMargin={true} onClick={() => calculateAllEmissionFactors()} /><button className="uppercase" type="button" onClick={() => sortByEmissionFactors()}>{sortedField ==='emissionsFactor' && sortedOrder==='asc' ? '↑' : sortedField==='emissionsFactor' && sortedOrder==='desc' ? '↓' : '⇅'}</button>
                                                            <div className="w-28">
                                                                <div className="grid gap-1">
                                                                    <div className="form-control-no-margin-8A whitespace-nowrap">
                                                                        <input type="text" value={searchTextEmissionFactor} onChange={(e) => setSearchTextEmissionFactor(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    }
                                                    {(role === 5001 || role === 6002 || role === 2003) &&
                                                     <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions Factor <button className="uppercase" type="button" onClick={() => sortByEmissionFactors()}>{sortedField ==='emissionsFactor' && sortedOrder==='asc' ? '↑' : sortedField==='emissionsFactor' && sortedOrder==='desc' ? '↓' : '⇅'}</button></th>
                                                    }
                                                    {role !== 3001 &&

                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emission Factor Source<div className="w-52">
                                                     
                                                     <MultiSelect
                                                         options={emissionFactorsToSelect.map((item) => {
                                                             return {
                                                                 label: item.emissionFactorSource.indexOf(";") === 24 ? item.emissionFactorSource.slice(26, item.emissionFactorSource.length) : item.emissionFactorSource,
                                                                 value: item.emissionFactorSource
                                                             }
                                                         })}
                                                         value={selectedEmissionFactorSources}
                                                         onChange={setSelectedEmissionFactorSources}
                                                         labelledBy="Select"
                                                     />
                                                     </div></th>

                                                    }
                                                    {/* {role === 3001 &&
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions Factor</th>
                                            } */}
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <Button color="bg-bondi-blue" text="Emissions (kgCO2e)" noMargin={true} onClick={() => calculateAllEmissions()} /><button className="uppercase" type="button" onClick={() => sortByEmissions()}>{sortedField ==='emissions' && sortedOrder==='asc' ? '↑' : sortedField==='emissions' && sortedOrder==='desc' ? '↓' : '⇅'}</button>
                                                            <div className="w-32">
                                                                <div className="grid gap-1">
                                                                    <div className="form-control-no-margin-8A whitespace-nowrap">
                                                                        <input type="text" value={searchTextEmissions} onChange={(e) => setSearchTextEmissions(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    }
                                                    {(role === 3001 || role === 5001 || role === 6002 || role === 2003) &&
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap">Emissions (kgCO2e) <button className="uppercase" type="button" onClick={() => sortByEmissions()}>{sortedField ==='emissions' && sortedOrder==='asc' ? '↑' : sortedField==='emissions' && sortedOrder==='desc' ? '↓' : '⇅'}</button>
                                                            <div className="w-32">
                                                                <div className="grid gap-1">
                                                                    <div className="form-control-no-margin-8A whitespace-nowrap">
                                                                        <input type="text" value={searchTextEmissions} onChange={(e) => setSearchTextEmissions(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    }
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase"> Comments
                                                                <div className="w-40">
                                                                <MultiSelect
                                                                    options={commentsToSelect.map((item) => {
                                                                        return {
                                                                            label: item.comments,
                                                                            value: item.comments
                                                                        }
                                                                    })}
                                                                    value={selectedComments}
                                                                    onChange={setSelectedComments}
                                                                    labelledBy="Select"
                                                                />
                                                                </div>
                                                                </th>
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                                    }
                                                    </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {subItemsFiltered.slice(firstRecord, lastRecord).map((footprintSubItem) => (
                                                    <tr key={footprintSubItem.id}>
                                                        {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <td onClick={() => markSubItemAsSelected(footprintSubItem.id)} className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.individual === "SELECTED" ? <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="radio-button-on"><rect width="24" height="24" opacity="0"/><path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/><path d="M12 7a5 5 0 1 0 5 5 5 5 0 0 0-5-5z"/></g></g></svg> : <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="radio-button-off"><rect width="24" height="24" opacity="0"/><path d="M12 22a10 10 0 1 1 10-10 10 10 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8 8 0 0 0-8-8z"/></g></g></svg>} </td>
                                                        }
                                                        <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 hover:cursor-pointer "}> {footprintSubItem.description}</td>
                                                        {
                                                        (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory !== "3.7 Employee Commuting" && footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory !== "3.6 Business Travel") && 
                                                        <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.supplier}</td>
                                                        }
                                                        <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.facility}</td>
                                                        {
                                                        (footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.7 Employee Commuting" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.6 Business Travel" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.4 Upstream Transportation & Distribution" || footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory === "3.9 Downstream Transportation & Distribution") &&
                                                        <>
                                                            <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.fromPostCode}</td>
                                                            <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.toPostCode}</td>
                                                            <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 hover:cursor-pointer "}> {footprintSubItem.transportMode}</td>
                                                        </>
                                                        }
                                                       
                                                        <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300 text-right" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300 text-right" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white text-right" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer text-right"}> {internationalNumberFormat.format(footprintSubItem.consumption)}</td>
                                                        <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.multiplier}</td>
                                                        <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.unit}</td>
                                                        {role !== 3001 &&
                                                            <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.emissionFactorSource.slice(-4) === "#MAP" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-daintree-400 text-white" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : footprintSubItem.emissionFactorSource.slice(-4) === "#PRE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-emerald-200 text-black" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer "}> {footprintSubItem.emissionFactor}</td>
                                                        }
                                                        {role !== 3001 &&
                                                        <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300 text-xs" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300 text-xs" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white text-xs" : "px-4 md:px-6 py-2 hover:cursor-pointer text-xs"}> {footprintSubItem.emissionFactorSource.indexOf(";") === 24 ? footprintSubItem.emissionFactorSource.slice(26, footprintSubItem.emissionFactorSource.length) : footprintSubItem.emissionFactorSource}</td>
                                                        }
                                                        {/* {role === 3001 &&
                                                <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.emissionFactor}</td>
                                                } */}
                                                        <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300 text-right" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300 text-right" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white text-right" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer text-right"}> {internationalNumberFormat.format(footprintSubItem.carbonEmissions)}</td>
                                                        <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 hover:cursor-pointer "}> {footprintSubItem.comments}</td>
                                                        
                                                        {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <td className={footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription && footprintSubItem.comments !== "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-amber-300" : footprintSubItem.comments === "#CHECK EXCLUDE" ? "px-4 md:px-6 py-2 hover:cursor-pointer bg-red-500 text-white" : "px-4 md:px-6 py-2 hover:cursor-pointer "}>
                                                            {footprint.organisationFootprintItems.find(item => item.itemDescription === footprintSubItem.comments) && footprintSubItem.comments !== footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription &&
                                                            <Options cloneFunction={cloneFootprintSubItem} cloneObject={footprintSubItem} deleteFunction={deleteFootprintSubItem} deleteObject={footprintSubItem.id} editFunction={editFootprintSubItem} editObject={{...footprintSubItem}} moveFunction={moveFootprintSubItem} moveObject={{...footprintSubItem}}></Options>
                                                            // <Options cloneFunction={cloneFootprintSubItem} cloneObject={footprintSubItem} editFunction={editFootprintSubItem} editObject={{...footprintSubItem}} moveFunction={moveFootprintSubItem} moveObject={{...footprintSubItem}}></Options>
                                                            }
                                                            {(!footprint.organisationFootprintItems.find(item => item.itemDescription === footprintSubItem.comments) || footprintSubItem.comments === footprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription) &&
                                                            <Options cloneFunction={cloneFootprintSubItem} cloneObject={footprintSubItem} deleteFunction={deleteFootprintSubItem} deleteObject={footprintSubItem.id} editFunction={editFootprintSubItem} editObject={{...footprintSubItem}}></Options>
                                                            // <Options cloneFunction={cloneFootprintSubItem} cloneObject={footprintSubItem} editFunction={editFootprintSubItem} editObject={{...footprintSubItem}}></Options>
                                                            }
                                                            </td>
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                        <Modal reportingYear={footprint.organisationFootprintYear} tableData={carbonEmissionFactors} onChange={multiplierSelection ? setSelectedMultiplier : setSelectedFactor} onClose={() => setShowModal(false)} show={showModal} title="Browse Emission Factor Database" inflationFact={inflationFactor} allSubItems={subItems} multiplierOrFactor={multiplierSelection ? "multiplier" : "factor"}/>
                                        <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={fieldToSendToModal === "commentThread" ? [footprint] : subItems} onChange={setSubItemsToXeroData} onClose={() => setShowModalUpdate(false)} show={showModalUpdate} arrayIdToUpdate={collectionArrayIdToSendToModal} title={fieldToSendToModal === "commentThread" ? "Internal QA Comment" : "Import Data"} />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='inline-block min-w-full px-3 my-5'>
                            {role !== 6002 && role !== 3001 && !saving &&
                            <Button color="bg-bondi-blue" text="Save" onClick={() => saveOnly()} />
                            }
                            {role !== 6002 && role !== 3001 && !saving &&
                            <Button color="bg-bondi-blue" text="Save and Close" onClick={() => onSubmit()} />
                            }
                            {role !== 6002 && role !== 3001 && saving &&
                            <Button color="bg-bondi-blue" text="Saving...."/>
                            }
                            {role !== 6002 && role !== 3001 && saving &&
                            <Button color="bg-bondi-blue" text="Saving...." />
                            }
                            <Button color="bg-bondi-blue" text="Close" onClick={() => onClose()} />
                            {role !== 6002 && role !== 3001 &&
                            <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(subItems)} />
                            }

                            <span className="pl-14">{"Total Consumption: " + internationalNumberFormat.format(subItemsFilteredTotalConsumption)}</span>

            
                            <div className="float-right">{firstRecord + " to " + lastRecord + " of " +
                                                        subItemsFilteredNumberOfItems + " Sub Items  " + subItemsFilteredTotaltCO2e + " tCO2e"}
                                                    
                                                        <span className = "p-2"></span>
                                                        <Button text="<< Previous 100" onClick={() => prev100Records()}></Button>
                                                        <Button text="Next 100 >>" onClick={() => next100Records()}></Button>

                                                    </div>

                        </div>
                    </div>
                </div>
                <ModalLoading onClose={() => setShowModalLoading(false)} show={showModalLoading} title="Data Processing" totalRecords={totalNumberOfRecordsToImport} currentRecord={currentRecordToImport}/>
                <InfoModal onClose={() => {setProcessWhat("");setShowInfoModal(false)}} onZero={() => {setProcessWhat("Zero");setShowInfoModal(false)}} onAll={() => {setProcessWhat("All");setShowInfoModal(false)}} show={showInfoModal} title="Distance Matrix" message="Some of the records already have a distance set.  Would you like to just calculate distances for any record with 0km currently set?" />
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Organisation Footprint Item Helper Tool" section="Organisation Footprint Item" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

};

export default ViewOrganisationFootprintItemHelperTool;