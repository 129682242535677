import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import Button from '../UI/Button'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { v4 as uuidv4 } from 'uuid'

const AddOrUpdateOrganisation = () => {

    const match = useMatch('/addOrUpdateOrganisation/:organisationId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [loadingClients, setLoadingClients] = useState(false)
    const [loadingContacts, setLoadingContacts] = useState(false)

    const [clientId, setClientId] = useState('')
    const [organisationName, setOrganisationName] = useState('')
    const [organisationAddress, setOrganisationAddress] = useState('')
    const [organisationContact, setOrganisationContact] = useState('')
    const [organisationNumberOfEmployees, setOrganisationNumberOfEmployees] = useState(0)
    const [organisationSector, setOrganisationSector] = useState('')
    const [organisationTurnoverGBP, setOrganisationTurnoverGBP] = useState(0)
    const [clients, setClients] = useState([])
    const [contacts, setContacts] = useState([])
    const [organisationCountry, setOrganisationCountry] = useState('')
    const [organisationFacilities, setOrganisationFacilites] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const removeCommas = (num) => num.toString().replace(/,/g, "");

    const handleChangeForOrganisationTurnoverGBP = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setOrganisationTurnoverGBP(formatted);
            } else {
                setOrganisationTurnoverGBP(value);
            }
        } else {
            setOrganisationTurnoverGBP(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedOrganisationTurnoverGBP = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setOrganisationTurnoverGBP(formatted);

        } else {
            setOrganisationTurnoverGBP("");
        }

    };

    const handleChangeForOrganisationNumberOfEmployees = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setOrganisationNumberOfEmployees(formatted);
            } else {
                setOrganisationNumberOfEmployees(value);
            }
        } else {
            setOrganisationNumberOfEmployees(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedOrganisationNumberOfEmployees = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setOrganisationNumberOfEmployees(formatted);

        } else {
            setOrganisationNumberOfEmployees("");
        }

    };

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const addFacility = () => {

        var tempOrganisationFacilites = [...organisationFacilities]

        tempOrganisationFacilites.push({id: uuidv4(), facilityName: "New Facility", country: "", entity: "", floorArea: "", floorAreaUnit: "", buildingTypeL1: "", buildingTypeL2:""})

        setOrganisationFacilites(tempOrganisationFacilites)

    }

    const deleteFacility = (facilityToDelete) => {

        var tempOrganisationFacilites = [...organisationFacilities]

        tempOrganisationFacilites = tempOrganisationFacilites.filter(item => item.id !== facilityToDelete)

        setOrganisationFacilites(tempOrganisationFacilites)

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            if (match.params.organisationId !== "_add") {

                setLoading(true)

                axios.get(process.env.REACT_APP_API_URL + '/organisations/' + match.params.organisationId)
                    .then((res) => {

                        setClientId(res.data.clientId)
                        setOrganisationName(res.data.organisationName)
                        setOrganisationAddress(res.data.organisationAddress)
                        setOrganisationContact(res.data.organisationContact)
                        setOrganisationSector(res.data.organisationSector)
                        setFormattedOrganisationTurnoverGBP(res.data.organisationTurnoverGBP)
                        setFormattedOrganisationNumberOfEmployees(res.data.organisationNumberOfEmployees)
                        setOrganisationCountry(res.data.organisationCountry)
                        setOrganisationFacilites(res.data.organisationFacilities)

                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

            }

            setLoadingClients(true)
            setLoadingContacts(true)

            if (role === 3001 || role === 6001) {

                axios.get(process.env.REACT_APP_API_URL + '/clients/' + company)
                .then((res) => {
                    setClients([res.data]);
                    if (match.params.organisationId === "_add") {
                        setClientId(res.data[0].id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoadingClients(false);
                });

            } else if (role === 6002) {

                axios.get(process.env.REACT_APP_API_URL + '/clients_by_special_user')
                .then((res) => {
                    console.log(res)
                    setClients(res.data.clients);
                    if (match.params.organisationId === "_add") {
                        setClientId(res.data.clients[0].id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoadingClients(false);
                });

            } else {

                axios.get(process.env.REACT_APP_API_URL + '/clients')
                    .then((res) => {
                        setClients(res.data.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0));
                        if (match.params.organisationId === "_add") {
                            setClientId(res.data[0].id);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoadingClients(false);
                    });

            }

            if (role === 3001 || role === 6001) {

                axios.get(process.env.REACT_APP_API_URL + '/contacts_by_clientId/' + company)
                .then((res) => {
                    setContacts(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoadingContacts(false);
                });

            } else if (role === 6002) {

                axios.get(process.env.REACT_APP_API_URL + '/contacts_by_special_user')
                .then((res) => {
                    setContacts(res.data.contacts)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoadingContacts(false);
                });

            } else {

                axios.get(process.env.REACT_APP_API_URL + '/contacts')
                    .then((res) => {
                        setContacts(res.data)
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoadingContacts(false);
                    });

                }
        }

    }, [match.params.organisationId, company, role, userPropertiesLoading])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/organisations', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/organisations/' + match.params.organisationId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (organisationContact !== null) {

            if (!isNaN(Number(removeCommas(organisationNumberOfEmployees))) && !isNaN(Number(removeCommas(organisationTurnoverGBP)))) {


                if (match.params.organisationId !== "_add") {
                    updateForm({ clientId, organisationName, organisationAddress, organisationContact, organisationNumberOfEmployees: Number(removeCommas(organisationNumberOfEmployees)), organisationSector, organisationTurnoverGBP: Number(removeCommas(organisationTurnoverGBP)), organisationCountry, organisationFacilities }).then(res => { navigate(-1) })
                } else {
                    addForm({ clientId, organisationName, organisationAddress, organisationContact, organisationNumberOfEmployees: Number(removeCommas(organisationNumberOfEmployees)), organisationSector, organisationTurnoverGBP: Number(removeCommas(organisationTurnoverGBP)), organisationCountry, organisationFacilities }).then(res => { navigate(-1) })
                }

            } else {

                window.alert("Please check that numeric values are valid")

            }
        }
    }

    if (contacts.length > 0 && !loadingContacts && clients.length > 0 && !loading && !loadingClients && !userPropertiesLoading && role && company) {

        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Organisation - Add / Edit Organisation" section="Organisation Main Details" url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Client</label>
                            <select className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role === 3001 ? true : false} value={clientId} onChange={(e) => { setClientId(e.target.value); contacts.filter(item => item.clientId === e.target.value).length > 0 ? setOrganisationContact(contacts.filter(item => item.clientId === e.target.value)[0].id) : setOrganisationContact(null) }} onFocus={(e) => { setClientId(e.target.value); contacts.filter(item => item.clientId === e.target.value).length > 0 ? setOrganisationContact(contacts.filter(item => item.clientId === e.target.value)[0].id) : setOrganisationContact(null) }}>
                                {clients.map((client) => (
                                    <option key={client.id} value={client.id}>{client.clientName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Organisation Name</label>
                            <input className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role === 3001 ? true : false} type="text" placeholder="Enter Organisation Name Here" value={organisationName} onChange={(e) => setOrganisationName(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Organisation Address</label>
                            <input className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role === 3001 ? true : false} type="text" placeholder="Enter Organisation Address Here" value={organisationAddress} onChange={(e) => setOrganisationAddress(e.target.value)} />
                        </div>
                        {/* <div className="form-control-8A">
                            <label>Organisation Contact</label>
                            <input className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role === 3001 ? true : false} type="text" placeholder="Enter Organisation Contact Here" value={organisationContact} onChange={(e) => setOrganisationContact(e.target.value)} />
                        </div> */}

                        <div className="form-control-8A">
                        <label>Country</label>
                        <CountryDropdown
                                value={organisationCountry}
                                onChange={setOrganisationCountry} />
                        </div>

                        {clientId && organisationContact &&
                            <div className="form-control-8A">
                                <label>Organisation Contact</label>
                                <select value={organisationContact} onChange={(e) => setOrganisationContact(e.target.value)}>
                                    {contacts.filter(item => item.clientId === clientId).map((contact) => (
                                        <option key={contact.id} value={contact.id}>{contact.contactName}</option>
                                    ))}
                                </select>
                            </div>
                        }

                        {clientId && (organisationContact === null) &&
                            <div className="form-control-8A">
                                <p className="text-red-500">No Contacts - Please add a Contact to the Client before creating an Organisation</p>
                            </div>
                        }


                        <div className="form-control-8A">
                            <label>Number of Employees</label>
                            <input type="text" placeholder="Enter Number of Employees Here" value={organisationNumberOfEmployees} onChange={handleChangeForOrganisationNumberOfEmployees} />
                        </div>
                        <div className="form-control-8A">
                            <label>Sector</label>
                            <input className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role === 3001 ? true : false} type="text" placeholder="Enter Organisation Sector Here" value={organisationSector} onChange={(e) => setOrganisationSector(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Turnover (Base Currency)</label>
                            <input type="text" placeholder="Enter Organisation Turnover Here" value={organisationTurnoverGBP} onChange={handleChangeForOrganisationTurnoverGBP} />
                        </div>

                        <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl m-4 text-xs">
                        <Button className='bg-bondi-blue' text="Click here to add new facility & entity record >>" onClick={() => addFacility()}/>
                            <table className="objects-8A min-w-full divide-y divide-gray-200 mt-2">

                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Entity</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Country</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Facility</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Floor Area</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Building Type L1</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Building Type L2</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                    </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200">
                                    {organisationFacilities.map((facility) => (
                                        <tr key={facility.id}>
                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(facility.entity); setFieldToSendToModal("Facility Entity"); setObjectIdToSendToModal(match.params.organisationId); setCollectionToSendToModal("organisations"); setCollectionArrayToSendToModal("organisationFacilities"); setCollectionArrayIdToSendToModal(facility.id) }} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {facility.entity} </td>
                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(facility.country); setFieldToSendToModal("Facility Country"); setObjectIdToSendToModal(match.params.organisationId); setCollectionToSendToModal("organisations"); setCollectionArrayToSendToModal("organisationFacilities"); setCollectionArrayIdToSendToModal(facility.id) }} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {facility.country} </td>
                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(facility.facilityName); setFieldToSendToModal("Facility Name"); setObjectIdToSendToModal(match.params.organisationId); setCollectionToSendToModal("organisations"); setCollectionArrayToSendToModal("organisationFacilities"); setCollectionArrayIdToSendToModal(facility.id) }} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {facility.facilityName} </td>
                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(facility.floorArea); setFieldToSendToModal("Facility Floor Area"); setObjectIdToSendToModal(match.params.organisationId); setCollectionToSendToModal("organisations"); setCollectionArrayToSendToModal("organisationFacilities"); setCollectionArrayIdToSendToModal(facility.id) }} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {facility.floorArea}</td>
                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(facility.floorAreaUnit); setFieldToSendToModal("Facility Floor Area Unit"); setObjectIdToSendToModal(match.params.organisationId); setCollectionToSendToModal("organisations"); setCollectionArrayToSendToModal("organisationFacilities"); setCollectionArrayIdToSendToModal(facility.id) }} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {facility.floorAreaUnit}</td>
                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(facility.buildingTypeL1); setFieldToSendToModal("Facility Building Type L1"); setObjectIdToSendToModal(match.params.organisationId); setCollectionToSendToModal("organisations"); setCollectionArrayToSendToModal("organisationFacilities"); setCollectionArrayIdToSendToModal(facility.id) }} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {facility.buildingTypeL1}</td>
                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(facility.buildingTypeL2); setFieldToSendToModal("Facility Building Type L2"); setObjectIdToSendToModal(match.params.organisationId); setCollectionToSendToModal("organisations"); setCollectionArrayToSendToModal("organisationFacilities"); setCollectionArrayIdToSendToModal(facility.id) }} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {facility.buildingTypeL2}</td>
                                            <td><button className="hover:cursor-pointer" onClick={() => { deleteFacility(facility.id) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> </svg></button></td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>
                    <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} arrayToShow={organisationFacilities} collectionToUpdate={collectionToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} onClose={() => setShowModal(false)} show={showModal} title="Update Organisation Information" />
                </div>
            </div>
        )

    } else {

        return (
            <div>
                <Header title="Organisation - Add / Edit Organisation" section="Organisation Main Details" url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                <p>Data is loading...</p>
            </div>)

    }
}

export default AddOrUpdateOrganisation